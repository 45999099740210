import styled, { css, keyframes } from "styled-components";

import { Flex } from "../Styles";

const Container = styled(Flex).attrs(() => ({
	column: true,
	justify: "center",
	align: "center"
}))`
	width: 100px;
	height: 100%;
	border-left: 1px solid gray;
	background-color: rgba(0,0,0,0.4);
`;
const blinkAnim = keyframes`
	0% { opacity: 1; }
	49% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 0; }
`;
const Light = styled.div`
	position: relative;
	width: 30px;
	height: 30px;
	margin: 5px;
	border-radius: 50%;
	background: radial-gradient(ellipse at center, gray 0%, gray 50%, transparent 50%);
	background-repeat: repeat;
	background-size: 10% 10%;

	&:before {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		content: '';
		${({ active, color, blink }) => active
			? css`
				background: radial-gradient(ellipse at center, ${color} 0%, ${color} 50%, transparent 50%);
				box-shadow: 0 0 34px ${color}, 0 0 12px ${color};
				animation: ${blink ? css`${blinkAnim} 1s infinite linear`: `none`};
			`
			: ``
		}
		background-repeat: repeat;
		background-size: 10% 10%;
	}
`;

export function TrafficLight({ status }) {
	return (
		<Container>
			<Light
				color="red"
				active={[ "OPEN", "COMPLETE", "CLOSED", "EXPIRED" ].includes(status)}
				blink={[ "OPEN", "COMPLETE" ].includes(status)}
			/>
			<Light
				color="yellow"
				active={[ "OPEN", "CANCELED" ].includes(status)}
				blink={status === "OPEN"}
			/>
			<Light
				color="#00ff00"
				active={[ "OPEN", "LIVE" ].includes(status)}
				blink={[ "OPEN", "LIVE" ].includes(status)}
			/>
		</Container>
	)
}
import styled from "styled-components";

import { Flex } from "../../Styles";
import { ConfigSquare } from "./ConfigSquare";

const Outer = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
  background-color: #000;
  width: 100%;
	margin-top: 10px;
	margin-bottom: 30px;
`;

const Container = styled(Flex).attrs(() => ({
	justify: "space-between"
}))`
  width: 100%;
`;

const Line = styled.div`
	position: absolute;
	width: calc(100% + 60px);
	height: 5px;
	background-color: white;
`;

export function RaceConfigProgress({ stage, type, vehicleURL, maxLevel }) {
  return (
    <Outer>
			<Line/>
      <Container>
        <ConfigSquare
          num={1}
          selected={stage === 1}
          imageURL={`/images/raceType/${type}.png`}
					invert={false}
					imageWidth={60}
        />
        <ConfigSquare
					num={2}
					selected={stage === 2}
					imageURL={vehicleURL}
					invert={false}
				/>
        <ConfigSquare
					num={3}
					selected={stage === 3}
					textContent={maxLevel}
				/>
        <ConfigSquare
					num={4}
					selected={stage === 4}
					imageURL="/check.png"
					imageWidth={45}
				/>
      </Container>
    </Outer>
  );
}

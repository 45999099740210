import { useState, useEffect } from "react";
import { formatTime } from "../utils";

// places: 4 = days, 3 = hours, 2 = minutes, 1 = seconds
export function useTimer(targetTime, shouldCount, { places = 4 } ) {
	const [ timeLeft, setTimeLeft ] = useState(new Array(places).fill("--").join(":"));

	useEffect(() => {
		if (!shouldCount) return;
		if (isNaN(targetTime)) {
			setTimeLeft(new Array(places).fill("--").join(":"));
			return;
		}

		const i = setInterval(() => {
			const ms = targetTime - Date.now();
			setTimeLeft(formatTime(Math.max(ms, 0), places));
		}, 1000);

		return () => clearInterval(i);
	}, [ targetTime, shouldCount, places ]);

	return timeLeft;
}
import styled from "styled-components";

import { Flex } from "../Styles";

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	width: 30%;
	border: 5px solid ${({ selected = false }) => selected ? "#00ff00": "black"};
	color: white;
	cursor: pointer;
	
	${({ disabled }) => disabled && `
		border-color: red;
		cursor: not-allowed;
	`}
`;

const IconContainer = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	background-color: white;
	width: 100%;
	padding: 15% 0;
`;

const TextContainer = styled(Flex).attrs(() => ({
	column: true,
	justify: "space-between",
	align: "center"
}))`
	padding: 10px;
	height: 160px;
	background-color: black;
`;

const Title = styled.div`
	text-align: center;
	font-size: 16px;
  font-family: ArialBlack;
  transform: scale(1, 1.3);
	text-transform: uppercase;
	padding: 10px 0;
`;

const Description = styled.div`
	text-align: center;
	height: 100%;
	padding-top: 10px;
	font-size: 13px;
`;

export function RaceType({ name, description, image, selected, disabled, onSelect }) {
	return (
		<Container
			selected={selected}
			disabled={disabled}
			onClick={() => !disabled && onSelect(name)}
		>
			<IconContainer>
				<img src={image} alt={name} width={100}/>
			</IconContainer>
			<TextContainer>
				<Title>{name}</Title>
				<Description>{description}</Description>
			</TextContainer>
		</Container>
	)
}

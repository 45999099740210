import { MeshNormalMaterial, MeshStandardMaterial } from "three";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import { prefix3D } from "../../utils";
import { createRainbowShader } from "../../shaders/rainbow";

export const paint = {
	"Arcade Rug": { texture: prefix3D + "arcade_map_v1.jpg" },
	"Banana Candy": { color: "#bdbd5c" },
	"Classic": { color: "#ffffff" },
	"Does It Come In Brown?": { color: "#534311" },
	"Global": {
		texture: prefix3D + "global_map_v1.jpg",
		color: "#cccccc"
	}, // world map
	"Happy Trees": { texture: prefix3D + "happy_trees_map_v1.jpg" }, // bob ross
	"I Honestly Love The Color": { color: "#a6ae6e" },
	"I Often Paint for Fun": { texture: prefix3D + "paint_often_map_v1.jpg" }, // flowery
	"I Really Like It": { color: "#a3a27e" },
	"Just Enough Cream": { color: "#c2bba9" },
	"Knockoff": { texture: prefix3D + "knockoff.jpg" },
	"Mean Sheen": { material: new MeshNormalMaterial() }, // maybe shader or texture?
	"Meat Comes First": {
		texture: prefix3D + "meat.jpg",
		textureProps: {
			repeat: [ 3, 3 ],
			offset: [ 0, 0.5 ]
		}
	},
	"Mustard": { color: "#988229" },
	"Next Door Neighbor": { color: "#6e819f" },
	"Onyx": {
		texture: prefix3D + "onyx_map_v1.jpg",
		roughness: 0.7
	},
	"Plaid": { texture: prefix3D + "plaid_map_v1.jpg" },
	"Polka 'Dona": {
		texture: prefix3D + "polka.jpg",
		textureProps: {
			repeat: [ 4.9, 4.9 ],
			offset: [ 0, 0.16 ]
		}
	},
	"Porcelain": { color: "#c5bab3" },
	"Rustic": {
		texture: prefix3D + "rustic_map_v2.jpg",
		metalness: 0.1,
		roughness: 0.9
	},
	"Skellies": { texture: prefix3D + "skellies_map_v1.jpg" }, // skeletons
	"Speed": { texture: prefix3D + "speed_map_v3.jpg" }, // flames
	"Sushi": {
		texture: prefix3D + "sushi_map_v1.jpg",
		color: "#cccccc"
	},
	"Today Is About Me": { color: "#b14cb2" },
	"The People's Car": { texture: prefix3D + "peoples_map_v1.jpg" },
	"Thermal": {
		material: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createRainbowShader({
				heightScale: 0.008,
				heightOffset: 0.55,
				csm: true
			}),
			metalness: 0,
			roughness: 0.05
		})
	}, // rainbow gradient going up
	"Tomato": { color: "#881A15" },
	"Watermelon": { texture: prefix3D + "watermelon_map_v2.jpg" },
	"We Should Race": { texture: prefix3D + "racing_stripes_map_v2.jpg" }, // blue with racing stripe
	"Ya, Why Not": { color: "#b47d43" }
}

const defaultPaint = {
	color: "#ff0000",
	texture: undefined,
	textureProps: {}
}
export function usePaint(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "paint");
	if (!trait) return { ...defaultPaint };

	const { value } = trait;
	if (!paint[ value ]) return { ...defaultPaint };

	return paint[ value ];
}
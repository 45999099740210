import styled from "styled-components";

import { Flex } from "./Styles";

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
  position: ${({ position = "fixed" }) => position};
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: ${({ width = "60%" }) => width};
  min-width: min(400px, 90%);
	max-width: min(650px, 90%);
	max-height: calc(100vh - 160px);
	overflow: hidden;
  z-index: 10000;
  box-shadow: 0px 7px 34px rgba(0, 0, 0, 0.4);
	padding: 30px;
	border-radius: 20px;
	border: 5px solid white;

	&:before {
		position: absolute;
		top: 0px;
		display: block;
		content: '';
		background-color: ${({ bg = "transparent" }) => bg};
		border: 5px solid ${({ borderColor = "black" }) => borderColor};
		height: calc(100% - 10px);
		width: calc(100% - 10px);
		border-radius: 15px;
		pointer-events: none;
	}

	${({ isHidden }) => isHidden ? "display: none !important;": ""}
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5000;
	${({ isHidden }) => isHidden ? "display: none !important;": ""}
`;

const ModalTitleContainer = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
	padding-bottom: 20px;
`;

export const ModalText = styled.div`
  width: 100%;
  font-size: 20px;
  text-transform: uppercase;
	color: ${({ color = "black" }) => color};
`;
export const ModalTitle = styled(ModalText)`
	font-weight: 700;
	font-size: 24px;
  font-family: ArialBlack;
  transform: scale(1, 1.3);
`;

const Xbutton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	width: 40px;
	height: 40px;
	padding: 6px;
	cursor: pointer;
	${({ invert }) => invert && `
		& > img {
			filter: invert(100%);
		}
	`}
`;

export const ModalBody = styled(Flex)`
	width: 100%;
	max-height: calc(100vh - 400px);
	overflow-y: auto;
`;

export function ModalHeader({ title, onClose, invert = false, style }) {
	return (
		<ModalTitleContainer style={style}>
			<ModalTitle color={invert ? "white": "black"}>{title}</ModalTitle>
			<Xbutton
				invert={invert}
				onClick={onClose}
			>
				<img src="/x-mark.svg" alt="X" height="28" />
			</Xbutton>
		</ModalTitleContainer>
	)
}

export function Modal({ onClose, title = null, children, isVisible, style, bg, borderColor }) {
  return (
    <>
      <Background
				isHidden={!isVisible}
				onClick={onClose}
			/>
      <Container
				bg={bg}
				borderColor={borderColor}
				style={style}
				isHidden={!isVisible}
			>
				{title && (
					<ModalHeader
						title={title}
						onClose={onClose}
					/>
				)}
				{children}
      </Container>
    </>
  );
}

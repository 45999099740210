import styled from "styled-components";

import { Flex } from "./Styles";

const Container = styled(Flex).attrs(() => ({
  justify: "center"
}))`
  width: 100%;
  min-height: 200px;
  overflow: hidden auto;
`;

const Carousel = styled(Flex).attrs(() => ({
  wrap: "wrap"
}))`
  width: 100%;
  height: fit-content;
`;

const NFTContainer = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	width: 170px;
	height: 230px;
	background-color: black;
	color: white;
	border: 5px solid ${({ selected, disabled }) => disabled
		? "red"
		: selected
			? "#00ff00"
			: "black"
	};
	margin: 10px;
	${({ disabled }) => disabled && `
		pointer-events: none;
		cursor: not-allowed;
	`}
`;
const NFTImage = styled.img`
  width: 160px;
  height: 160px;
`;
const NFTDescription = styled(Flex).attrs(() => ({
	column: true,
	justify: "center"
}))`
	height: 60px;
	text-transform: uppercase;
	& > div:first-of-type {
		margin: 5px 0;
		font-weight: 700;
	}
	& > div:last-of-type {
		font-size: 14px
	}
`;
const LevelAndXp = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
`;

export function SelectNFTCarousel({ nfts = [], selected, onSelect, onDeselect }) {
  return (
    <Container>
      <Carousel>
        {nfts && nfts.length
          ? nfts.map(({ image_url, name, level = 1, xp = 0, isRacing = false }, i) => {
						const isSelected = Array.isArray(selected)
							? selected.includes(nfts[i])
							: selected === nfts[i];
						if (isSelected && isRacing) onDeselect(nfts[i]);
            return (
							<NFTContainer
								key={i}
								selected={!isRacing && isSelected}
								disabled={isRacing}
								onClick={() => {
									if (isRacing) return;
									isSelected
										? onDeselect(nfts[i])
										: onSelect(nfts[i])
									}
								}
							>
								<NFTImage
									src={image_url}
									title={name}
								/>
								<NFTDescription>
									<div>{name}</div>
									<LevelAndXp>
										<div>Level: {level}</div>
										<div>XP: {xp}</div>
									</LevelAndXp>
								</NFTDescription>
							</NFTContainer>
						)
					})
          : "Your garage is empty..."
        }
      </Carousel>
    </Container>
  );
}
import styled from "styled-components";

import { Flex } from "../Styles";
import { ModalTitle } from "../Modal";

const Container = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
`;

const Description = styled(Flex).attrs(() => ({
	align: "center"
}))`
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 20px;
`;

const LiveLink = styled.a`
	text-decoration: underline;
	color: black;
	font-weight: 700;
	cursor: pointer;
	&:active {
		opacity: 0.6;
	}
`;

const CopyButton = styled.img`
	width: 20px;
	margin-left: 10px;
	cursor: pointer;

	&:active {
		opacity: 0.6;
	}
`;

export function RaceInfo({ link }) {
	return (
		<Container>
			<ModalTitle>YOUR RACE IS NOW OPEN</ModalTitle>
			<Description>
				HERE IS THE URL:&nbsp;
				<LiveLink href={link}>LIVE RACE LINK</LiveLink>
				<CopyButton src="/copy.svg" onClick={() => navigator.clipboard.writeText(link)}/>
			</Description>
			<Description>FEEL FREE TO SHARE THIS LINK ON THE WORLD WIDE WEB, SO OTHER RACERS CAN JOIN.</Description>
			<Description>BUCKLE UP, BUCKO.</Description>
		</Container>
	)
}
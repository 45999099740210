import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

import { prefix3D } from "../../utils";

import { Dona } from "../../Components/DonaModel";

function DonaTow({ speed, curved = true, ...props }) {
	const towProps = curved
		? {
			position: [ 0, -0.25, -2.5 ],
			rotation: [ -Math.PI / 40, 0, 0 ]
		}
		: {
			position: [ 0, 0, -2.5 ]
		}
	return (
		<group {...props}>
			<Dona
				{...towProps}
				speed={speed}
			/>
		</group>
	)
}

function LarryCamper({ curved, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "camper_compressed.glb");

	const towProps = curved
		? {
			position: [ 0, -0.12, -2 ],
			rotation: [ -Math.PI / 60, Math.PI, 0 ]
		}
		: {
			position: [ 0, 0, -2 ],
			rotation: [ 0, Math.PI, 0 ]
		}

	return (
		<group
			{...props}
			dispose={null}>
			<group {...towProps}>
				<mesh geometry={nodes.camper.geometry} material={materials.body} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.ac.geometry} material={materials.ac} position={[0, 1.5, -1.39]} />
				<mesh geometry={nodes.awning.geometry} material={materials.awning} position={[0, 1.74, -1.58]} />
				<mesh geometry={nodes.door.geometry} material={materials.door} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.window_doorside.geometry} material={materials.windows} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.window_back.geometry} material={materials.windows} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.window_sideback.geometry} material={materials.windows} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.window_sidefront.geometry} material={materials.windows} position={[0, 0.25, 0]} />
				<mesh geometry={nodes.wheels.geometry} material={materials.wheels} position={[0, 0.25, -0.2]} rotation={[0, 0, -Math.PI / 2]} scale={[0.3, 0.06, 0.3]} />
				<mesh geometry={nodes.tow.geometry} material={materials.tow} />
				<mesh geometry={nodes.awning_supports.geometry} material={materials.awning} position={[-0.48, 1.49, -1.58]} />
			</group>
		</group>
	)
}

function MergeLanes({ speed, curved, ...props }) {
	const blink = useRef(false);
	const { nodes, materials } = useGLTF(prefix3D + "merge_compressed.glb");

	const towProps = curved
		? {
			position: [ 0, -0.15, -1.2 ]
		}
		: {
			position: [ 0, 0, -1.2 ]
		}

	useFrame(({ clock }) => {
		if (!materials.Light) return;
		if (!speed && blink.current) return materials.Light.color.set("#e7d700");
		const sec = clock.getElapsedTime();
		if (Math.floor(sec) % 2) {
			if (!blink.current) return;
			blink.current = false;
			materials.Light.color.set("#404040");
		}
		else {
			if (blink.current) return;
			blink.current = true;
			materials.Light.color.set("#e7d700");
		}
	});

	return (
		<group {...props} dispose={null}>
			<group {...towProps}>
				<group position={[-0.02, 3.75, 0.2]} rotation={[-Math.PI, 0, -Math.PI]}>
					<mesh geometry={nodes.bottom_light1.geometry} material={materials.Light} position={[0.8, -0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_light2.geometry} material={materials.LightOff} position={[0.4, -0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_light3.geometry} material={speed ? materials.LightOff: materials.Light} position={[0, -0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_light4.geometry} material={materials.LightOff} position={[-0.4, -0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_light5.geometry} material={materials.Light} position={[-0.8, -0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light1.geometry} material={speed ? materials.Light: materials.LightOff} position={[1, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light2.geometry} material={speed ? materials.LightOff: materials.Light} position={[0.6, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light3.geometry} material={materials.LightOff} position={[0.2, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light4.geometry} material={speed ? materials.LightOff: materials.Light} position={[-0.2, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light5.geometry} material={materials.LightOff} position={[-0.6, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.bottom_middle_light6.geometry} material={materials.Light} position={[-1, -0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light1.geometry} material={speed ? materials.Light: materials.LightOff} position={[1.2, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light2.geometry} material={speed ? materials.Light: materials.LightOff} position={[0.8, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light3.geometry} material={speed ? materials.Light: materials.Light} position={[0.4, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light4.geometry} material={speed ? materials.Light: materials.LightOff} position={[0, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light5.geometry} material={speed ? materials.Light: materials.Light} position={[-0.4, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light6.geometry} material={speed ? materials.Light: materials.LightOff} position={[-0.8, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.middle_light7.geometry} material={speed ? materials.Light: materials.Light} position={[-1.2, 0, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_light1.geometry} material={materials.Light} position={[0.8, 0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_light2.geometry} material={materials.LightOff} position={[0.4, 0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_light3.geometry} material={speed ? materials.LightOff: materials.Light} position={[0, 0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_light4.geometry} material={materials.LightOff} position={[-0.4, 0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_light5.geometry} material={materials.Light} position={[-0.8, 0.63, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light1.geometry} material={speed ? materials.Light: materials.LightOff} position={[1, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light2.geometry} material={speed ? materials.LightOff: materials.Light} position={[0.6, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light3.geometry} material={materials.LightOff} position={[0.2, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light4.geometry} material={speed ? materials.LightOff: materials.Light} position={[-0.2, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light5.geometry} material={materials.LightOff} position={[-0.6, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
					<mesh geometry={nodes.top_middle_light6.geometry} material={materials.Light} position={[-1, 0.31, -0.05]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.5, 1]} />
				</group>
				<mesh geometry={nodes.wheels.geometry} material={materials.tires} position={[-0.77, 0.38, 0.01]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[0.38, 0.11, 0.38]} />
				<mesh geometry={nodes.upper_support.geometry} material={materials.orange} position={[-0.02, 4.58, 0.01]} rotation={[0, 0, -Math.PI]} scale={0.05} />
				<mesh geometry={nodes.body.geometry} material={materials.orange} position={[-0.02, 0.52, -0.4]} rotation={[-2.71, 0, -Math.PI]} scale={[0.56, 0.05, 0.01]} />
				<mesh geometry={nodes.kickstands.geometry} material={materials.white} position={[-0.02, 0, 0.01]} rotation={[-Math.PI, 0, -Math.PI]} />
				<mesh geometry={nodes.backboard.geometry} material={materials.black} position={[-0.02, 3.75, 0.2]} rotation={[-Math.PI, 0, -Math.PI]} />
				<mesh geometry={nodes.hubcaps.geometry} material={nodes.hubcaps.material} position={[0.8, 0.38, 0.01]} rotation={[Math.PI, 0, Math.PI / 2]} scale={[0.19, 0.02, 0.19]} />
			</group>
		</group>
	);
}

function NewBoat({ curved, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "towboat_compressed.glb");

	const towProps = curved
		? {
			position: [ 0, -0.255, -2.7 ],
			rotation: [ -Math.PI / 28, 0, 0 ]
		}
		: {
			position: [ 0, 0, -2.7 ]
		}

	return (
		<group {...props} dispose={null}>
			<group
				{...towProps}
				scale={0.6}>
				<group position={[0, 1.27, 0]} rotation={[0, -1.57, 0]}>
					<mesh geometry={nodes.avant.geometry} material={materials.tuyau} position={[4.89, 1.57, 0]} scale={[0.43, 0.07, 0.15]} />
					<mesh geometry={nodes.barre.geometry} material={nodes.barre.material} position={[4.74, 2.25, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[0.01, 0.2, 0.01]} />
					<mesh geometry={nodes.barre_haut.geometry} material={materials['barre ']} position={[0.39, 2.27, 0]} rotation={[-Math.PI, 0, -Math.PI / 2]} scale={[0.03, 4.3, 0.03]} />
					<mesh geometry={nodes.barre001.geometry} material={materials['barre ']} position={[4.74, 2, 0.2]} rotation={[0, 0, -0.04]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre002.geometry} material={materials['barre ']} position={[1.78, 1.97, 1.81]} rotation={[0, 0, -0.07]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre003.geometry} material={materials['barre ']} position={[0.15, 1.86, 1.91]} rotation={[0, 0, -0.07]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre004.geometry} material={materials['barre ']} position={[-1.47, 1.63, -1.96]} rotation={[0, 0, -0.09]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre005.geometry} material={materials['barre ']} position={[4.74, 2, -0.19]} rotation={[0, 0, -0.04]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre006.geometry} material={materials['barre ']} position={[3.37, 2, -1.36]} rotation={[0, 0, -0.04]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre007.geometry} material={materials['barre ']} position={[1.78, 1.97, -1.8]} rotation={[0, 0, -0.07]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre008.geometry} material={materials['barre ']} position={[0.15, 1.86, -1.91]} rotation={[0, 0, -0.07]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre009.geometry} material={materials['barre ']} position={[-1.47, 1.63, 1.94]} rotation={[0, 0, -0.09]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.barre010.geometry} material={materials['barre ']} position={[3.37, 2, 1.36]} rotation={[0, 0, -0.04]} scale={[0.02, 0.28, 0.02]} />
					<mesh geometry={nodes.bat_du_bateau.geometry} material={materials.black} position={[-0.77, 1.01, 0]} />
					<mesh geometry={nodes.bat_du_bateau001.geometry} material={materials.white} position={[-0.77, 1.01, 0]} />
					<group position={[0, 1.76, 0.12]} scale={[1, 0.06, 1]}>
						<mesh geometry={nodes.Cube005.geometry} material={materials['capo.001']} />
						<mesh geometry={nodes.Cube005_1.geometry} material={materials.volan} />
						<mesh geometry={nodes.Cube005_2.geometry} material={materials['volan.001']} />
						<mesh geometry={nodes.Cube005_3.geometry} material={materials['manette.001']} />
						<mesh geometry={nodes.Cube005_4.geometry} material={materials['manette ']} />
						<mesh geometry={nodes.Cube005_5.geometry} material={materials.manette} />
						<mesh geometry={nodes.Cube005_6.geometry} material={materials['barre ']} />
					</group>
					<group position={[-2.44, 1.6, -0.01]} scale={[1, 1, 1.07]}>
						<mesh geometry={nodes.Cube002.geometry} material={materials.Carosserie} />
						<mesh geometry={nodes.Cube002_1.geometry} material={materials['capteur solaire.002']} />
						<mesh geometry={nodes.Cube002_2.geometry} material={materials['capteur solaire.003']} />
						<mesh geometry={nodes.Cube002_3.geometry} material={materials['capteur solaire.004']} />
						<mesh geometry={nodes.Cube002_4.geometry} material={materials['capteur solaire.005']} />
						<mesh geometry={nodes.Cube002_5.geometry} material={materials.vitre} />
						<mesh geometry={nodes.Cube002_6.geometry} material={materials['barre ']} />
					</group>
					<group position={[-3.78, 1.6, -0.03]} scale={[1, 1, 0.93]}>
						<mesh geometry={nodes.Cube004.geometry} material={materials.Carosserie} />
						<mesh geometry={nodes.Cube004_1.geometry} material={materials['barre ']} />
						<mesh geometry={nodes.Cube004_2.geometry} material={materials.vitre} />
						<mesh geometry={nodes.Cube004_3.geometry} material={materials['bois.006']} />
						<mesh geometry={nodes.Cube004_4.geometry} material={materials['bois.007']} />
						<mesh geometry={nodes.Cube004_5.geometry} material={materials['bois.008']} />
						<mesh geometry={nodes.Cube004_6.geometry} material={materials.bois} />
						<mesh geometry={nodes.Cube004_7.geometry} material={materials['Material.004']} />
						<mesh geometry={nodes.Cube004_8.geometry} material={materials['bois.005']} />
					</group>
				</group>
				<mesh geometry={nodes.Cube.geometry} material={materials.towMat} position={[0, 1.27, 0]} rotation={[0, -1.57, 0]} />
				<mesh geometry={nodes.Cylinder.geometry} material={materials.wheelMat} position={[1.56, 0.51, 1.58]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.5, 0.15, 0.5]} />
				<mesh geometry={nodes.Cylinder001.geometry} material={materials.wheelMat} position={[1.56, 0.51, -2.92]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.5, 0.15, 0.5]} />
				<mesh geometry={nodes.Cylinder002.geometry} material={materials.wheelMat} position={[1.56, 0.51, -4.17]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[0.5, 0.15, 0.5]} />
			</group>
		</group>
	)
}

function Uhaul({ speed, curved, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "uhaul_compressed.glb");

	const towProps = curved
		? {
			position: [ 0, -0.2, -2.25 ],
			rotation: [ -Math.PI / 60, Math.PI, 0 ]
		}
		: {
			position: [ 0, 0, -2.25 ],
			rotation: [ 0, Math.PI, 0 ]
		}

	return (
		<group {...props} dispose={null}>
			<group
				{...towProps}
				scale={1.4}>
				<mesh
					geometry={nodes.metal_housing.geometry}
					material={materials.metal}
				/>
				<mesh
					geometry={nodes.brim.geometry}
					material={materials.metal}
				/>
				<mesh
					geometry={nodes.hubcaps.geometry}
					material={materials.metal}
					position={[0, 0.35, 0.25]}
					rotation={[0, 0, -Math.PI / 2]}
					scale={[1.2, 1, 1.2]}
				/>
				<mesh
					geometry={nodes.front_tires.geometry}
					material={materials.tire}
					position={[0, 0.35, 0.25]}
				/>
				<mesh
					geometry={nodes.panels.geometry}
					material={materials.panelMat}
				/>
				<mesh
					geometry={nodes.hitch.geometry}
					material={materials.metal}
					position={[0, 0.35, -1.9]}
					scale={[0.2, 0.02, 0.5]}
				/>
			</group>
		</group>
	)
}

function UnicornDuck({ speed, curved, ...props }) {
	const hornRef = useRef();

	const { nodes, materials } = useGLTF(prefix3D + "uniduck_compressed.glb");

	useFrame((_, delta) => {
		if (!speed) return;
		hornRef.current.rotation.y += 5 * delta;
	});

	const towProps = curved
		? {
			position: [ 0, 0, -1.25 ]
		}
		: {
			position: [ 0, 0, -1.25 ]
		}

	return (
		<group
			{...props}
			dispose={null}>
			<group
				{...towProps}
				scale={2.8}>
				<mesh
					geometry={nodes.Duck.geometry}
					material={materials.Duck}
					rotation={[Math.PI / 2, 0, 0]}
				/>
				<mesh
					ref={hornRef}
					geometry={nodes.Cone.geometry}
					material={materials['Material.001']}
					position={[0, 1.12, 0.2]}
					rotation={[0.36, 0, 0]}
					scale={[1, 1.13, 1]}
				/>
				<mesh
					geometry={nodes.Cone001.geometry}
					material={materials['Material.002']}
					position={[0.19, 1.05, 0.06]}
					rotation={[-0.03, -0.12, -0.5]}
					scale={[1.09, 1.24, 0.63]}
				/>
			</group>
    </group>
	)
}

function WinterLumber({ curved, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "logs_compressed.glb");

	const towProps = curved
		? {
			position: [ 0, -0.17, -1.8 ],
			rotation: [  -Math.PI / 55, Math.PI, 0  ]
		}
		: {
			position: [ 0, 0, -1.8 ],
			rotation: [ 0, Math.PI, 0 ]
		}

	return (
		<group
			{...props}
			dispose={null}>
			<group
				{...towProps}
				scale={0.15}>
				<group position={[0, 4.82, -0.9]} scale={[1, 1, 1.12]}>
					<mesh geometry={nodes.Model_1.geometry} material={materials.material0} />
					<mesh geometry={nodes.Model_2.geometry} material={materials.material1} />
					<mesh geometry={nodes.Model_3.geometry} material={materials.material2} />
				</group>
				<group position={[0, 8.1, -0.9]} rotation={[ 0, Math.PI, 0 ]} scale={[1, 1, 1.12]}>
					<mesh geometry={nodes.Model_1.geometry} material={materials.material0} />
					<mesh geometry={nodes.Model_2.geometry} material={materials.material1} />
					<mesh geometry={nodes.Model_3.geometry} material={materials.material2} />
				</group>
				<mesh geometry={nodes.Cube.geometry} material={materials.bed} position={[0, 4.25, 0]} scale={[1, 0.25, 1]} />
				<mesh geometry={nodes.Cube001.geometry} material={materials.blue} position={[0.03, 5.25, 0.05]} scale={[1, 9, 1]} />
				<mesh geometry={nodes.Cylinder.geometry} material={materials.black} position={[-5.77, 2, 9.61]} rotation={[0, 0, -Math.PI / 2]} scale={[2, 0.5, 2]} />
				<mesh geometry={nodes.Cylinder001.geometry} material={materials.black} position={[-5.77, 2, 5.11]} rotation={[0, 0, -Math.PI / 2]} scale={[2, 0.5, 2]} />
				<mesh geometry={nodes.Cylinder002.geometry} material={materials.black} position={[-5.77, 2, -8.89]} rotation={[0, 0, -Math.PI / 2]} scale={[2, 0.5, 2]} />
				<mesh geometry={nodes.Cylinder003.geometry} material={materials.black} position={[5.77, 2, 9.61]} rotation={[0, 0, -Math.PI / 2]} scale={[2, -0.5, 2]} />
				<mesh geometry={nodes.Cylinder004.geometry} material={materials.black} position={[5.77, 2, 5.11]} rotation={[0, 0, -Math.PI / 2]} scale={[2, -0.5, 2]} />
				<mesh geometry={nodes.Cylinder005.geometry} material={materials.black} position={[5.77, 2, -8.89]} rotation={[0, 0, -Math.PI / 2]} scale={[2, -0.5, 2]} />
				<mesh geometry={nodes.Cube002.geometry} material={materials.bed} position={[5.47, 3.25, -8.89]} scale={[1, 1, 2.5]} />
				<mesh geometry={nodes.Cube003.geometry} material={materials.bed} position={[5.47, 3.25, 7.4]} scale={[1, 1, 4.87]} />
			</group>
		</group>
	)
}

export const towing = {
	"'Dona": DonaTow,
	"Larry's Camper": LarryCamper,
	"Merge Lanes": MergeLanes,
	"New Boat": NewBoat,
	"UHaul": Uhaul,
	"Unicorn Duck": UnicornDuck,
	"Winter Lumber": WinterLumber,
}

export function useTowing(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "towing");
	if (!trait) return undefined;

	const { value } = trait;
	return towing[ value ];
}
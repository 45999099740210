import { useEffect, useState } from "react";
import styled from "styled-components";

import { usePlaylist } from "../Hooks";
import { clamp, shuffle } from "../utils";

import { Flex } from "./Styles";

const Container = styled(Flex).attrs(() => ({
	justify: "center"
}))`
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 140px;
	z-index: 999;
	pointer-events: none;
	& > * {
		pointer-events: all;
	}
`;

const RadioBar = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 80%;
	min-width: 600px;
	max-width: 800px;
	height: 80px;
	padding: 15px;
	border-radius: 9px;
	border: 5px solid #00ff00;
	background-color: black;
	box-shadow: 0px 3px 14px rgba(0,0,0,0.2);
	text-transform: uppercase;
`;

const ButtonContainer = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))``;
const RadioButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	margin: 5px;
	padding: 12px;
	cursor: pointer;
`;
const RadioIcon = styled.img`
	width: 21px;
	height: 21px;
	${({ flip }) => flip && `transform: scaleX(-1);`}
	${({ hidden }) => hidden && `display: none;`}
`;

const Title = styled.div`
	width: 420px;
	color: red;
	font-weight: 700;
	overflow: visible;
	font-family: LLPIXEL3, sans-serif;
	text-shadow: 1px 1px 10px red;
`;

const VolumeContainer = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 110px;
	margin-right: 20px;
`;
const VolumeSlider = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 80px;
	height: 60px;
	position: relative;
`;
const VolumeGroove = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 80px;
	height: 4px;
	background-color: #00ff00;
	border-radius: 2px;
	cursor: pointer;
`;
const VolumeKnob = styled.div`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #00ff00;
	cursor: pointer;
	position: absolute;
`;

const songs = [
	{
		src: "/sounds/radio/lizards.mp3",
		name: "We Are Lizards  -  Not Mark Zuckerberg  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/feeling_sick.m4a",
		name: "Feeling Sick  -  notta.bot  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/brothers.mp3",
		name: "Happy Holidays from Brothers & Sons  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/let_us_dance.m4a",
		name: "Let Us Dance (feat. notta.bot)  -  DJ Lampshaåde  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/dale.mp3",
		name: "Dale  -  Fields & Granger  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/tricycle.m4a",
		name: "Tricycle  -  notta.bot  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/idiots.m4a",
		name: "Sup Idiots  -  Glenn Fields  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/photograph.mp3",
		name: "Photograph  -  notta.bot  -  STARLAKE XM"
	},
	{
		src: "/sounds/radio/classact.mp3",
		name: "Episode 1  -  The Class Act Podcast  -  STARLAKE XM"
	},
];
const shuffledSongs = shuffle(songs);

export function Radio() {
	const [ slider, setSlider ] = useState();
	const [ volume, setVolume ] = useState(1);

	const { play, pause, next, previous, isPlaying, playingIndex = 0 } = usePlaylist(shuffledSongs, volume);

	useEffect(() => {
		if (!slider) return;

		const el = slider;
		const onSlide = ({ pageX }) => {
			const { left, width } = el.getBoundingClientRect();
			setVolume(clamp((pageX - left) / width, 0, 1));
		}
		const clear = () => {
			el.removeEventListener("mousemove", onSlide);
			window.removeEventListener("mouseup", clear);
		}
		const onSlideStart = e => {
			onSlide(e);
			el.addEventListener("mousemove", onSlide);
			window.addEventListener("mouseup", clear);
		}

		el.addEventListener("mousedown", onSlideStart);

		return () => {
			el.removeEventListener("mousedown", onSlideStart);
			clear();
		}
	}, [ slider ]);

	return (
		<Container>
			<RadioBar>
				<ButtonContainer>
					<RadioButton onClick={previous}>
						<RadioIcon src="/icons/skip.png" flip/>
					</RadioButton>
					<RadioButton onClick={() => isPlaying ? pause(): play()}>
						<RadioIcon src="/icons/play.png" hidden={isPlaying}/>
						<RadioIcon src="/icons/pause.png" hidden={!isPlaying}/>
					</RadioButton>
					<RadioButton onClick={next}>
						<RadioIcon src="/icons/skip.png"/>
					</RadioButton>
				</ButtonContainer>
				{/* eslint-disable-next-line */}
				<Title><marquee>{shuffledSongs[ playingIndex ].name.replace(/\s/g, "\xa0")}</marquee></Title>
				<VolumeContainer>
					<RadioIcon src="sound.png"/>
					<VolumeSlider ref={setSlider}>
						<VolumeGroove/>
						<VolumeKnob style={{ left: `calc(${volume * 100}% - 8px)` }}/>
					</VolumeSlider>
				</VolumeContainer>
			</RadioBar>
		</Container>
	)
}
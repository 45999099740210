import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { createRace, notifyDiscord, stakeDona } from "../../utils";

import { useUserDonasFromIds } from "../../Hooks";

import { Flex } from "../Styles";
import { Modal, ModalHeader } from "../Modal";
import { StakeDonaMenu } from "../StakeDonaMenu";
import { RaceConfigProgress } from "./RaceConfigProgress";
import { RaceTypePicker } from "./RaceTypePicker";
import { RaceConfigure } from "./RaceConfigure";
import { PageControl } from "./PageControl";
import { RaceReview } from "./RaceReview";
import { RaceInfo } from "./RaceInfo";

const ModalBody = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	width: calc(100% + 50px);
	max-height: calc(100vh - 400px);
	padding: 30px;
	padding-bottom: 100px;
	overflow-y: auto;
	z-index: 1;
	background-color: white;
`;

const FooterButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	min-width: 120px;
	padding: 15px;
	color: white;
	background-color: black;
	border-radius: 15px;
	cursor: pointer;
	margin-right: ${({ marginRight = "0px" }) => marginRight};
	font-weight: 700;
	${({ disabled }) => disabled && `
		opacity: 0.5;
		cursor: auto;
	`}
`;

const Loading = styled.div`
	margin-right: 15px;
	opacity: 0.5;
	font-size: 14px;
`;

export function CreateRaceDialog({ account, canTransact, isVisible, onClose }) {
	const [ stage, setStage ] = useState(1);
	
	const [ selectedDona, setSelectedDona ] = useState(null);

	const [ isTransacting, setIsTransacting ] = useState(false);

	const [ type, setType ] = useState("friendly");

	const [ maxLevel, setMaxLevel ] = useState(1);

	const [ createdRaceId, setCreatedRaceId ] = useState(null);

	useEffect(() => {
		if (!selectedDona) return;

		setMaxLevel(level => Math.max(level, selectedDona.level));
	}, [ selectedDona ]);

	const canProgress = !isTransacting && (stage !== 2 || (selectedDona && selectedDona.staked));

	const [ donas, isLoading, forceDonaReload ] = useUserDonasFromIds(account);
	
	const donaMenu = useMemo(() => (
		<StakeDonaMenu
			title="SELECT VEHICLE:"
			donas={donas}
			isLoading={isLoading}
			onSelect={setSelectedDona}
		/>
	), [ donas, isLoading ]);

	// refetch every time menu is opened, just to be sure donas are up-to-date
	useEffect(() => {
		isVisible && forceDonaReload()
	}, [ isVisible, forceDonaReload ]);

	const resetOnClose = () => {
		if (isTransacting) return;
		setStage(1);
		setSelectedDona(null);
		setType("friendly");
		setMaxLevel(1);
		onClose();
	}

	const onStake = async () => {
		if (!(await canTransact())) return;
		setIsTransacting(true);
		const { token_id } = selectedDona;
		try {
			const tx = await stakeDona(token_id);
			await tx.wait();
			setTimeout(() => {
				forceDonaReload();
				setIsTransacting(false);
			}, 1000)
		}
		catch(e) {
			alert("An error occurred while staking, or the transaction was canceled. Please try again.");
			console.error(e);
			setIsTransacting(false);
		}
	}

	const onLaunch = async () => {
		setIsTransacting(true);
		// should never happen, but better safe than sorry
		if (!(await canTransact())) return setIsTransacting(false);
		try {
			const tx = await createRace(selectedDona.token_id, maxLevel);
			const { events: [ raceStartedEvent ] } = await tx.wait();
			const [ raceId ] = raceStartedEvent.args;
			await notifyDiscord("new_race", `https://boneyard.jaypegsautomart.com/race/${raceId.toString()}`);
			setTimeout(() => {
				setCreatedRaceId(raceId.toString());
				setStage(s => s + 1);
				setIsTransacting(false);
			}, 3000);
		}
		catch(e) {
			alert("An error occurred while creating a race, or the transaction was canceled. Please try again.");
			console.error(e);
			setIsTransacting(false);
		}
	}

	return (
		<Modal
			isVisible={isVisible}
			onClose={resetOnClose}
			bg="black"
			style={{ backgroundColor: "white" }}
		>
			<ModalHeader
				title="NEW RACE"
				onClose={resetOnClose}
				invert={true}
			/>
			<RaceConfigProgress
				stage={stage}
				type={type}
				vehicleURL={selectedDona && selectedDona.image_url}
				maxLevel={stage > 2 ? maxLevel: undefined}
			/>
			<ModalBody>
				{stage === 1 && (
					<RaceTypePicker
						selectedType={type}
						onSelect={setType}
					/>
				)}
				{stage === 2 && donaMenu}
				{stage === 3 && (
					<RaceConfigure
						userLevel={selectedDona.level}
						maxLevel={maxLevel}
						setMaxLevel={setMaxLevel}
					/>
				)}
				{stage === 4 && (
					<RaceReview
						type={type}
						selectedVehicle={selectedDona.name}
						maxLevel={maxLevel}
						isLaunching={isTransacting}
					/>
				)}
				{stage === 5 && (
					<RaceInfo link={`${window.location.origin}/race/${createdRaceId}`} />
				)}
			</ModalBody>
			<PageControl
				onBack={stage > 1 && stage < 5 && (() => setStage(s => s - 1))}
				onNext={() => setStage(s => s + 1)}
				canProgress={canProgress}
				additionalButton={stage === 2 && (
					<>
						{isTransacting && <Loading>Staking... Please wait</Loading>}
						<FooterButton
							marginRight="15px"
							disabled={isTransacting || !selectedDona || selectedDona.staked}
							onClick={onStake}
						>
							STAKE
						</FooterButton>
					</>
				)}
				replaceNextButton={(
					stage === 4 
						? (
							<FooterButton
								disabled={isTransacting}
								onClick={onLaunch}
							>
								LAUNCH
							</FooterButton>
						)
						: (stage === 5 && (
							<FooterButton onClick={() => window.location.href = `/race/${createdRaceId}`}>VIEW RACE</FooterButton>
						))
				)}
			/>
		</Modal>
	)
}

import { useEffect, useState, useCallback, useRef } from "react";

import { onboard, approveBoneyardContract, isBoneyardApprovedByAccount, DEBUG } from "../utils";

export function useWallet(wallet = "MetaMask") {
	const [ account, setAccount ] = useState(null);

	async function connect() {
		if (
			await onboard.walletSelect(wallet) && 
			await onboard.walletCheck()
		) {
			const { address } = onboard.getState();
			setAccount(address);
			return address;
		}
		return false;
  }

	const [ isApproved, setIsApproved ] = useState(false);
	const [ isApproving, setIsApproving ] = useState(false);
	const isApprovedRef = useRef(isApproved);

	const checkIfAlreadyApproved = useCallback(async (address, useApprovedStatus = true) => {
		if (useApprovedStatus && isApprovedRef.current) return true;
		if (address && await isBoneyardApprovedByAccount(address)) {
			DEBUG && console.log("already approved");
			setIsApproved(true);
			isApprovedRef.current = true;
			return true;
		}
		setIsApproved(false);
		isApprovedRef.current = false;
		return false;
	}, []);

	useEffect(() => {
		const onAccountChange = () => {
			setAccount(window.ethereum.selectedAddress);
			checkIfAlreadyApproved(window.ethereum.selectedAddress, false);
		}

		window.ethereum && window.ethereum.on && window.ethereum.on("accountsChanged", onAccountChange);

		return () => window.ethereum && window.ethereum.off && window.ethereum.off("accountsChanged", onAccountChange);
	}, [ checkIfAlreadyApproved ]);

	const approve = async (address = account) => {
		if (await checkIfAlreadyApproved(address)) return true;

		try {
			setIsApproving(true);
			const tx = await approveBoneyardContract();
			await tx.wait();
			setIsApproved(true);
			isApprovedRef.current = true;
			setIsApproving(false);
			return true;
		}
		catch(e) {
			alert("An error occurred while approving the Boneyard contract. Please try again.");
			setIsApproved(false);
			isApprovedRef.current = false;
			setIsApproving(false);
			return false
		}
	}

	const canTransact = async () => {
		let address = account;
		if (!address) {
			address = await connect();
			if (!address) return false
		}
		if (!isApproved && !(await approve(address))) return false;
		return true;
	}

	return { account, connect, isApproved, approve, isApproving, canTransact };
}
import { useCallback, useEffect, useState } from "react";

export function usePlaylist(songs, volume = 1) {
	const [ audio ] = useState(new Audio());

	const [ index, setIndex ] = useState(0);

	useEffect(() => {
		if (!songs.length) return;

		audio.src = songs[ index ].src;
		audio.loop = false;
		audio.load();
		setIsLoading(true);
	}, [ audio, songs, index ]);

	useEffect(() => {
		audio.volume = volume;
	}, [ audio, volume ]);

	const [ isPlaying, setIsPlaying ] = useState(false);

	const [ isLoading, setIsLoading ] = useState(false);

	const play = useCallback(() => {
		audio.play();
		setIsPlaying(true);
	}, [ audio ]);
	const pause = useCallback(() => {
		audio.pause();
		setIsPlaying(false);
	}, [ audio ]);
	const next = useCallback(() => {
		setIndex(i => ((i + 1) % songs.length));
		setIsLoading(true);
	}, [ songs ]);
	const previous = useCallback(() => {
		setIndex(i => i === 0 ? songs.length - 1: i - 1);
		setIsLoading(true);
	}, [ songs ]);

	useEffect(() => {
		audio.addEventListener("loadeddata", () => {
			setIsLoading(false);
			isPlaying && play();
		});
	}, [ audio, isPlaying, play ]);
	useEffect(() => {
		audio.addEventListener("ended", next);
	}, [ audio, next ]);

	return { play, pause, next, previous, playingIndex: index, isPlaying, isLoading }
}
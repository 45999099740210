import { MeshStandardMaterial } from "three";

const standard = {
	black: new MeshStandardMaterial({ color: "#000000" }),
	black20: new MeshStandardMaterial({ color: "#202020" }),
	black40: new MeshStandardMaterial({ color: "#404040" }),
	steel: new MeshStandardMaterial({
		color: "#cccccc",
		metalness: 1,
		roughness: 0.25
	}),
	gold: new MeshStandardMaterial({
		color: "#ffc356",
		metalness: 1,
		roughness: 0.3
	}),
	white: new MeshStandardMaterial({ color: "#ffffff" })
}

Object.values(standard).forEach(mat => mat.color.convertSRGBToLinear());

export const sharedMaterials = {
	standard
}

export function cloneMaterialWithProps(mat, props = {}) {
	const clone = mat.clone();
	for (const [ key, value ] of Object.entries(props)) {
		switch(key) {
			case "color":
				clone.color.set(value);
				break;
			default:
				clone[ key ] = value;
				break;
		}
	}
	return clone;
}
import styled from "styled-components";

import { clamp } from "../../utils";

import { Flex } from "../Styles";
import { ModalTitle } from "../Modal";
import { useEffect, useState } from "react";

const Container = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
	padding: 30px;
	background-color: white;
	z-index: 1;
`;

const SliderContainer = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 100%;
	height: 120px;
	position: relative;
`;
const Groove = styled.div`
	width: 100%;
	height: 10px;
	background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, ${({ minimum }) => `rgba(0,0,0,0.2) ${minimum * 9}%, black ${minimum * 9 + 1}%`}, black 100%);
	pointer-events: none;
`;
const Slider = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	position: absolute;
	width: 40px;
	height: 120px;
	left: calc(${({ level }) => level * 9}% - 20px);
	pointer-events: none;
`;
const SliderNumber = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	height: 40px;
	font-size: 20px;
	font-weight: 700;
`;
const SliderSquare = styled.div`
	background-color: ${({ color = "black" }) => color};
	width: 40px;
	height: 40px;
`;

const Description = styled.div`
	text-transform: uppercase;
`;

export function RaceConfigure({ userLevel, maxLevel, setMaxLevel }) {
	const [ sliderEl, setSliderEl ] = useState();
	
	useEffect(() => {
		if (!sliderEl) return;

		const el = sliderEl;
		const onSlide = ({ offsetX }) => {
			const { width } = el.getBoundingClientRect();
			setMaxLevel(
				clamp(Math.ceil(10 * offsetX / width), userLevel, 10)
			);
		}
		const clear = () => {
			el.removeEventListener("mousemove", onSlide);
			window.removeEventListener("mouseup", clear);
		}
		const onSlideStart = e => {
			onSlide(e);
			el.addEventListener("mousemove", onSlide);
			window.addEventListener("mouseup", clear);
		}

		el.addEventListener("mousedown", onSlideStart);

		return () => {
			el.removeEventListener("mousedown", onSlideStart);
			clear();
		}
	}, [ sliderEl, userLevel, setMaxLevel ])

	return (
		<Container>
			<ModalTitle>SET MAX LEVEL</ModalTitle>
			<SliderContainer ref={setSliderEl}>
				<Groove minimum={userLevel}/>
				<Slider level={maxLevel}>
					<SliderNumber>{maxLevel}</SliderNumber>
					<SliderSquare/>
					<SliderSquare color="transparent"/>
				</Slider>
			</SliderContainer>
			<Description>
				YOUR VEHICLE IS LEVEL {userLevel}. HIGHER LEVEL VEHICLES HAVE BETTER CHANCES AT WINNING RACES. 
				HOWEVER, HAVING A HIGHER LEVEL VEHICLE DOES NOT GUARANTEE YOU WILL WIN AGAINST A LOWER LEVEL VEHICLE. 
				CHOOSE WISELY.
			</Description>
		</Container>
	)
}

import styled from "styled-components";

export const BorderButton = styled.div`
  cursor: pointer;
  display: inline-block;
  border: 3px solid #fff;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  background-color: ${({ bgColor = "#000" }) => bgColor};
  padding: 14px;
  text-transform: uppercase;
  border-radius: 14px;
  box-shadow: 1px 1px 10px #fff;

  :hover {
    background-color: #fff;
    color: #000;
  }
`;

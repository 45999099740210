import styled from "styled-components";

import { Flex } from "./Flex";

export const PillLabel = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 175px;
	height: 48px;
	padding: 0 24px;
	margin: 10px;
	border-radius: 24px;
	background-color: black;
	
	color: white;
	font-weight: 700;
`;

export const PillHeader = styled(PillLabel).attrs(() => ({
	justify: "center"
}))`
	margin: 0px;
	color: red;
	font-size: 24px;
	font-family: LLPIXEL3, sans-serif;
`;
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useWindowSize } from "@react-hook/window-size";

import { Flex } from "./Styles";
import { CautionTape } from "./CautionTape";

const Container = styled(Flex)`
  position: relative;
	width: 100vw;
	height: 100vh;
`;

const RichieImage = styled.img`
  position: fixed;
  bottom: -2px;
  right: -60px;
	user-select: none;
	${isMobile
		? `
			width: 240px;
			height: 335px;
		`
		: `
			width: 300px;
			height: 420px;
		`
	}
  z-index: 1000;
	transition: opacity 0.5s ease;

	&:hover {
		opacity: 0.5;
	}
	&:active {
		pointer-events: none;
	}
`;

const FenceContainer = styled(Flex).attrs(({ align }) => ({
  justify: align,
  align: align,
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("/fence.png");
  background-position: center;
  background-size: cover;
  z-index: 2;
`;

const EmptyContainer = styled(Flex).attrs(() => ({
  justify: "center"
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  //z-index: 2;
`;

const BgVideo = styled.video`
  position: fixed;
`;

export function Wrapper({ children, disableFence, contentAlign = 'center', bgVideo = true }) {
  const [ windowWidth, windowHeight ] = useWindowSize(0, 0, { fps: 1 });
  const windowAspect = windowHeight / windowWidth;

  const vidAspect = 0.5625;
  return (
    <Container>
			{bgVideo && (
				<BgVideo
					src="/smoke.mp4"
					autoPlay={true}
					loop={true}
					playsInline={true}
					muted={true}
					width={windowAspect > vidAspect ? windowHeight / vidAspect : windowWidth}
					height={windowAspect > vidAspect ? windowHeight : windowWidth * vidAspect}
				/>
			)}

      <CautionTape position={"left"} />
      <CautionTape position={"top"} />
      <CautionTape position={"right"} />
      <CautionTape position={"bottom"} />
      <RichieImage src={"/images/richie.png"} />

      {!disableFence 
				? <FenceContainer align={contentAlign}>{children}</FenceContainer>
				: <EmptyContainer align={contentAlign}>{children}</EmptyContainer>
			}
    </Container>
  );
}

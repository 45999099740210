import { createContext, useContext, useEffect, useState } from "react"
import axios from "axios"

const defaultState = {
	donaMetadata: undefined
}

const DonaContext = createContext(defaultState)

export const useDonaContext = () => useContext(DonaContext)

export function DonaProvider({ children }) {
	const [ donaMetadata, setDonaMetadata ] = useState()

	useEffect(() => {
		const getMetadata = async () => {
			try {
				const { data } = await axios.get("https://d1bvlks3ibbm3s.cloudfront.net/donas/metadata-by-token.json")
				Object.values(data).forEach((dona) => {
					[ "256", "512", "1024", "original" ].forEach((size) => {
						if (dona.images?.[ size ]) dona.images[ size ] = `https://d1bvlks3ibbm3s.cloudfront.net/donas/${dona.images[ size ]}`
					})
				})
				setDonaMetadata(data)
			} catch(err) {
				console.warn("Unable to fetch metadata", err.message)
				setDonaMetadata(undefined)
			}
		}
		getMetadata()
	}, [])

	return (
		<DonaContext.Provider value={{
			donaMetadata
		}}>
			{children}
		</DonaContext.Provider>
	)
}
import { useEnvironment } from "./useEnvironment";
import { useHeadlights } from "./useHeadlights";
import { usePaint } from "./usePaint";
import { useTowing } from "./useTowing";
import { useUpgrades } from "./useUpgrades";
import { useWheels } from "./useWheels";
import { useWindows } from "./useWindows";

export function useDonaTraits(traits = []) {

	const special = useEnvironment(traits);
	const towing = useTowing(traits);
	const paintProps = usePaint(traits);
	const wheelProps = useWheels(traits);
	const headlights = useHeadlights(traits);
	const windows = useWindows(traits);
	const upgrades = useUpgrades(traits);

	if (!traits || !traits.length) return undefined;
	return {
		towing,
		paintProps,
		wheelProps,
		headlights,
		windows,
		upgrades,
		...special
	}
}
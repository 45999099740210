import { Suspense, useState } from "react";
import { AmbientLight } from "three";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, PerspectiveCamera, Stats } from "@react-three/drei";
import { EffectComposer, Selection, SelectiveBloom } from "@react-three/postprocessing";

import { GarageDona } from "./GarageDona";
import { ShadowPlane } from "../ShadowPlane";

export function Garage3D({ dona, loading, speed, ...props }) {
	const [ camera, setCamera ] = useState();

	return (
		<Suspense fallback={loading}>
			<Canvas
				background="black"
				dpr={window.devicePixelRatio}
				flat={true}
				shadows>
				<Stats/>
				<Environment
					background={false}
					preset="city"
				/>
				<OrbitControls
					camera={camera}
					autoRotate={true}
					enableZoom={false}
					enablePan={false}
					minPolarAngle={Math.PI / 2}
					maxPolarAngle={Math.PI / 2}
				/>
				<PerspectiveCamera
					ref={setCamera}
					makeDefault
					position={[ 0, 0, 8 ]}
					fov={40}
					aspect={window.innerWidth / window.innerHeight}
					near={1}
					far={1000}
				/>
				<ambientLight
					color="#bbb"
					intensity={0.8}
				/>
				<directionalLight
					color="#ef7800"
					intensity={1.2}
					position={[ 1, 10, -2 ]}
					castShadow={true}
					shadow-mapSize-width={1024}
					shadow-mapSize-height={1024}
					shadow-camera-far={50}
					shadow-camera-left={-10}
					shadow-camera-right={10}
					shadow-camera-top={10}
					shadow-camera-bottom={-10}
				/>
				<Selection>
					<EffectComposer autoClear={false}>
						<SelectiveBloom
							intensity={2}
							luminanceThreshold={0.6}
							luminanceSmoothing={0.1}
							lights={[ new AmbientLight(0xbbbbbb, 0.8) ]}
						/>
					</EffectComposer>
					{!!dona && (
						<group {...props}>
							<GarageDona
								traits={dona.traits}
								speed={speed}
							/>
						</group>
					)}
				</Selection>
				<ShadowPlane position={[ 0, -2, 0 ]}/>
			</Canvas>
		</Suspense>
	)
}
import { useCallback, useEffect, useRef, useState } from "react";
import { MathUtils, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";

import { Dona } from "../DonaModel";
import { useDonaTraits } from "../../Hooks";

const targetScale = new Vector3(1, 1, 1);
export function Racer({ radius, rotation, traits = [], isRacing, ...props }) {
	const [ groupRef, setGroupRef ] = useState();
	const pivotRef = useRef();
	const vanRef = useRef();

	const traitProps = useDonaTraits(traits);

	const rotationRef = useRef({
		value: 0,
		target: 0
	});

	useEffect(() => {
		rotationRef.current.target = rotation;
	}, [ rotation ]);

	const moveDona = useCallback((transform) => {
		transform({
			dona: vanRef.current,
			startPos: [ 0, radius, 0 ]
		})
	}, [ radius ]);

	useFrame((_, delta) => {
		if (groupRef && traitProps) {
			if (groupRef.scale.x !== 1) groupRef.scale.lerp(targetScale, 0.05);
			if (groupRef.scale.x > 0.99) groupRef.scale.set(1, 1, 1);
		}
		
		if (rotationRef.current.value === rotationRef.current.target) return;

		const { value, target } = rotationRef.current;
		rotationRef.current.value = MathUtils.lerp(value, target, 0.025 * 60 * delta);
		if (Math.abs(rotationRef.current.value - target) < 0.0001) {
			rotationRef.current.value = target;
		}
		pivotRef.current && (pivotRef.current.rotation.x = rotationRef.current.value);
	});

	return (
		<group
			ref={setGroupRef}
			{...props}
			scale={0}>
			<group
				ref={pivotRef}
				position={[ 0, -radius, 0 ]}>
				<group
					ref={vanRef}
					position={[ 0, radius, 0 ]}>
					<Dona
						speed={isRacing ? 1: 0}
						position={[ 0, -0.05, -0.2 ]}
						scale={0.75}
						moveDona={moveDona}
						{...traitProps}
					/>
				</group>
			</group>
		</group>
	)
}
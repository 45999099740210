import { useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import { useWallet } from "../../Hooks";

import { BorderButton, Flex } from "../Styles";
import { WrapperWithMenu } from "../WrapperWithMenu";
import { JoinRaceModal } from "./JoinRaceModal";
import { RaceBlock } from "./RaceBlock";

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
  width: 100%;
	margin-top: 100px;
	padding-bottom: 100px;
`;

const MuteButton = styled(BorderButton)`
	margin-left: 24px;
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover > img {
		filter: brightness(0) grayscale(100%) !important;
	}
`;
const SoundIcon = styled.img`
	width: 21px;
	height: 21px;
	filter: invert() brightness(100) grayscale(100%);
	${({ hidden }) => hidden && `display: none;`}
`;

export function RacePage() {
	const { id } = useParams();

  const { account, connect, canTransact } = useWallet();

	const [ isMuted, setIsMuted ] = useState(true);

	const [ joinRaceModalActive, setJoinRaceModalActive ] = useState(false);

  return (
    <WrapperWithMenu
			account={account}
			connectWallet={connect}
			allowed={true}
			canTransact={canTransact}
			menuItems={
				<MuteButton onClick={() => setIsMuted(m => !m)}>
					<SoundIcon
						src="/sound.png"
						hidden={isMuted}
					/>
					<SoundIcon
						src="/no-sound.png"
						hidden={!isMuted}
					/>
				</MuteButton>
			}
		>
      <Container>
				<RaceBlock
					raceId={id}
					canTransact={canTransact}
					openJoinMenu={async () => (await canTransact() && setJoinRaceModalActive(true))}
					isMuted={isMuted}
				/>
      </Container>
			<JoinRaceModal
				account={account}
				canTransact={canTransact}
				onClose={() => setJoinRaceModalActive(false)}
				visible={joinRaceModalActive}
				raceId={id}
			/>
    </WrapperWithMenu>
  );
}

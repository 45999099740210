import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MeshStandardMaterial, RepeatWrapping, sRGBEncoding } from "three";
import { useFrame } from "@react-three/fiber";
import { useGLTF, useTexture } from "@react-three/drei";

import { prefix3D } from "../utils";

export function Dona({
	speed = 0,
	position = [ 0, 0, 0 ],
	curved = true,
	moveDona = undefined,
	environment: {
		overrideMat = undefined
	} = {},
	towing: TowingModel = undefined,
	paintProps: {
		color = "#ffffff",
		texture = undefined,
		textureProps = {},
		metalness = 0,
		roughness = 0.05,
		material: paintMat = undefined
	} = {},
	wheelProps: {
		model: WhModel = undefined,
		retainTires = false,
		retainRims = false,
		rimMat = undefined,
		tireMat = undefined,
		spins = true,
		moveDona: movementFn = undefined
	} = {},
	headlights: {
		material: hMat = undefined,
		model: HModel = undefined,
		retainHeadlights = false
	} = {},
	windows: {
		material: wMat = undefined,
		windowMat = undefined,
		windshieldMat = undefined,
		model: WModel = undefined
	} = {},
	upgrades: UpgradeModel = undefined,
	...props
}) {
	const [ group, setGroup ] = useState();

	useLayoutEffect(() => {
		group && group.traverse(obj => {
			obj.castShadow = true;
			obj.receiveShadow = true;
		});
	}, [ group ]);

	const frontRims = useRef();
	const backRims = useRef();

	const map = useTexture(texture || []);
	const { nodes, materials } = useGLTF(prefix3D + "dona_compressed_v3.glb");

	const bodyMat = useMemo(() => {
		if (!materials.carpaint) return null;

		return new MeshStandardMaterial({ color: "#00ff00" });
	}, [ materials.carpaint ]);

	useEffect(() => {
		if (!bodyMat) return;

		bodyMat.color.set(color);
		bodyMat.metalness = metalness;
		bodyMat.roughness = roughness;
		bodyMat.needsUpdate = true;
	}, [ bodyMat, color, metalness, roughness ]);

	useEffect(() => {
		if (!bodyMat) return;
		if (!map || (Array.isArray(map) && map.length === 0)) {
			bodyMat.map = null;
			return;
		}

		const { repeat = [ 1, 1 ], offset = [ 0, 0 ] } = textureProps;
		bodyMat.map = map;
		bodyMat.map.wrapS = bodyMat.map.wrapT = RepeatWrapping;
		bodyMat.map.center.set(0.5, 0.5);
		bodyMat.map.repeat.set(...repeat);
		bodyMat.map.offset.set(...offset);
		bodyMat.map.encoding = sRGBEncoding;
		bodyMat.map.flipY = false;
		bodyMat.map.needsUpdate = true;
	}, [ bodyMat, map, textureProps ]);

	useEffect(() => {
		if (!materials.windowglass) return;

		materials.windowglass.color.set("#000000");
		materials.windowglass.opacity = 0.5;
		materials.windowglass.transparent = true;
		materials.windowglass.needsUpdate = true;
	}, [ materials.windowglass ]);

	useEffect(() => {
		if (!materials.clearglass) return;
		
		materials.clearglass.metalness = 1;
		materials.clearglass.roughness = 0.2;
		materials.clearglass.opacity = 0.5;
		materials.clearglass.transparent = true;
		materials.clearglass.needsUpdate = true;
	}, [ materials.clearglass ]);

	useEffect(() => {
		frontRims.current && (frontRims.current.rotation.x = 0);
		backRims.current && (backRims.current.rotation.x = 0);
	}, [ spins ]);

	useFrame(({ clock }, delta) => {
		if (speed && spins) {
			frontRims.current && (frontRims.current.rotation.x += 15 * speed * delta);
			backRims.current && (backRims.current.rotation.x += 15 * speed * delta);
		}
		moveDona && movementFn && moveDona(args => movementFn({
			...args,
			delta,
			elapsed: clock.getElapsedTime()
		}))
	});

	return (
		<group
			ref={setGroup}
			position={position}
			{...props}
			dispose={null}>
			{TowingModel && (
				<TowingModel
					position={[ 0, 0, -2.75 ]}
					rotation={[ curved ? -Math.PI / 25: 0, 0, 0 ]}
					speed={speed}
					curved={curved}
				/>
			)}
			{UpgradeModel && (
				<UpgradeModel
					speed={speed}
					curved={curved}
					moveDona={moveDona}
				/>
			)}
			<group scale={0.01}>
				<mesh
					geometry={nodes.back_wiper.geometry}
					material={overrideMat || materials.black}
					position={[-24.19, 126.7, -236.2]}
				/>
				<mesh
					geometry={nodes.black_f_grill_parrt03.geometry}
					material={overrideMat || materials.black}
					position={[0, 71.51, 238.9]}
				/>
				<mesh
					position={[0, 70.74, 237.85]}
					geometry={nodes.black_radiator01.geometry}
					material={overrideMat || materials.black}
				/>
				<mesh
					position={[0, 102.08, -0.4]}
					geometry={nodes.body.geometry}
					material={overrideMat || (!!texture ? bodyMat: (paintMat || bodyMat))}
				/>
				<mesh
					geometry={nodes.chrome_f_grill_part00.geometry}
					material={overrideMat || materials.chrome}
					position={[0, 71.55, 238.77]}
				/>
				<mesh
					geometry={nodes.chrome_f_grill_part01.geometry}
					material={overrideMat || materials.chrome}
					position={[0, 71.51, 242.66]}
				/>
				<mesh
					geometry={nodes.dets1.geometry}
					material={overrideMat || materials.black}
					position={[0.02, 115.43, -239.37]}
				/>
				<mesh
					geometry={nodes.dets2.geometry}
					material={overrideMat || materials.black}
					position={[0, 71.5, 243.4]}
				/>
				<mesh
					geometry={nodes.ex.geometry}
					material={overrideMat || materials.chrome}
					position={[-56.88, 77.1, -235.3]}
				/>
				<mesh
					geometry={nodes.floor.geometry}
					material={overrideMat || materials.black}
					position={[0, 53.29, 1.28]}
				/>
				<mesh
					geometry={nodes.frame001.geometry}
					material={overrideMat || materials.black}
					position={[0, 80.1, 222.47]}
				/>
				<mesh
					geometry={nodes.Front_Logo.geometry}
					material={overrideMat || materials.chrome}
					position={[0.02, 115.34, -238.81]}
				/>
				<mesh
					geometry={nodes.Front_Logo001.geometry}
					material={overrideMat || materials.chrome}
					position={[0, 71.33, 242.73]}
				/>
				<mesh
					geometry={nodes.headlight_backplates.geometry}
					material={overrideMat || materials.chrome} 
					scale={100}
				/>
				<group position={[0, 80.06, 224.41]}>
					{HModel && <HModel/>}
					{(!HModel || retainHeadlights) && (
						<mesh
							geometry={nodes.headlights.geometry} // headlights
							material={overrideMat || hMat || materials.clearglass}
						/>
					)}
				</group>
				<mesh
					geometry={nodes.headlights_interior.geometry}
					material={overrideMat || materials.black}
					scale={100}
				/>
				<mesh
					geometry={nodes.interior01.geometry}
					material={overrideMat || materials.interior}
					position={[0, 103.46, -44.11]}
				/>
				<mesh
					geometry={nodes.KIA_logo_front.geometry}
					material={overrideMat || materials.chrome}
					position={[0.27, 115.5, -239.38]}
				/>
				<mesh
					geometry={nodes.KIA_logo_front001.geometry}
					material={overrideMat || materials.chrome}
					position={[-0.1, 71.59, 243.39]}
				/>
				<mesh
					geometry={nodes.mirror01.geometry}
					material={overrideMat || materials.mirror}
					position={[0, 120.4, 90.78]}
				/>
				<mesh
					geometry={nodes.mirror_box.geometry}
					material={overrideMat || materials.black}
					position={[0, 122.19, 104.24]}
				/>
				<mesh
					geometry={nodes.mirrors.geometry}
					material={overrideMat || paintMat || bodyMat}
					position={[0, 120.12, 97.17]}
				/>
				<mesh
					geometry={nodes.orange_glass.geometry}
					material={overrideMat || materials.orangeglass}
					position={[0, 77.51, 219.4]}
				/>
				<mesh
					geometry={nodes.redglass_r_top.geometry}
					material={overrideMat || materials.redglass}
					position={[0, 168.99, -197.18]}
				/>
				<group
					ref={frontRims}
					position={[-92.92, 35.47, 160.47]}>
						{WhModel && (
							<WhModel
								side="front"
								speed={speed}
							/>
						)}
						{(!WhModel || retainTires) && (
							<mesh
								geometry={nodes.front_tires.geometry}
								material={overrideMat || tireMat || materials.tire}
								position={[8.07, 0, 0]}
								rotation={[0, 0, -Math.PI / 2]}
								scale={[176.36, 96.24, 176.36]}
							/>
						)}
						{(!WhModel || retainRims) && (
							<mesh
								geometry={nodes.rim03.geometry}
								material={overrideMat || rimMat || materials.rim}
							/>
						)}
				</group>
				<group
					ref={backRims}
					position={[-92.92, 35.47, -142.47]}>
						{WhModel && (
							<WhModel
								side="rear"
								speed={speed}
							/>
						)}
						{(!WhModel || retainTires) && (
							<mesh
								geometry={nodes.rear_tires.geometry}
								material={overrideMat || tireMat || materials.tire}
								position={[8.07, 0, 0]}
								rotation={[0, 0, -Math.PI / 2]}
								scale={[176.36, 96.24, 176.36]}
							/>
						)}
						{(!WhModel || retainRims) && (
							<mesh
								geometry={nodes.rim03001.geometry}
								material={overrideMat || rimMat || materials.rim}
							/>
						)}
				</group>
				<mesh
					geometry={nodes.runners.geometry}
					material={overrideMat || materials.black}
					position={[0, 177.32, -81.66]}
				/>
				<mesh
					geometry={nodes.runners_bars.geometry}
					material={overrideMat || materials.mattemetal}
					position={[0, 180.43, -80.62]}
				/>
				<mesh
					geometry={nodes.running_lights_housing.geometry}
					material={overrideMat || materials.black}
					position={[0, 37.93, 232.31]}
				/>
				<mesh
					geometry={nodes.Sedona_r_text.geometry}
					material={overrideMat || materials.chrome}
					position={[53.14, 76.98, -236.35]}
				/>
				<mesh
					geometry={nodes.taillights_clear.geometry}
					material={overrideMat || materials.clearglass}
					position={[0, 112.46, -223.79]}
				/>
				<mesh
					geometry={nodes.taillights_red.geometry}
					material={overrideMat || materials.redglass}
					position={[0, 98.93, -223.63]}
				/>
				<mesh
					geometry={nodes.trunk_handle.geometry}
					material={overrideMat || materials.chrome}
					position={[0, 110.7, -237.79]}
				/>
				<mesh
					geometry={nodes.window_frame.geometry}
					material={overrideMat || materials.black}
					position={[0, 137.58, -1.76]}
				/>
				<mesh
					geometry={nodes.window_rear.geometry}
					material={overrideMat || wMat || materials.windowglass}
					position={[0, 143.61, -216.71]}
				/>
				<mesh
					geometry={nodes.windows.geometry}
					material={overrideMat || windowMat || wMat || materials.windowglass}
					position={[0, 138.08, -48.98]}
				/>
				<group position={[0, 139.31, 104.61]}>
					<mesh
						geometry={nodes.windshield.geometry}
						material={overrideMat || windshieldMat || wMat || materials.windowglass}
					/>
					{WModel && <WModel/>}
				</group>
				<mesh
					geometry={nodes.wiper.geometry}
					material={overrideMat || materials.black}
					position={[3.33, 113.22, 145.9]}
				/>
			</group>
		</group>
	)
}

useGLTF.preload(prefix3D + "dona_compressed_v3.glb");
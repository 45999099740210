import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { stakeDona, joinRace, DEBUG } from "../../utils";

import { useUserDonasFromIds } from "../../Hooks";

import { Flex } from "../Styles";
import { Modal, ModalBody, ModalText } from "../Modal";
import { StakeDonaMenu } from "../StakeDonaMenu";

const ModalFooter = styled(Flex).attrs(() => ({
	justify: "flex-end",
	align: "center"
}))`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	border: 5px solid black;
	background-color: white;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
`;

const FooterButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	width: 120px;
	padding: 15px;
	color: white;
	background-color: black;
	border-radius: 15px;
	cursor: pointer;
	margin-right: 15px;
	font-weight: 700;
	${({ disabled }) => disabled && `
		opacity: 0.5;
		cursor: auto;
	`}
`;

const Loading = styled.div`
	margin-right: 15px;
	opacity: 0.5;
`;

const JoinSuccess = styled(ModalText)`
	padding-bottom: 40px;
`;

export function JoinRaceModal({ account, canTransact, onClose, visible, raceId }) {
	const [ selected, setSelected ] = useState(null);

	const [ isTransacting, setIsTransacting ] = useState(false);

	const [ hasJoined, setHasJoined ] = useState(false);

	const [ donas, isLoading, forceDonaReload ] = useUserDonasFromIds(account);
	const donaMenu = useMemo(() => (
		<StakeDonaMenu
			donas={donas}
			isLoading={isLoading}
			onSelect={setSelected}
		/>
	), [ donas, isLoading ]);

	// refetch every time menu is opened, just to be sure donas are up-to-date
	useEffect(() => {
		visible && forceDonaReload()
	}, [ visible, forceDonaReload ]);

	const stakeSelected = async () => {
		if (!(await canTransact())) return;
		setIsTransacting(true);
		try {
			const tx = await stakeDona(selected.token_id);
			await tx.wait();
			selected.staked = true;
			setTimeout(() => {
				setIsTransacting(false);
				forceDonaReload();
			}, 1000);
		}
		catch(e) {
			alert(`An error occurred while staking. Please reload the page and try again.`);
			console.error(e);
			setIsTransacting(false);
		}
	}

	const onJoin = async () => {
		if (!(await canTransact())) return;
		setIsTransacting(true);
		DEBUG && console.log(selected);
		try {
			const tx = await joinRace(raceId, selected.token_id);
			await tx.wait();
			setHasJoined(true);
			setSelected(null);
		}
		catch(e) {
			alert("An error occurred while joining the race.");
			console.error(e);
		}
		finally {
			setIsTransacting(false);
		}
	}

  return (
    <Modal
      style={{
				backgroundColor: "white",
				paddingBottom: "100px"
			}}
      isVisible={visible}
      onClose={() => {
				if (isTransacting) return;
				setSelected(false);
				setHasJoined(false);
				onClose();
			}}
			title="JOIN RACE"
    >
			<ModalBody>
				{hasJoined
					? <JoinSuccess>You have successfully joined the race (#{raceId}). Your Dona should appear in the race shortly.</JoinSuccess>
					: donaMenu
				}
			</ModalBody>
			<ModalFooter>
				{isTransacting && <Loading>Joining...</Loading>}
				<FooterButton
					disabled={isTransacting || !selected || selected.staked}
					onClick={stakeSelected}
				>
					STAKE
				</FooterButton>
				<FooterButton
					disabled={isTransacting || !selected || !selected.staked}
					onClick={onJoin}
				>
					JOIN
				</FooterButton>
			</ModalFooter>
    </Modal>
  )
}
import styled from "styled-components";

import { Flex } from "../Styles";
import { RaceType } from "./RaceType";
import { ModalTitle } from "../Modal";

const Container = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
`;

const PickerContainer = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
	margin: 30px 0;
`;

const raceTypes = [
  {
    name: "friendly",
    description: "RACE FOR STREET CRED (XP). NO ENTRY FEE.",
    image: "/images/raceType/friendly.png"
  },
  {
    name: "PINK SLIPS",
    description: "RACE FOR DONAS. WINNER KEEPS ALL DONAS STAKED IN THE RACE.",
    image: "/images/raceType/slips.png",
		disabled: true
  },
  {
    name: "gang",
    description: "RACE AGAINST FUSION 5 TO DRAIN THEIR TREASURY.",
    image: "/images/raceType/gang.png",
		disabled: true
  },
];

export function RaceTypePicker({ selectedType, onSelect }) {
	return (
		<Container>
			<ModalTitle>SELECT RACE TYPE:</ModalTitle>
			<PickerContainer>
				{raceTypes.map((type, i) => (
					<RaceType
						{...type}
						key={i}
						selected={type.name === selectedType}
						onSelect={onSelect}
					/>
				))}
			</PickerContainer>
		</Container>
	)
}

import { useCallback, useEffect, useRef } from "react";

// options includes things like volume, loop, etc.
export function useSoundEffect(src, { loop = false, ...options } = {}) {
	const audio = useRef(new Audio(src));

	audio.current.loop = loop;
	Object.entries(options).forEach(([ prop, val ]) => audio.current[ prop ] = val);

	useEffect(() => {
		audio.current.src = src;
		audio.current.load();
	}, [ src ]);

	const play = useCallback(() => audio.current.play(), []);

	const pause = useCallback(() => audio.current.pause(), []);
	
	return [ play, pause ];
}
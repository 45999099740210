import { useEffect, useMemo } from "react";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import { useGraph } from "@react-three/fiber";
import { useAnimations, useGLTF } from "@react-three/drei";

export function useGLTFAnimation({ path, groupRef, animRef, ...options }) {
	const { scene, materials, animations } = useGLTF(path);

	const clone = useMemo(() => SkeletonUtils.clone(scene), [ scene ])
  const { nodes } = useGraph(clone);
	const { mixer } = useAnimations(animations, groupRef);

	useEffect(() => {
		if (!animations || !mixer) return;
		animRef.current = mixer.clipAction(animations[0], groupRef.current);
		for (const [ key, value ] of Object.entries(options)) {
			switch(key) {
				case "loop":
					animRef.current.loop = value;
					break;
				case "repetitions":
					animRef.current.repetitions = value;
					break;
				default:
					break;
			}
		}
	}, [ groupRef, animRef, animations, mixer, options ]);

	return {
		nodes,
		materials,
	}
}
import styled from "styled-components";

import { Flex } from "../Styles";
import { ModalTitle } from "../Modal";

const Container = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
`;

const RaceParam = styled.div`
	font-size: 18px;
	text-transform: uppercase;
	height: 20px;
	margin-top: 20px;

	& > span {
		font-weight: 700;
	}
`;

const Description = styled.div`
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 20px;
`;

export function RaceReview({ type, selectedVehicle, maxLevel, isLaunching }) {
	return (
		<Container>
			<ModalTitle>{"REVIEW & LAUNCH"}</ModalTitle>
			{isLaunching
				? <Description>Launching Race... Please wait</Description>
				: (
					<>
						<RaceParam>
							RACE TYPE: <span>{type}</span>
						</RaceParam>
						<RaceParam>
							VEHICLE: <span>{selectedVehicle}</span>
						</RaceParam>
						<RaceParam>
							MAX LEVEL: <span>{maxLevel}</span>
						</RaceParam>
						<Description>
							ONCE LAUNCHED, THE RACE WILL BE OPEN FOR 600 BLOCKS (~2.5 HOURS). 
							IF ALL 7 SLOTS ARE FILLED AT ANY POINT IN THAT TIME, THE RACE WILL START IMMEDIATELY. 
							IF NO ONE ELSE JOINS WITHIN THAT TIME, THE RACE WILL BE CANCELED.
						</Description>
					</>
				)
			}
		</Container>
	)
}
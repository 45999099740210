import { useState, useReducer, useEffect, useRef } from "react";

import { getUserDonasIds, getStakedDonasByOwner, getDonaLevels, getDonaXPs, areRacing, DEBUG } from "../utils";
import { useDonaContext } from "../providers";

export function useUserDonasFromIds(address = window.ethereum.selectedAddress) {
	const { donaMetadata } = useDonaContext()
  const [ userDonas, setUserDonas ] = useState([]);

	const [ isLoading, setIsLoading ] = useState(false);
	const isLoadingRef = useRef(isLoading);
	useEffect(() => {
		isLoadingRef.current = isLoading;
	}, [ isLoading ]);

	const [ reloadTrigger, forceReload ] = useReducer(x => !x, false);

  useEffect(() => {
    if (!address || !donaMetadata) {
			setUserDonas([]);
			return;
		}
		if (isLoadingRef.current) return;

    const getAssets = async () => {
			setIsLoading(true);
			try {
				const tokenIds = await getUserDonasIds(address);

				const parkedDonas = await getStakedDonasByOwner(address);
				const parkedDonaIds = parkedDonas.map(({ id }) => id);
				// parkedDonas tracks any dona that has ever been staked, so there could be overlap
				// make sure all ids are in array as same type (String) and use Set to remove duplicates
				const allDonaIds = parkedDonaIds.concat(tokenIds.map(id => String(id)));
				const allUniqueDonaIds = [ ...(new Set(allDonaIds)) ];

				if (!allUniqueDonaIds.length) {
					setIsLoading(false);
					setUserDonas([]);
					return;
				}
				
				const [ donaLevels, donaXPs, activeRacers ] = await Promise.all([
					getDonaLevels(allUniqueDonaIds),
					getDonaXPs(allUniqueDonaIds),
					areRacing(parkedDonaIds)
				]);
				const donasWithAttributes = (allUniqueDonaIds || []).map(tokenId => {
					const dona = donaMetadata[ tokenId ] || {}
					const { parked = false } = parkedDonas.find(({ id }) => id === tokenId) || {};
					return {
						...dona,
						staked: parked,
						level: donaLevels[ tokenId ] || 1,
						xp: donaXPs[ tokenId ] || 0,
						isRacing: activeRacers[ tokenId ] || false
					};
				});
				DEBUG && console.log(donasWithAttributes);
				setUserDonas(donasWithAttributes);
				setIsLoading(false);
				return reloadTrigger; // silence warnings
			}
			catch(e) {
				alert(`An error occurred while fetching your 'Donas. Please reload and try again.`);
				console.error(e);
				setIsLoading(false);
				setUserDonas([]);
			}
		}
		getAssets();
  }, [ address, reloadTrigger, donaMetadata ]);

  return [ userDonas, isLoading, forceReload ];
}
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { DonaProvider } from "./providers";

import { Home } from "./Components/Home";
import { RacePage } from "./Components/RacePage";
import { RacePage3D } from "./Components/RacePage3D";
import { GaragePage } from "./Components/GaragePage";
import { RandomGaragePage } from "./Components/GaragePage/Random";

function App() {
  return (
    <div className="App">
			<DonaProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/random-garage" element={<RandomGaragePage/>}/>
						<Route path="/garage" element={<GaragePage/>}/>
						<Route path="/race3d/" element={<RacePage3D/>}/>
						<Route path="/race/:id" element={<RacePage/>}/>
						<Route path="/" element={<Home/>}/>
					</Routes>
				</BrowserRouter>
			</DonaProvider>
		</div>
  );
}

export default App;

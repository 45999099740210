export const WheelIcon = (props) => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
	width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
	preserveAspectRatio="xMidYMid meet" {...props}>
		<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
			<path d="M845 4726 c-62 -27 -101 -102 -90 -174 8 -54 73 -125 124 -135 19 -4
				227 -7 464 -7 l431 0 -45 -32 c-142 -101 -237 -179 -329 -273 l-105 -105 -570
				0 c-354 0 -584 -4 -607 -10 -21 -6 -51 -27 -71 -50 -57 -63 -61 -141 -11 -207
				49 -65 35 -63 549 -63 257 0 465 -3 463 -7 -1 -5 -33 -69 -69 -143 -215 -435
				-276 -903 -183 -1383 49 -251 165 -543 298 -747 l55 -85 -257 -5 c-234 -5
				-260 -7 -291 -25 -53 -31 -74 -67 -79 -132 -3 -48 0 -64 20 -92 12 -19 36 -44
				51 -55 28 -20 40 -21 440 -24 l411 -3 46 -43 c49 -46 244 -194 270 -205 8 -3
				-354 -8 -805 -11 -754 -5 -823 -6 -852 -22 -49 -27 -76 -74 -81 -137 -4 -65
				19 -109 77 -148 l34 -23 1486 0 c1588 0 1563 0 1796 50 385 84 760 289 1046
				573 194 192 315 359 434 598 299 604 299 1314 0 1918 -288 579 -801 998 -1415
				1155 -260 66 -258 66 -1493 66 -900 -1 -1118 -3 -1142 -14z m2315 -831 c466
				-76 888 -424 1045 -863 150 -418 98 -856 -146 -1227 -66 -100 -259 -295 -359
				-362 -142 -95 -303 -165 -480 -208 -104 -26 -375 -31 -501 -11 -284 47 -525
				172 -735 381 -203 202 -323 428 -380 713 -24 115 -24 353 -1 478 61 336 266
				663 538 859 161 116 379 208 568 240 108 18 337 18 451 0z"/>
			<path d="M2683 3550 c-167 -44 -315 -126 -443 -246 -113 -107 -228 -268 -198
				-279 7 -3 102 -34 212 -69 l198 -64 72 73 c57 57 91 82 159 115 l87 42 0 224
				c0 123 -3 224 -7 223 -5 0 -40 -9 -80 -19z"/>
			<path d="M3102 3344 l3 -226 48 -19 c71 -28 158 -90 214 -153 l49 -55 204 65
				c113 36 209 70 214 74 11 11 -67 130 -140 211 -145 164 -355 283 -562 322
				l-32 7 2 -226z"/>
			<path d="M2870 2801 c-77 -24 -152 -94 -170 -160 -24 -89 -7 -177 46 -239 95
				-111 248 -121 357 -23 101 90 107 252 14 350 -62 66 -167 96 -247 72z"/>
			<path d="M1916 2638 c-15 -167 29 -382 110 -539 68 -132 173 -265 193 -243 5
				5 64 85 131 178 l123 170 -18 30 c-60 99 -85 174 -100 297 l-6 46 -207 66
				c-114 37 -210 67 -213 67 -3 0 -9 -33 -13 -72z"/>
			<path d="M3723 2643 l-202 -66 -7 -66 c-9 -90 -36 -175 -79 -245 -20 -33 -32
				-63 -27 -71 4 -6 63 -89 131 -183 l124 -171 34 37 c107 119 206 313 237 462
				31 152 33 371 3 369 -7 0 -103 -30 -214 -66z"/>
			<path d="M2611 1830 c-72 -99 -131 -182 -131 -184 0 -3 37 -19 83 -36 239 -93
				505 -93 745 0 45 17 82 34 82 37 0 6 -250 352 -259 357 -4 3 -33 -2 -66 -10
				-93 -21 -211 -17 -306 12 -12 3 -51 -43 -148 -176z"/>
		</g>
	</svg>
)

import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";

import { prefix3D } from "../../utils";

export function TrafficLight({ status, setStatus, bloomEnabled = true, rotation, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "traffic_light_compressed.glb");

	const { redMat = undefined, yellowMat = undefined, greenMat = undefined } = useMemo(() => {
		if (!materials) return undefined;

		return {
			redMat: materials.red_light.clone(),
			yellowMat: materials.yellow_light.clone(),
			greenMat: materials.green_light.clone()
		}
	}, [ materials ]);

	return (
		<group
			rotation={rotation}
			{...props}
			dispose={null}>
			<group position={[ 0, -0.75, 0 ]}>
				<mesh
					geometry={nodes.Cube.geometry}
					material={materials.black}
				/>
				<Select enabled={status === 0}>
					<mesh
						geometry={nodes.Cylinder.geometry}
						material={yellowMat}
						material-color={status === 0 ? "#ffff00": "#7f7f00"}
						position={[0, 0.75, 0.1]}
						onClick={() => setStatus && setStatus(0)}
					/>
				</Select>
				<Select enabled={status === -1}>
					<mesh
						geometry={nodes.Cylinder001.geometry}
						material={redMat}
						material-color={status === -1 ? "#ff0000": "#7f0000"}
						position={[0, 1.23, 0.1]}
						onClick={() => setStatus && setStatus(-1)}
					/>
				</Select>
				<Select enabled={status === 1}>
					<mesh
						geometry={nodes.Cylinder002.geometry}
						material={greenMat}
						material-color={status === 1 ? "#00ff00": "#007f00"}
						position={[0, 0.28, 0.1]}
						onClick={() => setStatus && setStatus(1)}
					/>
				</Select>
				<mesh
					geometry={nodes.Cylinder003.geometry}
					material={materials.black}
					position={[0, 0.28, 0.15]}
					rotation={[ 0, 0, -rotation[2] ]}
				/>
				<mesh
					geometry={nodes.Cylinder004.geometry}
					material={materials.black}
					position={[0, 0.75, 0.15]}
					rotation={[ 0, 0, -rotation[2] ]}
				/>
				<mesh
					geometry={nodes.Cylinder005.geometry}
					material={materials.black}
					position={[0, 1.23, 0.15]}
					rotation={[ 0, 0, -rotation[2] ]}
				/>
			</group>
		</group>
	);
}
import { useMemo, useState } from "react";
import styled from "styled-components";

import { stakeDonas, unstakeDonas } from "../utils";

import { useUserDonasFromIds } from "../Hooks";

import { Flex } from "./Styles";
import { Modal, ModalBody } from "./Modal";
import { StakeDonaMenu } from "./StakeDonaMenu";

const ModalFooter = styled(Flex).attrs(() => ({
	justify: "flex-end",
	align: "center"
}))`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	border: 5px solid black;
	background-color: white;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
`;

const FooterButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	width: 140px;
	height: 50px;
	padding: 15px;
	color: white;
	background-color: black;
	border-radius: 15px;
	cursor: pointer;
	margin-right: 15px;
	font-weight: 700;
	${({ disabled }) => disabled && `
		opacity: 0.5;
		cursor: auto;
	`}
`;

const RefreshButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	height: 50px;
	padding: 15px;
	background-color: white;
	border: 2px solid black;
	border-radius: 15px;
	margin-right: 15px;
	&:active {
		background-color: rgba(0,0,0,0.05);
	}
	${({ disabled }) => disabled
		? `
			opacity: 0.5;
			pointer-events: none;
		`
		: `
			cursor: pointer;
		`
	}
	& > img {
		width: 18px;
		height: 18px;
	}
`;

const Loading = styled.div`
	margin-right: 15px;
	opacity: 0.5;
`;

export function DonaModal({ account, canTransact, onClose, visible }) {
	const [ selected, setSelected ] = useState([]);

	const [ isTransacting, setIsTransacting ] = useState(false);

	const [ donas, isLoading, forceDonaReload ] = useUserDonasFromIds(account);

	const donaMenu = useMemo(() => (
		<StakeDonaMenu
			donas={donas}
			isLoading={isLoading}
			onSelect={setSelected}
			selectMultiple={true}
		/>
	), [ donas, isLoading ]);

	const stakingEnabled = !!selected.length && selected.every(({ staked = false }) => !staked);
	const unstakingEnabled = !!selected.length && selected.every(({ staked = false }) => staked);
	const stakeOrUnstakeSelected = async (staking = true) => {
		if (!(await canTransact())) return;
		setIsTransacting(true);
		const stakingFunction = staking ? stakeDonas: unstakeDonas;
		try {
			const tx = await stakingFunction(selected.map(({ token_id }) => token_id));
			await tx.wait();
			selected.forEach(dona => dona.staked = staking);
			setSelected([]);
			setTimeout(() => {
				setIsTransacting(false);
				forceDonaReload();
			}, 1000);
		}
		catch(e) {
			alert(`An error occurred while ${staking ? "": "un"}staking. Please reload the page and try again.`);
			console.error(e);
		}
	}

  return (
    <Modal
      style={{
				backgroundColor: "white",
				paddingBottom: "100px"
			}}
      isVisible={visible}
      onClose={() => !isTransacting && onClose()}
			title="VEHICLES"
    >
			<ModalBody>
				{donaMenu}
			</ModalBody>
			<ModalFooter>
				{isTransacting && <Loading>{stakingEnabled ? "Staking": "Unstaking"}...</Loading>}
				<FooterButton
					disabled={isTransacting || !stakingEnabled}
					onClick={() => !isTransacting && stakingEnabled && stakeOrUnstakeSelected(true)}
				>
					STAKE{stakingEnabled && !!selected.length ? ` ${selected.length}`: ""}
				</FooterButton>
				<FooterButton
					disabled={isTransacting || !unstakingEnabled}
					onClick={() => !isTransacting && unstakingEnabled && stakeOrUnstakeSelected(false)}
				>
					UNSTAKE{unstakingEnabled && !!selected.length ? ` ${selected.length}`: ""}
				</FooterButton>
				<RefreshButton
					title="Reload 'Donas"
					disabled={isLoading}
					onClick={() => !isTransacting && !isLoading && forceDonaReload()}
				>
					<img src="/refresh.svg" alt="R"/>
				</RefreshButton>
			</ModalFooter>
    </Modal>
  )
}
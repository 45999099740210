import { useEffect, useRef, useState, useCallback } from "react";

import { DEBUG, FINALIZING_BLOCKS, getRaceDataGraph, isRaceExpired, isRaceFinalized, isRaceValid, listenForContractEvent, listenForRaceUpdates, PENDING_BLOCKS, RACING_BLOCKS } from "../utils";

export function useRaceTracker(id, { onUpdate, onStart, onJoin, onFinish }) {
	const [ isValid, setIsValid ] = useState(true);

	const [ data, setData ] = useState({});

	const [ status, setStatus ] = useState(null);
	const statusRef = useRef(status);
	useEffect(() => {
		statusRef.current = status;
	}, [ status ]);

	const [ shouldUpdate, setShouldUpdate ] = useState(true);

	const [ isLoading, setIsLoading ] = useState(false);

	const checkIfFinalizedOrExpired = useCallback(async (raceId) => {
		const [ isFinalized, isExpired ] = await Promise.all([
			isRaceFinalized(raceId),
			isRaceExpired(raceId)
		]);
		if (isFinalized || isExpired) {
			if (isFinalized) setStatus("CLOSED");
			else if (isExpired) setStatus("EXPIRED");
			setShouldUpdate(false);
		}
	}, []);

	useEffect(() => {
		if (!id) {
			setIsValid(false);
			setData({});
			setStatus(null);
			return;
		}

		const getData = async () => {
			setIsLoading(true);

			try {
				const valid = await isRaceValid(id);
				if (!valid) return setIsValid(false);
	
				setIsValid(true);
				const { createTime, createBlock, startBlock, maxLevel, racers = [], racerResults = [], positionResults = [] } = await getRaceDataGraph(id);
				DEBUG && console.log(racerResults, positionResults);
				const results = racerResults.length 
					? racers.map(({ id: racerId }) => {
						const i = racerResults.findIndex(tokenId => tokenId === racerId);
						return { tokenId: racerResults[i], position: positionResults[i] };
					})
					: null;
				setData({
					createTime: Number(createTime) * 1000,
					createBlock,
					startBlock: startBlock || createBlock + PENDING_BLOCKS - 1,
					maxLevel,
					results
				});
				if (results) checkIfFinalizedOrExpired(id);
			}
			catch(e) {
				setIsValid(false);
				console.error(e);
			}
			finally {
				setIsLoading(false);
			}
		}
		getData();
	}, [ id, checkIfFinalizedOrExpired ]);

	useEffect(() => {
		if (!id || !data.startBlock || !shouldUpdate) {
			if (data.results && onUpdate) onUpdate(data.results, null);
			return;
		}

		const { startBlock } = data;
		const unsubscribe = listenForRaceUpdates(id, startBlock, async (racers, block) => {
			const convertedRacers = racers.map(({ position, racer }) => ({ position: position.toNumber(), tokenId: racer.toNumber() }));
			onUpdate && onUpdate(data.results || convertedRacers, block);
			DEBUG && console.log(data.results || convertedRacers);
			
			if (block - startBlock >= (RACING_BLOCKS + FINALIZING_BLOCKS) && racers.length > 1) {
				checkIfFinalizedOrExpired(id);
			}
			else if (block - startBlock >= RACING_BLOCKS && racers.length > 1) {
				if (statusRef.current !== "CLOSED") {
					const isFinalized = await isRaceFinalized(id);
					if (isFinalized) {
						setStatus("CLOSED");
						setShouldUpdate(false);
					}
					else if (statusRef.current !== "COMPLETE") {
						setStatus("COMPLETE");
						if (block - startBlock === RACING_BLOCKS) onFinish && onFinish();
					}
				}
			}
			else if (block - startBlock >= 0) {
				if (racers.length > 1) {
					if (statusRef.current !== "LIVE") {
						setStatus("LIVE");
						if (block === startBlock) onStart && onStart();
					}
				}
				else {
					setStatus("CANCELED");
					setShouldUpdate(false);
				}
			}
			else if (block < startBlock) {
				if (racers.length === 7 && statusRef.current !== "LIVE") {
					setStatus("LIVE");
					setData(d => ({ ...d, startBlock: block }));
					onStart && onStart();
				}
				else if (statusRef.current !== "OPEN") setStatus("OPEN");
			}
		});

		return unsubscribe;
	}, [ id, data, shouldUpdate, checkIfFinalizedOrExpired, onUpdate, onStart, onFinish ]);

	useEffect(() => {
		if (!onJoin) return;

		const unsubscribe = listenForContractEvent("RaceJoined", ({ raceId }) => (raceId === id && onJoin()));

		return unsubscribe;
	}, [ id, onJoin ]);

	return [ isValid, data, status, isLoading ];
}
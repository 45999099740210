import styled from "styled-components";

import { Flex } from "./Styles";

const Container = styled(Flex)`
  position: fixed;
	${({
		top = "0px", left = "0px",
		width, height,
		rotate = "0", 
		mode = "inherit", 
		letterSpacing = "inherit" 
	}) => `
		top: ${top};
		left: ${left};
		width: ${width};
		height: ${height};
		transform: rotateZ(${rotate}deg);
		writing-mode: ${mode};
		letter-spacing: ${letterSpacing}px;
	`}

  background-color: yellow;
  overflow: hidden;
  font-weight: bold;
  font-size: 16px;
  padding: 4px;
  line-height: 20px;
  z-index: 4;
	flex-wrap: nowrap;
`;

const NarcText = styled.span`
  color: #000;
  line-height: 22px;
	text-align: center;
	flex-shrink: 0;
	margin-left: 2px;
`;

const positions = {
	top: {
		width: "100vw",
		height: "28px",
	},
	left: {
		width: "28px",
		height: "100vh",
		rotate: 180,
		mode: "vertical-rl",
		letterSpacing: 0.4,
	},
	right: {
		left: "calc(100vw - 28px)",
		width: "28px",
		height: "100vh",
		mode: "vertical-rl",
		letterSpacing: 0.4,
	},
	bottom: {
		top: "calc(100vh - 28px)",
		width: "100vw",
		height: "28px",
		rotate: 180,
	}
};

export function CautionTape({ position = "left" }) {

  return (
    <Container {...positions[ position ]}>
      <NarcText>CAUTION ! DON'T BE A DWEEBY NARC !</NarcText>
      <NarcText>CAUTION ! DON'T BE A NARC DWEEB !</NarcText>
      <NarcText>CAUTION ! DON'T BE A DWEEBY NARC !</NarcText>
      <NarcText>CAUTION ! DON'T BE A NARC DWEEB !</NarcText>
      <NarcText>CAUTION ! DON'T BE A DWEEBY NARC !</NarcText>
      <NarcText>CAUTION ! DON'T BE A NARC DWEEB !</NarcText>
      <NarcText>CAUTION ! DON'T BE A DWEEBY NARC !</NarcText>
      <NarcText>CAUTION ! DON'T BE A NARC DWEEB !</NarcText>
      <NarcText>CAUTION ! DON'T BE A DWEEBY NARC !</NarcText>
      <NarcText>CAUTION ! DON'T BE A NARC DWEEB !</NarcText>
    </Container>
  );
}

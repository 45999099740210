import { useEffect, useMemo, useRef } from "react";
import { RepeatWrapping, SphereBufferGeometry } from "three";
import { Circle, Cylinder, Instance, Instances, MeshDistortMaterial, Ring, Sphere, Torus, useTexture } from "@react-three/drei"
import { Select } from "@react-three/postprocessing";

import { prefix3D } from "../../utils";

import { useGLTFAnimation } from "../useGLTFAnimation";
import { sharedMaterials } from "../../shaders/materials";

function Bomba() {
	return (
		<group>
			<Ring
				args={[ 24, 34, 32 ]}
				position={[ -3, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, 0 ]}
				material={sharedMaterials.standard.white}
			/>
			<Ring
				args={[ 24, 34, 32 ]}
				position={[ 188, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}
				material={sharedMaterials.standard.white}
			/>
		</group>
	)
}

function Eyes() {
	const eyeMap = useTexture(prefix3D + "eyes.jpg");
	if (eyeMap) {
		eyeMap.center.set(0.5, 0.5);
		eyeMap.repeat.set(4, 2);
	}

	const eyeMat = (
		<meshBasicMaterial
			attach="material"
			map={eyeMap}
		/>
	);

	return (
		<group>
			<Sphere
				args={[ 36 ]}
				position={[ 15, 0, 0 ]}
				rotation={[ 0, Math.PI, 0 ]}>
				{eyeMat}
			</Sphere>
			<Sphere
				args={[ 36 ]}
				position={[ 170, 0, 0 ]}
				rotation={[ 0, Math.PI, 0 ]}
				scale={[ -1, 1, 1 ]}>
				{eyeMat}
			</Sphere>
		</group>
	);
}

function FruitLoops({ side = "front" }) {
	const [ dist1, dist2 ] = useMemo(() => ([
		0.1875 + 0.1 * Math.random(),
		0.1875 + 0.1 * Math.random()
	]), []);

	const colors = side === "front"
		? [ "red", "green" ]
		: [ "yellow", "purple" ]

	return (
		<group>
			<Torus
				args={[ 25, 12, 16, 64 ]}
				position={[ 5, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}>
				<MeshDistortMaterial
					color={colors[0]}
					speed={0}
					distort={dist1}
					radius={1}
				/>
			</Torus>
			<Torus
				args={[ 25, 12, 16, 64 ]}
				position={[ 180, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}>
				<MeshDistortMaterial
					color={colors[1]}
					speed={0}
					distort={dist2}
					radius={1}
				/>
			</Torus>
		</group>
	)
}

function Happy() {
	const happyMap = useTexture(prefix3D + "happy.jpg");
	if (happyMap) {
		happyMap.center.set(0.5, 0.5);
		happyMap.repeat.set(0.75, 0.75);
		happyMap.offset.set(-0.075, -0.025);
	}

	const happyMat = (
		<meshBasicMaterial
			attach="material"
			map={happyMap}
		/>
	);

	return (
		<group>
			<Circle
				args={[ 24, 32 ]}
				position={[ -1, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, 0 ]}>
				{happyMat}
			</Circle>
			<Circle
				args={[ 24, 32 ]}
				position={[ 186, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}>
				{happyMat}
			</Circle>
		</group>
	)
}

function RunningBlockDude({ speed, ...props }) {
	const group = useRef();
	const anim = useRef();

	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "blockdude_run_compressed.glb",
		groupRef: group,
		animRef: anim
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.reset().play();
		animation.weight = 1;
		animation.timeScale = 4;
		return () => {
			animation.fadeOut(2).halt(2);
		}
	}, [ speed ]);

  return (
		<group
			ref={group}
			{...props}
			dispose={null}>
			<group name="Scene" scale={100}>
				<group name="Armature">
					<primitive object={nodes.Bone} />
					<skinnedMesh
						name="mrletsgo"
						geometry={nodes.mrletsgo.geometry}
						material={materials.initialShadingGroup}
						skeleton={nodes.mrletsgo.skeleton}
					/>
				</group>
			</group>
		</group>
  );
}
function HoldMe({ speed }) {
	return (
		<group position={[ 0, -36, 8 ]}>
			<RunningBlockDude
				speed={speed}
				position={[ -17, 0, 0 ]}
				scale={[ -1, 1, 1 ]}
			/>
			<RunningBlockDude
				speed={speed}
				position={[ 202, 0, 0 ]}
			/>
		</group>
	)
}

function Levitate() {
	return (
		<group position={[ 0, 5, 0 ]}>
			<group position={[ -5, 0, 0 ]}>
				<Torus
					args={[ 24, 5, 8, 8, Math.PI / 2 ]}
					position={[ 30, 10, 0 ]}
					rotation={[ 0, Math.PI, 0 ]}
					material={sharedMaterials.standard.black20}
				/>
				<Cylinder
					args={[ 34, 34, 20, 32 ]}
					material={sharedMaterials.standard.black20}
				/>
				<Select enabled={true}>
					<Cylinder
						args={[ 8, 1, 12, 64, 16 ]}
						position={[ 0, -18, 0 ]}>
						<MeshDistortMaterial
							color="#ffa500"
							speed={20}
							distort={0.75 + 0.25 * Math.random()}
							radius={1.5}
						/>
					</Cylinder>
					<Cylinder
						args={[ 12, 1, 18, 64, 16 ]}
						position={[ 0, -24, 0 ]}>
						<MeshDistortMaterial
							color="#ffa500"
							speed={20}
							distort={0.75 + 0.25 * Math.random()}
							radius={1.8}
							opacity={0.25}
							transparent={true}
						/>
					</Cylinder>
				</Select>
			</group>
			

			<group position={[ 190, 0, 0 ]}>
				<Torus
					args={[ 24, 5, 8, 8, Math.PI / 2 ]}
					position={[ -30, 10, 0 ]}
					material={sharedMaterials.standard.black20}
				/>
				<Cylinder
					args={[ 34, 34, 20, 32 ]}
					material={sharedMaterials.standard.black20}
				/>
				<Select enabled={true}>
					<Cylinder
						args={[ 8, 1, 12, 64, 16 ]}
						position={[ 0, -18, 0 ]}>
						<MeshDistortMaterial
							color="#ffa500"
							speed={20}
							distort={0.75 + 0.25 * Math.random()}
							radius={1.5}
						/>
					</Cylinder>
					<Cylinder
						args={[ 12, 1, 18, 64, 16 ]}
						position={[ 0, -24, 0 ]}>
						<MeshDistortMaterial
							color="#ffa500"
							speed={20}
							distort={0.75 + 0.25 * Math.random()}
							radius={1.8}
							opacity={0.25}
							transparent={true}
						/>
					</Cylinder>
				</Select>
			</group>
		</group>
	)
}

function Logs() {
	const [ logMap, stumpMap ] = useTexture([
		prefix3D + "logs.jpeg",
		prefix3D + "stump.jpg"
	]);
	if (logMap) {
		logMap.wrapS = logMap.wrapT = RepeatWrapping;
		logMap.repeat.set(2, 1);
	}

	const logMat = (
		<meshBasicMaterial
			attach="material"
			map={logMap}
		/>
	);
	const stumpMat = (
		<meshBasicMaterial
			attach="material"
			map={stumpMap}
		/>
	)

	return (<>
		<Circle
			args={[ 36, 32 ]}
			position={[ -12.5, 0, 0 ]}
			rotation={[ 0, -Math.PI / 2, 0 ]}>
			{stumpMat}
		</Circle>
		<Cylinder
			args={[ 36, 36, 210, 32, 1, true ]}
			position={[ 92.5, 0, 0 ]}
			rotation={[ 0, 0, Math.PI / 2 ]}>
			{logMat}
		</Cylinder>
		<Circle
			args={[ 36, 32 ]}
			position={[ 197.5, 0, 0 ]}
			rotation={[ 0, Math.PI / 2, 0 ]}>
			{stumpMat}
		</Circle>
	</>)
}

function Sad({ side = "front" }) {
	const sadMap = useTexture(prefix3D + "sad.jpg");

	const sadMat = (
		<meshBasicMaterial
			attach="material"
			map={sadMap}
		/>
	);

	const invert = side !== "front";

	return (
		<group>
			<Circle
				args={[ 24, 32 ]}
				position={[ -1, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, invert ? Math.PI: 0 ]}>
				{sadMat}
			</Circle>
			<Circle
				args={[ 24, 32 ]}
				position={[ 186, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, invert ? Math.PI: 0 ]}>
				{sadMat}
			</Circle>
		</group>
	)
}

const sphereGeo = new SphereBufferGeometry(1.5);
const steelPos = Array.from({ length: 12 }, (_, i) => ([
	0,
	18 * Math.cos(i * Math.PI / 6),
	18 * Math.sin(i * Math.PI / 6)
]));
function Steelies() {
	return (
		<group>
			<Instances
				geometry={sphereGeo}
				material={sharedMaterials.standard.steel}
				limit={24}>
				<group position={[ -2.5, 0, 0 ]}>
					{steelPos.map((pos, i) => (
						<Instance
							key={i}
							position={pos}
						/>
					))}
				</group>
				<group position={[ 187.5, 0, 0 ]}>
					{steelPos.map((pos, i) => (
						<Instance
							key={i}
							position={pos}
						/>
					))}
				</group>
			</Instances>
		</group>
	)
}

export const wheels = {
	"Bomba": {
		model: Bomba,
		rimMat: sharedMaterials.standard.gold,
		retainRims: true,
		retainTires: true
	},
	"Eyes": {
		model: Eyes
	},
	"Fruit Loops": {
		model: FruitLoops
	},
	"Ghost": {
		rimMat: sharedMaterials.standard.black20,
		tireMat: sharedMaterials.standard.white
	},
	"Happy": {
		model: Happy,
		retainTires: true
	},
	"Hold Me": {
		model: HoldMe,
		spins: false
	},
	"Levitate": {
		model: Levitate,
		spins: false,
		moveDona: ({ elapsed, dona, startPos }) => {
			dona.position.y = startPos[1] + 0.1 * (1.5 + Math.sin(2 * elapsed));
		}
	},
	"Logs": {
		model: Logs
	},
	"Off Road": {
		rimMat: sharedMaterials.standard.black20
	},
	"Premium": undefined,
	"Sad": {
		model: Sad,
		retainTires: true
	},
	"Steelies": {
		model: Steelies,
		rimMat: sharedMaterials.standard.black20,
		retainRims: true,
		retainTires: true
	}
}

export function useWheels(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "wheels");
	if (!trait) return {};

	const { value } = trait;
	if (!wheels[ value ]) return {};

	return wheels[ value ];
}
import { useEffect, useState } from "react";
import styled from "styled-components";

import { jpamLink } from "../links";

import { Flex } from "./Styles";
import { ModalText, ModalTitle } from "./Modal";
import { SelectNFTCarousel } from "./SelectNFTCarousel";

const Container = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
`;

const Title = styled(ModalTitle)`
	margin-bottom: 20px;
`;

const CarouselContainer = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
	margin: 20px 0;
`;

const Loading = styled.div`
	margin: 20px 0;
`;

const EmptyGarageMessage = styled.div`
	margin: 20px 0;
	& > a {
		font-weight: 700;
	}
	& > a:visited {
		color: inherit;
	}
`;

// Probably best to cache this component in parent to limit API calls
export function StakeDonaMenu({ title = null, donas, isLoading, selectMultiple, onSelect }) {
	const stakedNFTs = donas.filter(({ staked = false }) => staked);
	const unstakedNFTs = donas.filter(({ staked = false }) => !staked);

	const [ stakedSelected, setStakedSelected ] = useState(selectMultiple ? []: null);
	const [ unstakedSelected, setUnstakedSelected ] = useState(selectMultiple ? []: null);

	useEffect(() => {
		if (!selectMultiple) {
			onSelect(stakedSelected || unstakedSelected);
			return;
		}

		onSelect(stakedSelected.length ? stakedSelected: unstakedSelected);
	}, [ selectMultiple, onSelect, stakedSelected, unstakedSelected ]);

	const isGarageEmpty = !isLoading && !donas.length;

	return (
		<Container>
			{title && <Title>{title}</Title>}
			<ModalText>
				<strong>ONLY STAKED VEHICLES CAN BE RACED.</strong>
				VEHICLES CAN BE STAKED/UNSTAKED AT ANY POINT, AS LONG AS THEY ARE NOT ACTIVELY IN A RACE.
				WHILE A VEHICLE IS STAKED, OWNERSHIP CANNOT BE TRANSFERRED.
			</ModalText>
			{isLoading && <Loading>Loading...</Loading>}
			{isGarageEmpty && (
				<EmptyGarageMessage>
					Your garage is empty! Maybe try a different address or <a href={jpamLink}>buy/mint some Donas</a>
				</EmptyGarageMessage>
			)}
			{!!stakedNFTs.length && (
				<CarouselContainer>
					<Title>STAKED VEHICLES:</Title>
					<SelectNFTCarousel
						nfts={stakedNFTs}
						selected={stakedSelected}
						onSelect={nft => {
							if (!selectMultiple) {
								setUnstakedSelected(null);
								setStakedSelected(nft);
								return;
							}
							setUnstakedSelected([]);
							setStakedSelected(s => ([ ...s, nft ]));
						}}
						onDeselect={nft => {
							if (!selectMultiple) return setStakedSelected(null);

							setStakedSelected(s => {
								s.splice(s.indexOf(nft), 1);
								return [ ...s ];
							});
						}}
					/>
				</CarouselContainer>
			)}
			{!!unstakedNFTs.length && (
				<CarouselContainer>
					<Title>UNSTAKED VEHICLES:</Title>
					<SelectNFTCarousel
						nfts={unstakedNFTs}
						selected={unstakedSelected}
						onSelect={nft => {
							if (!selectMultiple) {
								setStakedSelected(null);
								setUnstakedSelected(nft);
								return;
							}
							setStakedSelected([]);
							setUnstakedSelected(s => ([ ...s, nft ]));
						}}
						onDeselect={nft => {
							if (!selectMultiple) return setUnstakedSelected(null);

							setUnstakedSelected(s => {
								s.splice(s.indexOf(nft), 1);
								return [ ...s ];
							});
						}}
					/>
				</CarouselContainer>
			)}
		</Container>
	)
}
import { Vector2 } from "three";

export const createKuwaharaShader = ({
	resolution = [ 1024, 1024 ],
	map = null,
	radius = 10,
	csm = false
}) => {
	const colorOutput = csm ? "csm_DiffuseColor": "gl_FragColor";

	return {
		uniforms: {
			resolution: { value: new Vector2(...resolution) },
			uSampler: { value: map },
			RADIUS: { value: radius }
		},
		vertexShader: `
		varying vec2 vUv;
		
		void main() {
			vUv = uv;
			${csm
				? "csm_Position = position;"
				: "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);"
			}
		}
		`,
		fragmentShader: `
		varying vec2 vUv;
		uniform sampler2D uSampler;
		uniform vec2 resolution;
		uniform int RADIUS;
		// #define RADIUS 3
		void main (void) {
			// Bildkoordinaten des aktuellen Pixels -> (u,v) von 0 .. 1
			vec2 src_size = vec2(resolution.x, resolution.y);
			vec2 uv = vUv;
			// Anzahl der Pixel einer Region
			float n = float((RADIUS + 1) * (RADIUS + 1));
			// Summen und Summenquadrate der Regionen (in Burger: (17.4 S_1,k, 17.5 S_2,k)
			// Zu beachten: Jeweils Vektoren mit 3 Elementen für die einzelnen Farbkanäle RGB
			vec3 m[4];
			vec3 s[4];
			for (int k = 0; k < 4; ++k) {
				m[k] = vec3(0.0);
				s[k] = vec3(0.0);
			}
			for (int t = -RADIUS; t <= 0; ++t) {
				for (int i = -RADIUS; i <= 0; ++i) {
					vec3 c = texture2D(uSampler, uv + vec2(i,t) / src_size).rgb;
					m[0] += c;
					s[0] += c * c;
				}
			}
			for (int j = -RADIUS; j <= 0; ++j) {
				for (int i = 0; i <= RADIUS; ++i) {
					vec3 c = texture2D(uSampler, uv + vec2(i,j) / src_size).rgb;
					m[1] += c;
					s[1] += c * c;
				}
			}
			for (int j = 0; j <= RADIUS; ++j) {
				for (int i = 0; i <= RADIUS; ++i) {
					vec3 c = texture2D(uSampler, uv + vec2(i,j) / src_size).rgb;
					m[2] += c;
					s[2] += c * c;
				}
			}
			for (int j = 0; j <= RADIUS; ++j) {
				for (int i = -RADIUS; i <= 0; ++i) {
					vec3 c = texture2D(uSampler, uv + vec2(i,j) / src_size).rgb;
					m[3] += c;
					s[3] += c * c;
				}
			}
			// Region mit der kleinsten Varianz finden und deren Mittelwert als neuen Pixelwert nutzen
			float min_sigma2 = 100.0;
			for (int k = 0; k < 4; ++k) {
				// Tatsächliche Mittelwerte und Varianzen der Regionen berechnen
				// Analog zu (17.3) in Burger
				m[k] /= n;
				s[k] = abs(s[k] / n - m[k] * m[k]);
				// "Totale Varianz"
				float sigma2 = s[k].r + s[k].g + s[k].b;
				if (sigma2 < min_sigma2) {
					min_sigma2 = sigma2;
					// Neuen Pixelwert setzen
					${colorOutput} = vec4(m[k], 1.0);
				}
			}
		}`
	}
}
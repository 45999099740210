import { useEffect, useState } from "react";
import styled from "styled-components";

import { useDonaContext } from "../../providers";
import { useWallet } from "../../Hooks";

import { BorderButton, Flex } from "../Styles";
import { WrapperWithMenu } from "../WrapperWithMenu";
import { RaceScene } from "./RaceScene";
import { JoinRaceModal } from "./JoinRaceModal";
import { RaceBlock } from "./RaceBlock";

const Container3D = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
  position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
`;

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
  position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	padding: 100px;
	overflow-y: auto;
	/* ${({ minimized }) => minimized && `pointer-events: none;`} */
	pointer-events: none;
	z-index: 3;
`;

const MuteButton = styled(BorderButton)`
	margin-left: 24px;
	padding: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover > img {
		filter: brightness(0) grayscale(100%) !important;
	}
`;
const SoundIcon = styled.img`
	width: 21px;
	height: 21px;
	filter: invert() brightness(100) grayscale(100%);
	${({ hidden }) => hidden && `display: none;`}
`;

export function RacePage3D() {
	const id = 1;
	const { donaMetadata } = useDonaContext()

  const { account, connect, canTransact } = useWallet();

	const [ isMuted, setIsMuted ] = useState(true);

	const [ raceBlockMinimized, setRaceBlockMinimized ] = useState(true);

	const [ joinRaceModalActive, setJoinRaceModalActive ] = useState(false);

	const [ raceStatus, setRaceStatus ] = useState(-1);

	const [ raceIds ] = useState(() => {
		const search = new URLSearchParams(window.location.search);
		const searchIds = search.get("token_ids") || search.get("token_id");
		const arr = searchIds ? searchIds.split(",").map(num => parseInt(num)): [];
		let flipFlag = false;
		while(arr.length < 7) {
			const randId = Math.ceil(Math.random() * 6000);
			if (flipFlag) arr.push(randId);
			else arr.unshift(randId);
			flipFlag = !flipFlag;
		}
		return arr;
	});

	const [ racers, setRacers ] = useState(raceIds.map(id => ({
		id,
		token_id: id,
		vin: "",
		image_url: undefined,
		traits: undefined,
		distance: 0
	})));

	useEffect(() => {
		if (!donaMetadata) return;

		setRacers(r => r.map(({ id, ...rest }) => {
			const { metadata, images } = donaMetadata[ id ] || {}
			return {
				...rest,
				id,
				vin: metadata?.name?.split("VIN")[1] || "",
				traits: metadata?.attributes || [],
				image_url: images?.[ "512" ]
			}
		}))
	}, [ raceIds, donaMetadata ]);

	useEffect(() => {
		if (raceStatus === -1) {
			setRacers(r => r.map(racer => ({ ...racer, distance: 0 })));
			return;
		}
		if (raceStatus === 0) return;

		const progressRace = () => {
			setRacers(r => r.map(({ distance, ...rest }) => ({
				...rest,
				distance: distance + Math.ceil(10 * Math.random())
			})));
		};
		progressRace();
		const i = setInterval(progressRace, 6000);

		return () => clearInterval(i);
	}, [ raceStatus ]);

  return (
    <WrapperWithMenu
			account={account}
			connectWallet={connect}
			allowed={true}
			canTransact={canTransact}
			menuItems={
				<MuteButton onClick={() => setIsMuted(m => !m)}>
					<SoundIcon
						src="/sound.png"
						hidden={isMuted}
					/>
					<SoundIcon
						src="/no-sound.png"
						hidden={!isMuted}
					/>
				</MuteButton>
			}>
      <Container3D>
				<RaceScene
					racers={racers}
					raceStatus={raceStatus}
					setRaceStatus={setRaceStatus}
				/>
      </Container3D>
			<Container minimized={raceBlockMinimized}>
				<RaceBlock
					raceId={id}
					canTransact={canTransact}
					openJoinMenu={async () => (await canTransact() && setJoinRaceModalActive(true))}
					minimized={raceBlockMinimized}
					setMinimized={setRaceBlockMinimized}
					racers={racers}
					raceStatus={
						raceStatus === -1
							? "OPEN"
							: raceStatus === 0
								? "CANCELED"
								: "LIVE"
					}
				/>
			</Container>
			<JoinRaceModal
				account={account}
				canTransact={canTransact}
				onClose={() => setJoinRaceModalActive(false)}
				visible={joinRaceModalActive}
				raceId={id}
			/>
    </WrapperWithMenu>
  );
}

import styled from "styled-components";

import { Flex } from "../../Styles";

const Container = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
  position: relative;
  width: 100px;
  height: 100px;
  border: 3px solid white;
	${({ selected, invert }) => selected
		? `
			background-color: white;
			& > img {
				filter: ${invert ? "invert()": "none"};
			}
		`
		: `background-color: black;`
	}
`;

const NumberDisplay = styled.div`
  position: absolute;
  color: ${({ color }) => color};
  font-weight: 600;
  top: 10px;
  left: 10px;
`;

const TextDisplay = styled.div`
	color: ${({ color }) => color};
	font-size: 40px;
	font-weight: 700;
`;
const ImageDisplay = styled.img`
	width: ${({ width }) => width}px;
`;

export function ConfigSquare({ num = 0, imageURL, imageWidth = 100, textContent, selected, invert = true }) {
	const contentColor = selected ? "black" : "white";
  return (
    <Container
			selected={selected}
			invert={invert}
		>
      <NumberDisplay color={contentColor}>{num}</NumberDisplay>
      {imageURL && (
				<ImageDisplay
					width={imageWidth}
					src={imageURL}
				/>
			)}
			{textContent && <TextDisplay color={contentColor}>{textContent}</TextDisplay>}
    </Container>
  );
}


export function ShadowPlane({ size = [ 100, 100 ], opacity = 0.4, ...props }) {
	return (
		<group {...props}>
			<mesh
				rotation={[ -Math.PI / 2, 0, 0 ]}
				receiveShadow>
				<planeBufferGeometry attach="geometry" args={size}/>
				<shadowMaterial attach="material" transparent opacity={opacity}/>
			</mesh>
		</group>
	)
}
import axios from "axios";
import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import boneyardABI from './abis/boneyardABI.json';
import donaNFTABI from './abis/donaNFT.json';

export const DEBUG = false;

const boneyardGraphBaseURL = "https://api.studio.thegraph.com/query/10763/dona-boneyard-main/v0.0.4";

export const prefix3D = process.env.PUBLIC_URL + "models/";

// RINKEBY
export const CHAINID = 1;
export const donaNFTContractAddress = '0xf210d5d9dcf958803c286a6f8e278e4ac78e136e';
export const boneyardContractAddress = '0x7C8ec29d00902aBb6Cc29d220d3eC9d3409ab0Be';
//

export const PENDING_BLOCKS = 600;
export const pendingTime = PENDING_BLOCKS * 15 * 1000; // (blocks * s/block * ms/s = ms)
export const RACING_BLOCKS = 60;
export const racingTime = RACING_BLOCKS * 15 * 1000;
export const FINALIZING_BLOCKS = 255 - RACING_BLOCKS + 1;

export const defaultProvider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/eth", CHAINID);

const blockNativeAPIKey = "0822d008-0624-4b0f-a5d5-24aac01cdd72";

const defaultDonaNFTContract = new ethers.Contract(donaNFTContractAddress, donaNFTABI, defaultProvider);
const defaultBoneyardContract = new ethers.Contract(boneyardContractAddress, boneyardABI, defaultProvider);

export const contractInteraction = {
	provider: null,
	signer: null,
	boneyardContract: null,
	donaContract: null
}

export const onboard = Onboard({
	dappId: blockNativeAPIKey, // [String] The API key created by step one above
	networkId: CHAINID, // [Integer] The Ethereum network ID your Dapp uses.
	subscriptions: {
		wallet: ({ provider: walletProvider }) => {
			const provider = contractInteraction.provider = new ethers.providers.Web3Provider(walletProvider);
			const signer = contractInteraction.signer = provider.getSigner();
			contractInteraction.boneyardContract = new ethers.Contract(boneyardContractAddress, boneyardABI, signer);
			contractInteraction.donaContract = new ethers.Contract(donaNFTContractAddress, donaNFTABI, signer);
		}
	}
});

// unsigned
export async function isBoneyardApprovedByAccount(address = window.ethereum.selectedAddress) {
	return await defaultDonaNFTContract.isApprovedForAll(address, boneyardContractAddress);
}

// signed
export async function approveBoneyardContract() {
	const { donaContract } = contractInteraction;

	return await donaContract.setApprovalForAll(boneyardContractAddress, true);
}

// unsigned
export async function getCurrentBlockNumber() {
	return await defaultProvider.getBlockNumber();
}

// unsigned
export function listenForBlock(handler) {
	defaultProvider.on("block", handler);

	let shouldListen = true;
	getCurrentBlockNumber()
		.then(blockNumber => {
			if (!shouldListen) return;
			handler(blockNumber);
			defaultProvider.on("block", handler);
		});

	return () => {
		shouldListen = false;
		defaultProvider.off("block", handler);
	}
}

// unsigned
export async function getUserDonasIds(address = window.ethereum.selectedAddress) {
	const balance = (await defaultDonaNFTContract.balanceOf(address)).toNumber();

	const tokenIds = [];
	for (let i = 0; i < balance; i++) {
		const tokenId = await defaultDonaNFTContract.tokenOfOwnerByIndex(address, i);
		tokenIds.push(tokenId.toNumber());
	}

	return tokenIds;
}

// unsigned
export async function getDonaLevel(tokenId) {
	return (await defaultBoneyardContract.levelOf(tokenId)).toNumber();
}
export async function getDonaLevels(tokenIds) {
	const levels = {};
	for (const id of tokenIds) {
		const level = await getDonaLevel(id);
		levels[ id ] = level;
	}

	return levels;
}

// unsigned
export async function getDonaXP(tokenId) {
	return (await defaultBoneyardContract.xpOf(tokenId)).toNumber();
}
export async function getDonaXPs(tokenIds) {
	const xps = {};
	for (const id of tokenIds) {
		const xp = await getDonaXP(id);
		xps[ id ] = xp;
	}

	return xps;
}

// signed
export async function stakeDona(tokenId) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.joinBoneyard(tokenId);
}
export async function stakeDonas(tokenIds) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.joinBoneyardMulti(tokenIds);
}

// thegraph
export async function isStaked(tokenId) {
	const result = await axios.post(boneyardGraphBaseURL, {
		query: `
		{
			parkedCar(id:"${tokenId}") {
				id
				owner
				updateTime
				parked
			}		
		}
		`
	});

	return result.data.data.parkedCars;
}

export async function getStakedDonasByOwner(address = window.ethereum.selectedAddress) {
	const result = await axios.post(boneyardGraphBaseURL, {
		query: `
		{
			parkedCars(first: 50, where: {owner:"${address.toLowerCase()}"}) {
				id
				owner
				updateTime
				parked
			}
		}
		`
	});

	return result.data.data.parkedCars;
}

// signed
export async function unstakeDona(tokenId) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.leaveBoneyard(tokenId);
}
export async function unstakeDonas(tokenIds) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.leaveBoneyardMulti(tokenIds);
}

// unsigned
export async function isRaceValid(raceId) {
	return await defaultBoneyardContract.isRaceValid(raceId);
}

// signed
export async function createRace(tokenId, maxLevel) {
	const { boneyardContract } = contractInteraction;

	// raceId
	return await boneyardContract.createRace(tokenId, maxLevel);
}

// unsigned
export async function getRaceData(raceId) {
	// Race: id, startBlock, maxLevel, version, status
	// Racers: (tokenId, position)[] ???
	return await defaultBoneyardContract.raceData(raceId);
}

// graph
export async function getRaceDataGraph(raceId) {
	const result = await axios.post(boneyardGraphBaseURL, {
		query: `
		{
			race(id:"${raceId}") {
				id
				creator
				createTime
				createBlock
				startBlock
				maxLevel
				racers {
					id
				}
				racerResults
				positionResults
				winner {
					id
				}
			}
		}
		`
	});
	DEBUG && console.log(result);
	return result.data.data.race;
}

// graph
const raceTypeFilters = {
	open: block => `where: { startBlock_gt: ${block} }`,
	live: block => `where: { startBlock_lte: ${block}, startBlock_gt: ${block - RACING_BLOCKS} }`
}
export async function getRacesOfType(type, blockNumber) {
	if (!raceTypeFilters[ type ]) return undefined;
	const currentBlock = blockNumber || await getCurrentBlockNumber();
	
	const result = await axios.post(boneyardGraphBaseURL, {
		query: `
		{
			races(first:20, ${raceTypeFilters[ type ](currentBlock)}) {
				id
				createBlock
				startBlock
				maxLevel
				winner
				racers {
					id
				}
			}
		}
		`
	});
	DEBUG && console.log(result, currentBlock);
	return result?.data?.data?.races || [];
}

export async function getMyRaces(address) {
	const result = await axios.post(boneyardGraphBaseURL, {
		query: `
		{
			parkedCars(where: { owner: "${address.toLowerCase()}" }) {
				enteredRaces {
					id
					creator
					createTime
					createBlock
					startBlock
					maxLevel
					winner {
						id
						owner
					}
					racers {
						id
						owner
					}
					racerResults
					positionResults
				}
			}
		}
		`
	});

	const races = result.data.data.parkedCars
		.reduce((raceArr, { enteredRaces = [] }) => ([
			...raceArr,
			...enteredRaces.filter(({ id }) => !raceArr.find(({ id: existingId }) => id === existingId))
		]), []);
	DEBUG && console.log(races);
	return races;
}

// unsigned
export async function getRacePositions(raceId, blocks) {
	// racer (tokenId), position (0 -> 1 ???)
	return await defaultBoneyardContract.calcRacePositions(raceId, clamp(blocks, 1, RACING_BLOCKS));
}

// unsigned
export function listenForRaceUpdates(raceId, startBlock, onUpdate) {
	const onBlock = async blockNumber => {
		// TODO: maybe don't ping getRacePositions if the race isn't live yet (blocksRacing < 0)
		// instead, add RaceJoined event handler in RacePage
		const blocksRacing = blockNumber - startBlock;
		const racers = await getRacePositions(raceId, blocksRacing);
		onUpdate(racers, blockNumber);
	}

	// immediately return current positions based on current block
	let shouldListen = true;
	getCurrentBlockNumber()
		.then(blockNumber => {
			if (!shouldListen) return;
			onBlock(blockNumber);
			defaultProvider.on("block", onBlock);
		});

	// return unsubscribe function for cleanup
	return () => {
		defaultProvider.off("block", onBlock);
		shouldListen = false;
	}
}

// signed
export async function joinRace(raceId, tokenId) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.joinRace(raceId, tokenId);
}

// unsigned
export async function isRacing(tokenId) {
	return await defaultBoneyardContract.isParticipating(tokenId);
}
export async function areRacing(tokenIds) {
	const states = {};
	for (const id of tokenIds) {
		const state = await isRacing(id);
		states[ id ] = state;
	}

	return states;
}

// signed
export async function finalizeRace(raceId) {
	const { boneyardContract } = contractInteraction;

	return await boneyardContract.finalizeRace(raceId);
}

// unsigned
export function listenForContractEvent(event, handler) {
	if (!handler) return null;

	let eventName, handlerWrapper;
	// listen for specified event and return unsubscribe function
	switch(event) {
		case "DonaStaked":
		case "BoneyardJoined":
			eventName = "BoneyardJoined";
			handlerWrapper = (owner, tokenId) => handler({
				owner, 
				tokenId: tokenId.toString()
			});
			break;
		case "DonaUnstaked":
		case "BoneyardLeft":
			eventName = "BoneyardLeft";
			handlerWrapper = (owner, tokenId) => handler({
				owner,
				tokenId: tokenId.toString()
			});
			break;
		case "RaceCreated":
			eventName = "RaceCreated";
			handlerWrapper = (raceId, host, hostTokenId, createBlock, maxLevel) => handler({
				raceId: raceId.toString(),
				host,
				hostTokenId: hostTokenId.toString(),
				createBlock: createBlock.toNumber(),
				startBlock: createBlock.toNumber() + PENDING_BLOCKS - 1,
				maxLevel: maxLevel.toNumber()
			});
			break;
		case "RaceJoined":
			eventName = "RaceJoined";
			handlerWrapper = (raceId, owner, tokenId) => handler({
				raceId: raceId.toString(),
				owner,
				tokenId: tokenId.toString()
			})
			break;
		case "RaceFinalized":
			eventName = "RaceFinalized";
			handlerWrapper = (raceId, winnerTokenId) => handler({
				raceId: raceId.toString(),
				winner: winnerTokenId.toString()
			});
			break;
		default:
			throw new Error(`Invalid event: ${event}`);
	}

	defaultBoneyardContract.on(eventName, handlerWrapper);
	return () => defaultBoneyardContract.off(eventName, handlerWrapper);
}

// unsigned
export async function isRaceFinalizable(raceId) {
	return await defaultBoneyardContract.isRaceFinalizable(raceId);
}

// unsigned
export async function isRaceFinalized(raceId) {
	return await defaultBoneyardContract.isRaceFinalized(raceId);
}

// unsigned
export async function isRaceExpired(raceId) {
	return await defaultBoneyardContract.isRaceExpired(raceId);
}

// unsigned
export async function getLotData(lotIndex) {
  return await defaultDonaNFTContract.lotData(lotIndex);
}

export async function getVIN(id) {
  const lotId = Math.floor(id / 500);
  const lotMin = lotId * 500;
  const lotMax = lotMin + 500 - 1;
  const [ randomness, uri ] = await getLotData(lotId);
  let vin = ethers.BigNumber.from(id).add(randomness.mod(500)).toNumber();
  if (vin > lotMax) vin -= 500;

  return { uri, vin };
}

export async function reverseVIN(vin) {
  const lotId = Math.floor(vin / 500);
  const lotMin = lotId * 500;
  const [ randomness, uri ] = await getLotData(lotId);

  let tokenId = vin - randomness.mod(500).toNumber();
  if (tokenId < lotMin) tokenId += 500;
  return { uri, tokenId };
}

export async function getOpenSeaAssets(ids = []) {
	if (ids.length > 30) return await getUnlimitedOpenSeaAssets(ids);
	const idStr = ids.map(id => `token_ids=${id}`).join("&");
	const result = await axios.get(
		`https://api.opensea.io/api/v1/assets?order_direction=desc&asset_contract_address=0xF210D5d9DCF958803C286A6f8E278e4aC78e136E&${idStr}&limit=${ids.length}`
	);
	return result.data.assets;
}
export async function getUnlimitedOpenSeaAssets(ids = []) {
	const chunks = [];
	for (let i = 0; i < Math.ceil(ids.length / 30); i++) {
		chunks.push(ids.slice(i * 30, i * 30 + 30));
	}
	const results = await Promise.all(chunks.map(idArr => axios.get(
		`https://api.opensea.io/api/v1/assets?order_direction=desc&asset_contract_address=0xF210D5d9DCF958803C286A6f8E278e4aC78e136E&${idArr.map(id => `token_ids=${id}`).join("&")}&limit=${idArr.length}`
	)));

	return results.reduce((finalArr, result) => ([ ...finalArr, ...result.data.assets ]), []);
}

const discordMessageTypes = {
	new_race: "A new race has been created. Join the race using this link: "
}
export async function notifyDiscord(type, link) {
	if (!discordMessageTypes[ type ]) return;
	const results = await axios.post(
		`https://discord.com/api/webhooks/918175577008132146/RxMGyAfC4bNqPWOXbTQK_sp18Eppzw9RI2HR5vgpcKxWIZJAy4fQb0_WfFPsgZ11NoUM`,
		{ content: `${discordMessageTypes[ type ]} <${link}>`}
	);
	DEBUG && console.log(results);
	return;
}

// OTHER UTILITY FUNCTIONS

export function clamp(value, min, max) {
	return Math.max(min, Math.min(value, max));
}

export function shuffle(array) {
  let i = array.length;
  let randomIndex;
  while (i !== 0) {
    randomIndex = Math.floor(Math.random() * i);
    i--;
    [ array[i], array[ randomIndex ] ] = [ array[ randomIndex ], array[i] ];
  }
  return array;
}

export function padNumber(num, zeroes = 2) {
	return (num < Math.pow(10, zeroes - 1))
		? (new Array(zeroes).fill(0).join("") + num).slice(-zeroes)
		: num;
}

const intervals = [ 60, 60, 24 ];
export function formatTime(ms, places = 4) {
	const seconds = Math.floor(ms / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	return [ seconds, minutes, hours, days ]
		.map((time, i, arr) => i === arr.length - 1 ? time: padNumber(time % intervals[i]))
		.slice(0, places)
		.reverse()
		.join(":");
}

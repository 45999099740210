import { useCallback, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useWindowWidth } from "@react-hook/window-size";

import { getRacesOfType, clamp, getCurrentBlockNumber, listenForContractEvent, PENDING_BLOCKS, RACING_BLOCKS, DEBUG, getMyRaces, FINALIZING_BLOCKS } from "../utils";

import { gitbookLink } from "../links";

import { useWallet, useBlockNumber } from "../Hooks";

import { BorderButton, Flex } from "./Styles";
import { WrapperWithMenu } from "./WrapperWithMenu";
import { StatsBlock } from "./StatsBlock";
import { CreateRaceDialog } from "./CreateRaceDialog";
import { Radio } from "./Radio";

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
  width: 100%;
  padding-bottom: 150px;
  box-sizing: border-box;
`;

const LetsRace = styled.img`
  width: 70%;
	max-width: 860px;
  min-width: 200px;
  margin-top: 40px;
`;

const Buttons = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
  margin-top: 20px;
  width: 360px;
  z-index: 0;
`;

const slideUp = keyframes`
	0% { transform: translateY(300px); }
	50% { transform: translateY(300px); }
	100% { transform: translateY(0px); }
`;
const Dashboard = styled(Flex).attrs(({ column = false }) => ({
	column,
	justify: column ? "space-between": "center",
	align: column ? "center": "flex-start",
	wrap: "wrap"
}))`
  width: 100%;
  margin-top: 60px;
  box-sizing: border-box;
	padding: 40px;
	
	animation: ${slideUp} 2s ease;
`;

const flash = (from, to) => keyframes`
	0% { background-color: ${from}; }
	100% { background-color: ${to}; }
`;
const FlashingBorderButton = styled(BorderButton)`
	&:not(:hover) {
		animation: ${({ fromColor, toColor }) => flash(fromColor, toColor)} 1s alternate infinite linear;
	}
`;

export function Home() {
	const { account, connect, canTransact } = useWallet();

  const [ createDialogVisible, setCreateDialogVisible ] = useState(false);

  const allowed = localStorage.getItem("is_not_narc");

	const windowWidth = useWindowWidth();

	const [ myRaces, setMyRaces ] = useState([]);
	useEffect(() => {
		if (!account) return;

		(async () => {
			const races = await getMyRaces(account);
			setMyRaces(races.reverse());
		})()
	}, [ account ]);

	const [ openRaces, setOpenRaces ] = useState([]);
	const [ liveRaces, setLiveRaces ] = useState([]);
	const racesRef = useRef({ open: [], live: [] });
	useEffect(() => {
		racesRef.current.open = openRaces;
		racesRef.current.live = liveRaces;
	}, [ openRaces, liveRaces ]);

	useEffect(() => {
		const getRaces = async () => {
			const blockNumber = await getCurrentBlockNumber();
			const [ open, live ] = await Promise.all([
				getRacesOfType("open", blockNumber),
				getRacesOfType("live", blockNumber)
			]);
			open.forEach(race => {
				race.createBlock = Number(race.createBlock);
				race.startBlock = Number(race.startBlock);
				race.blocksLeft = clamp(race.startBlock - blockNumber, 0, PENDING_BLOCKS);
			});
			live.forEach(race => {
				race.createBlock = Number(race.createBlock);
				race.startBlock = Number(race.startBlock);
				race.blocksLeft = clamp(blockNumber - race.startBlock, 0, RACING_BLOCKS);
			});
			DEBUG && console.log(open, live);
			setOpenRaces(open);
			setLiveRaces(live);
		}
		getRaces();
	}, []);
	
	const onBlock = useCallback(blockNumber => {
		const open = [];
		const live = [];
		for (const race of racesRef.current.open) {
			const { startBlock, racers } = race;
			if (racers.length === 7) live.push({
				...race,
				startBlock: blockNumber,
				blocksLeft: RACING_BLOCKS
			});
			else if (blockNumber >= startBlock) live.push({
				...race,
				blocksLeft: clamp(RACING_BLOCKS - (blockNumber - startBlock), 0, RACING_BLOCKS)
			});
			else open.push({
				...race,
				blocksLeft: clamp(startBlock - blockNumber, 0, PENDING_BLOCKS)
			});
		}
		for (const race of racesRef.current.live) {
			const { startBlock } = race;
			if (blockNumber - startBlock <= RACING_BLOCKS) live.push({
				...race,
				blocksLeft: clamp(RACING_BLOCKS - (blockNumber - startBlock), 0, RACING_BLOCKS)
			});
		}
		setOpenRaces(open);
		setLiveRaces(live);
	}, []);
	const currentBlock = useBlockNumber(onBlock);

	// TODO: if we get events working, listen for RaceCreated to keep track of new races
	useEffect(() => {
		const unsubs = [
			listenForContractEvent("RaceJoined", ({ raceId, tokenId }) => {
				const existingRace = racesRef.current.open.find(({ id }) => String(id) === raceId);
				if (!existingRace) {
					return DEBUG && console.warn(`Race ${raceId} joined, but not tracked.`);
				}
				setOpenRaces(races => {
					const race = races.find(({ id }) => String(id) === raceId);
					if (race.racers.find(({ id }) => String(id) === tokenId)) return races;
					race.racers.push({ id: tokenId });
					return [ ...races ];
				});
			}),
			listenForContractEvent("RaceCreated", ({ raceId, hostTokenId, createBlock, startBlock, maxLevel }) => {
				setOpenRaces(races => ([
					...races,
					{
						id: raceId,
						createBlock,
						startBlock,
						blocksLeft: PENDING_BLOCKS,
						maxLevel: maxLevel,
						racers: [{ id: hostTokenId }],
					}
				]));
			})
		];

		return () => unsubs.forEach(fn => fn());
	}, []);

  return (
    <WrapperWithMenu
			account={account}
			connectWallet={connect}
			allowed={allowed}
			shouldTest={true}
			canTransact={canTransact}
		>
      <CreateRaceDialog
				account={account}
				canTransact={canTransact}
				isVisible={createDialogVisible}
				onClose={() => setCreateDialogVisible(false)}
			/>
      <Container>
        <LetsRace src={"/images/letsrace.png"} />

        <Buttons column={windowWidth < 650}>
          <BorderButton
						onClick={() => window.open(gitbookLink, "_blank")}
						bgColor="red"
					>
						WTF IS THIS SHIT
					</BorderButton>
          <FlashingBorderButton
            fromColor="#03800f"
						toColor="#04ff1f"
						onClick={async () => (await canTransact() && setCreateDialogVisible(true))}
          >
            CREATE NEW RACE
          </FlashingBorderButton>
        </Buttons>

        <Dashboard column={true}>
					{!!account && (
						<StatsBlock
							title="MY RACES"
							status={true}
							races={myRaces.map(race => {
								if (race.racerResults.length) return { ...race, status: "CLOSED" };

								if (currentBlock < race.startBlock) return { ...race, status: race.racers.length < 7 ? "OPEN": "LIVE" };

								if (race.racers.length === 1) return { ...race, status: "CANCELED" };

								if (currentBlock - race.startBlock < RACING_BLOCKS) return { ...race, status: "LIVE" };

								if (currentBlock - race.startBlock < RACING_BLOCKS + FINALIZING_BLOCKS) return { ...race, status: "COMPLETED" };
								
								return { ...race, status: "EXPIRED" };
							})}
						/>
					)}
          <StatsBlock
						title="OPEN RACES"
						races={openRaces}
					/>
          <StatsBlock
						title="LIVE RACES"
						races={liveRaces}
					/>
        </Dashboard>
      </Container>
			<Radio/>
    </WrapperWithMenu>
  );
}

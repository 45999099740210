export const createRainbowShader = ({ heightScale, heightOffset, csm = false }) => {
	const colorOut = csm ? "csm_DiffuseColor": "gl_FragColor";
	return {
		uniforms: {
			heightScale: { value: heightScale },
			heightOffset: { value: heightOffset },
		},
		vertexShader: `
		varying vec3 vPos;
		
		void main() {
			vPos = position;
			${csm ? "csm_Position = position": "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);"}; 
		}
		`,
		fragmentShader: `
		uniform float heightScale;
		uniform float heightOffset;
		varying vec3 vPos;

		void main() {
			float normHeight = max(min(heightScale * vPos.y + heightOffset, 1.0), 0.0);
			if (normHeight < 0.167) {
				${colorOut} = mix(vec4(0.5, 0.0, 1.0, 1.0), vec4(0.0, 0.0, 1.0, 1.0), normHeight * 6.0);
			}
			else if (normHeight < 0.333) {
				${colorOut} = mix(vec4(0.0, 0.0, 1.0, 1.0), vec4(0.0, 1.0, 1.0, 1.0), (normHeight - 0.167) * 6.0);
			}
			else if (normHeight < 0.5) {
				${colorOut} = mix(vec4(0.0, 1.0, 1.0, 1.0), vec4(0.0, 1.0, 0.0, 1.0), (normHeight - 0.333) * 6.0);
			}
			else if (normHeight < 0.667) {
				${colorOut} = mix(vec4(0.0, 1.0, 0.0, 1.0), vec4(1.0, 1.0, 0.0, 1.0), (normHeight - 0.5) * 6.0);
			}
			else if (normHeight < 0.833) {
				${colorOut} = mix(vec4(1.0, 1.0, 0.0, 1.0), vec4(1.0, 0.5, 0.0, 1.0), (normHeight - 0.667) * 6.0);
			}
			else {
				${colorOut} = mix(vec4(1.0, 0.5, 0.0, 1.0), vec4(1.0, 0.0, 0.0, 1.0), (normHeight - 0.833) * 6.0);
			}
		}
		`
	}
}
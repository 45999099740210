import { useEffect, useRef } from "react";
import { Color, DoubleSide, MeshStandardMaterial } from "three";
import { useFrame } from "@react-three/fiber";
import { Cylinder } from "@react-three/drei";
import CustomShaderMaterial from "three-custom-shader-material";

const vertexShader = `
varying vec2 uvs;

void main() {
	uvs = uv;
	// gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
	csm_Position = position;
}
`;
const fragmentShader = `
	uniform vec3 roadColor;
	uniform vec3 dividerColor;
	uniform float numDashes;
	uniform float lanes;
	uniform float offset;

	varying vec2 uvs;

	void main() {
		float x = mod(uvs.x + offset, 1.0);
		csm_DiffuseColor = vec4(roadColor, 1.0);
		float margin = 0.05 / lanes;
		if (uvs.y < margin || uvs.y > 1.0 - margin) {
			csm_DiffuseColor = vec4(1.0);
		}
		else {
			float divPos = mod(uvs.y * lanes, 1.0);
			if ((divPos < 0.025 || divPos > 0.975) && mod(floor(x * numDashes), 2.0) == 1.0) {
				csm_DiffuseColor = vec4(dividerColor, 1.0);
			}
		}
	}
`;

export function Lanes({ radius, number, speed = 1, status, ...props }) {
	const matRef = useRef();

	useEffect(() => {
		if (!matRef.current) return;

		matRef.current.uniforms.lanes.value = number;
	}, [ number ]);

	useFrame((_, delta) => {
		if (status < 1 || !matRef.current) return;
		matRef.current.uniforms.offset.value -= delta * speed / 5;
	});

	return (
		<group {...props}>
			<Cylinder
				args={[ radius, radius, 2 * number, 64, 1, true ]}
				rotation={[ 0, 0, Math.PI / 2 ]}>
				<CustomShaderMaterial
					ref={matRef}
					baseMaterial={MeshStandardMaterial}
					vertexShader={vertexShader}
					fragmentShader={fragmentShader}
					uniforms={{
						roadColor: { value: new Color("#101010") },
						dividerColor: { value: new Color("#ffff00") },
						numDashes: { value: 40 },
						lanes: { value: number },
						offset: { value: 0 }
					}}
					side={DoubleSide}
				/>
			</Cylinder>
			<Cylinder
				args={[ radius + 0.01, radius + 0.01, 2 * number, 64, 1, true ]}
				rotation={[ 0, 0, Math.PI / 2 ]}
				receiveShadow>
				<shadowMaterial attach="material" transparent opacity={0.4}/>
			</Cylinder>
		</group>
	)
}
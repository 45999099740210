import { useCallback, useEffect, useRef } from "react";

import { useDonaTraits } from "../../Hooks";
import { Dona } from "../DonaModel";

export function GarageDona({ traits = [], speed = 0, ...props }) {
	const vanRef = useRef();

	const traitProps = useDonaTraits(traits);
	
	const moveDona = useCallback((transform) => transform({
		dona: vanRef.current,
		startPos: [ 0, 0, 0 ]
	}), []);

	useEffect(() => {
		if (!vanRef.current) return;

		vanRef.current.position.set(0, 0, 0);
	}, [ traitProps ]);

	return (
		<group {...props}>
			<group ref={vanRef}>
				<Dona
					speed={speed}
					scale={0.7}
					curved={false}
					moveDona={moveDona}
					{...traitProps}
				/>
			</group>
		</group>
	)
}
import { MeshBasicMaterial, MeshStandardMaterial, TextureLoader } from "three";
import { Billboard, Sphere, useTexture } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";

import { prefix3D } from "../../utils";
import { sharedMaterials, cloneMaterialWithProps } from "../../shaders/materials";

function Eyes() {
	const eyeMap = useTexture(prefix3D + "eyes.jpg");
	if (eyeMap) {
		eyeMap.center.set(0.5, 0.5);
		eyeMap.repeat.set(4, 2);
	}

	const eyeMat = (
		<meshBasicMaterial
			attach="material"
			map={eyeMap}
		/>
	);

	return (
		<group position={[ 0, -3, -3 ]}>
			<Billboard position={[ -73, 0, 0 ]}>
				<Sphere
					args={[ 14 ]}
					rotation={[ 0, -Math.PI / 2, 0 ]}>
					{eyeMat}
				</Sphere>
			</Billboard>
			<Billboard position={[ 73, 0, 0 ]}>
				<Sphere
					args={[ 14 ]}
					rotation={[ 0, -Math.PI / 2, 0 ]}>
					{eyeMat}
				</Sphere>
			</Billboard>
		</group>
	);
}

const laserMat = new MeshBasicMaterial({ color: "#ffffff" });
const glowMat = new MeshBasicMaterial({
	color: "#ff0000",
	opacity: 0.7,
	transparent: true
});
function Lasers() {
	return (
		<group position={[ 0, -2, 5.5 ]}>
			<Select enabled={true}>
				<group position={[ -73, 0, 0 ]}>
					<Sphere
						args={[ 3 ]}
						material={laserMat}
					/>
					<Sphere
						args={[ 20 ]}
						material={glowMat}
					/>
				</group>

				<group position={[ 73, 0, 0 ]}>
					<Sphere
						args={[ 3 ]}
						material={laserMat}
					/>
					<Sphere
						args={[ 20 ]}
						material={glowMat}
					/>
				</group>
			</Select>
		</group>
	)
}

const nipMat = new MeshStandardMaterial({ color: "#be723c" });
function Nipples() {
	return (
		<group position={[ 0, -2, 7.5 ]}>
			<Sphere
				args={[ 8 ]}
				position={[ -73, 0, -2 ]}
				rotation={[ -Math.PI / 7, -Math.PI / 6, 0 ]}
				scale={[ 1, 1, 0.3 ]}
				material={nipMat}
			/>
			<Sphere
				args={[ 4.5 ]}
				position={[ -73.5, 0.625, 0 ]}
				material={nipMat}
			/>

			<Sphere
				args={[ 8 ]}
				position={[ 73, 0, -2 ]}
				rotation={[ -Math.PI / 7, Math.PI / 6, 0 ]}
				scale={[ 1, 1, 0.3 ]}
				material={nipMat}
			/>
			<Sphere
				args={[ 4.5 ]}
				position={[ 73.5, 0.625, 0 ]}
				material={nipMat}
			/>
		</group>
	)
}

export const headlights = {
	"Classic": undefined, // default
	"Curious": {
		model: Eyes
	},
	"Lasers": {
		model: Lasers,
		retainHeadlights: true
	},
	"Racing": {
		material: new MeshStandardMaterial({
			color: "#cccc00",
			opacity: 0.8,
			transparent: true
		})
	},
	"Tinted": {
		material: cloneMaterialWithProps(
			sharedMaterials.standard.black20,
			{ opacity: 0.85, transparent: true }
		)
	},
	"Vulnerable": {
		material: new MeshStandardMaterial({ color: "#ffcc99" }),
		model: Nipples,
		retainHeadlights: true
	},
	"Pretty Fly": {
		material: new MeshStandardMaterial({
			color: "#21cca8",
			normalMap: new TextureLoader().load(prefix3D + "fleye_normal.jpeg")
		})
	}
}

export function useHeadlights(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "headlights");
	if (!trait) return {};

	const { value } = trait;
	if (!headlights[ value ]) return {};

	return headlights[ value ];
}
import { useEffect, useState } from "react";

import { listenForBlock } from "../utils";

export function useBlockNumber(listener = null) {
	const [ blockNumber, setBlockNumber ] = useState(null);

	useEffect(() => {
		const unsubscribe = listenForBlock(number => {
			setBlockNumber(number);
			listener && listener(number);
		});

		return unsubscribe;
	}, [ listener ]);

	return blockNumber;
}
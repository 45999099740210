import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useDonaContext } from "../../providers";
import { useRaceTracker, useSoundEffect, useTimer, useWindowFocus } from "../../Hooks";

import { clamp, finalizeRace, isRaceFinalizable, pendingTime, RACING_BLOCKS } from "../../utils";

import { Flex } from "../Styles";
import { Racer, RacerNumber, RacerFinish } from "./Racer";
import { TrafficLight } from "./TrafficLight";

const Container = styled(Flex).attrs(({ messageOnly = false }) => ({
	column: true,
	justify: messageOnly ? "center": "flex-start",
	align: messageOnly ? "center": "flex-start"
}))`
  background-color: rgba(0,0,0,0.6);
	border: 5px solid #ffff00;
  border-radius: 12px;
  width: calc(100% - 80px);
	min-width: 660px;
	max-width: 1000px;
	height: ${({ footerActive }) => footerActive ? "calc(100vh - 300px)": "auto"};
	min-height: 490px;
	margin-top: 60px;
	overflow: hidden;
	${({ messageOnly = false }) => messageOnly && `
		font-size: 40px;
		color: white;
		font-family: LLPIXEL3, sans-serif;
		text-shadow: 1px 1px 10px white;
	`}
`;

const Header = styled(Flex)`
	width: 100%;
	height: 190px;
	border-bottom: 3px solid gray;
`;
const RaceHeader = styled(Flex).attrs(() => ({
	column: true
}))`
	width: calc(100% - 100px);
	padding: 20px 40px;
`;
const TimeLeft = styled.div`
	font-family: LLPIXEL3, sans-serif;
	font-size: 60px;
	margin-bottom: 20px;
	color: yellow;
	text-shadow: 1px 1px 10px yellow;

	& > span {
		color: white;
		text-shadow: 1px 1px 10px white;
	}
`;
const RaceInfo = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
	color: white;
	text-transform: uppercase;
	text-shadow: 1px 1px 10px white;
`;
const RaceInfoColumn = styled(Flex).attrs(() => ({
	column: true
}))`
	min-width: 160px;
	& > div:last-of-type {
		margin-top: 15px;
	}
`;

const RaceHeadingText = styled.div`
	font-family: LLPIXEL3, sans-serif;
	font-size: 50px;
	color: white;
	text-shadow: 1px 1px 10px white;
`;

const RaceButton = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	padding: 15px;
	border-radius: 10px;
	border: 2px solid white;
	color: white;
	text-shadow: 1px 1px 10px white;
	font-weight: 700;
	cursor: pointer;

	&:active {
		opacity: 0.6;
	}
	${({ disabled }) => disabled && `
		opacity: 0.6;
		pointer-events: none;
	`}

	& > img {
		margin-left: 15px;
		filter: invert() drop-shadow(1px 1px 5px rgba(255, 255, 255, 0.7));
		width: 20px;
	}
`;

const Body = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
	height: ${({ footerActive }) => footerActive ? "calc(100vh - 600px)": "auto"};
	min-height: 200px;
	overflow-y: auto;
`;

const JoinRaceFooter = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 100%;
	min-height: 25px;
	border-top: 3px solid gray;
`;
const JoinButtonContainer = styled(Flex).attrs(() => ({
	justify: "flex-end",
	align: "center"
}))`
	width: calc(100% - 200px);
	height: 100px;
	padding-right: 40px;
`;

const headerMap = {
	CANCELED: "COPS! RUN!",
	EXPIRED: "TOO SLOW, BUCKO",
	CLOSED: "THAT'S SOME JUICY XP"
}

export function RaceBlock({ raceId, openJoinMenu, canTransact, isMuted }) {
	const { donaMetadata } = useDonaContext()
	
	const [ currentBlock, setCurrentBlock ] = useState(0);

	const [ racerDonas, setRacerDonas ] = useState([]);
	const [ racerPositions, setRacerPositions ] = useState([]);

	const onUpdate = useCallback((racers, block) => {
		setRacerPositions(racers);
		setCurrentBlock(block);
	}, []);

	const [ isFinalizing, setIsFinalizing ] = useState(false);
	const onFinalize = useCallback(async () => {
		setIsFinalizing(true);
		if (!(await canTransact())) return setIsFinalizing(false);
		try {
			if (await isRaceFinalizable(raceId)) {
				const tx = await finalizeRace(raceId);
				await tx.wait();
			}
			else alert("Unable to finalize race. Please refresh and try again.");
		}
		catch(e) {
			console.error(e);
			alert("An error occurred while finalizing race. Please try again.");
		}
		finally {
			setIsFinalizing(false);
		}
	}, [ raceId, canTransact ]);

	const isWindowFocused = useWindowFocus();

	const [ playIdleSound, pauseIdleSound ] = useSoundEffect("/sounds/idling.mp3", {
		muted: isMuted || !isWindowFocused,
		loop: true,
		volume: 0.25
	});
	const [ playRacingSong, pauseRacingSong ] = useSoundEffect("/sounds/maggots.mp3", {
		muted: isMuted || !isWindowFocused,
		loop: true,
		volume: 0.5
	});
	const [ playJoinSound ] = useSoundEffect("/sounds/on-join.mp3", { muted: isMuted });
	const [ playStartSound ] = useSoundEffect("/sounds/on-start.mp3", { muted: isMuted });
	const [ playRaceSound ] = useSoundEffect("/sounds/on-block.mp3", { muted: isMuted || !isWindowFocused });
	const [ playFinishSound ] = useSoundEffect("/sounds/on-end.mp3", { muted: isMuted });

	const [
		isValid,
		{ maxLevel, startBlock, createTime, type = "FRIENDLY" },
		status,
		isLoading
	] = useRaceTracker(raceId, {
		onUpdate,
		onJoin: playJoinSound,
		onStart: playStartSound,
		onFinish: playFinishSound
	});

	useEffect(() => {
		if (status === "OPEN") playIdleSound();
		else pauseIdleSound();
	}, [ status, playIdleSound, pauseIdleSound ]);

	useEffect(() => {
		if (status === "LIVE") playRacingSong();
		else pauseRacingSong();
	}, [ status, playRacingSong, pauseRacingSong ]);

	useEffect(() => {
		if (!donaMetadata) return;
		if (racerPositions.length === racerDonas.length) return;

		setRacerDonas((racerPositions || []).map(({ tokenId }) => {
			const { metadata, images } = donaMetadata[ tokenId ] || {}
			return {
				token_id: tokenId,
				name: metadata?.name || "",
				image_url: images?.[ "512" ] || "/sign.png"
			}
		}));
	}, [ racerPositions, racerDonas.length, donaMetadata ]);

	const shouldCount = useMemo(() => status === "OPEN", [ status ]);
	const timeLeft = useTimer(pendingTime + createTime, shouldCount, { places: 3 });

	const footerActive = status === "OPEN" && racerDonas.length < 7;

	const blocksRaced = useMemo(() => (
		clamp(currentBlock - startBlock, 0, RACING_BLOCKS)
	), [ currentBlock, startBlock ]);

	const maxDistanceTraveled = useMemo(() => (
		racerPositions.reduce((max, { position = 0 }) => position > max ? position: max, 0)
	), [ racerPositions ]);

	useEffect(() => {
		if (blocksRaced > 0 && blocksRaced < 60) playRaceSound();
	}, [ blocksRaced, playRaceSound ]);

  return !isValid && !isLoading
		?	<Container messageOnly={true}>INVALID RACE!</Container>
		: (
			<Container
				messageOnly={isLoading}
				footerActive={footerActive}
			>
				{isLoading
					? "LOADING RACE..."
					: (
						<>
							<Header>
								<RaceHeader>
									<TimeLeft>
										{headerMap[ status ]
											? headerMap[ status ]
											: shouldCount
												? timeLeft
												: <>BLOCKS: <span>{blocksRaced || 0}/{RACING_BLOCKS}</span></>
										}
									</TimeLeft>
									<RaceInfo>
										<RaceInfoColumn>
											<div>
												STATUS: <strong>{(!isValid && !isLoading) ? "INVALID" :status}</strong>
											</div>
											<div>
												TYPE: <strong>{type}</strong>
											</div>
										</RaceInfoColumn>
										<RaceInfoColumn>
											<div>
												MAX LEVEL: <strong>{maxLevel}</strong>
											</div>
											<div>
												RACE #: <strong>{raceId}</strong>
											</div>
										</RaceInfoColumn>
										<RaceButton onClick={() => navigator.clipboard.writeText(window.location.href)}>
											<div>COPY RACE URL</div>
											<img
												src="/copy.svg"
												alt="C"
											/>
										</RaceButton>
									</RaceInfo>
								</RaceHeader>
								<TrafficLight status={status}/>
							</Header>
							<Body footerActive={footerActive}>
								{racerDonas.map(({ token_id, name, image_url = "/sign.png" }, i) => {
									const { position = 0 } = racerPositions.find(({ tokenId }) => String(tokenId) === String(token_id)) || {};
									const isWinner = [ "COMPLETE", "CLOSED" ].includes(status) && position === maxDistanceTraveled;
									return (
										<Racer
											key={token_id}
											number={i + 1}
											name={name}
											image={image_url}
											progress={[ "OPEN", "CANCELED" ].includes(status) ? 0: (blocksRaced / RACING_BLOCKS) * position / maxDistanceTraveled}
											winnerContent={isWinner && <>
												<RaceHeadingText>WINNER</RaceHeadingText>
												{![ "CLOSED", "EXPIRED" ].includes(status) && (
													<RaceButton
														onClick={onFinalize}
														disabled={isFinalizing}
													>
														FINALIZE
													</RaceButton>
												)}
											</>}
										/>
									)
								})}
							</Body>
							<JoinRaceFooter>
								{footerActive && (
									<>
										{/* The + sign sits low so gotta bump it up with padding */}
										<RacerNumber style={{ paddingBottom: "15px" }}>+</RacerNumber>
										<JoinButtonContainer>
											<RaceButton onClick={openJoinMenu}>JOIN RACE</RaceButton>
										</JoinButtonContainer>
										<RacerFinish checkered={false}/>
									</>
								)}
							</JoinRaceFooter>
						</>
					)
				}
			</Container>
		)
}

import styled from "styled-components";
import { useReducer, useRef, useState } from "react";
import { Flex } from "./Styles";
import { useWindowWidth } from "@react-hook/window-size";

import { narcLink } from "../links";

const borderRadius = '40px';

const NarcBox = styled.div`
  position: fixed;
  top: 10vh;
  left: 50%;
	transform: translateX(-50%);
  width: min(80vw, 80vh);
  height: min(80vw, 80vh);
	max-width: 600px;
	max-height: 600px;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: ${borderRadius};
	font-family: ArialBlack;
	font-size: ${({ isSmall }) => isSmall ? "1.25rem": "2.5rem"};

  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

const InnerNarc = styled(Flex).attrs(() => ({
	column: true,
	justify: "space-between",
	align: "center"
}))`
  position: absolute;
  top: 5px;
  right: 5px;
  left: 5px;
  bottom: 5px;
  border-radius: ${borderRadius};
  border-width: 5px;
  border-style: solid;
	padding: 25px;
	text-align: center;

  @media only screen and (max-width: 600px) {
    display: block;
  }
`;

// TODO use correct font
const NarcText = styled.div`
	width: 100%;
	text-align: center;
	font-weight: 900;
	margin-bottom: 0.3rem;
	margin-top: 1rem;
	transform: scale(1, 1.5);
`;

const NarcImg = styled.img`
	width: 60%;
	display: block;
	margin: auto;
`;

const NarcOption = styled.div`
	cursor: pointer;
	text-align: center;
	margin-bottom: 1rem;
	font-weight: 900;
	transform: scale(1, 1.5);
	&:hover {
		opacity: 0.5;
	}
`;

const NarcBlackText = styled.div`
	background-color: black;
	width: 100%;
	color: white;
	font-weight: bold;
	font-size: 22px;
	text-align: center;
	margin-top: 1rem;
	padding: 20px;
`;

const NarcSpan = styled.div`
	transform: scale(1, 1.5);
	text-align: center;
	margin-bottom: 1rem;
	font-weight: 900;
`;

const CaptchaDaddy = styled.div`
	width: 100%;
	display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const CaptchaImage = styled.img`
	width: 25%;
	border: 5px solid ${({ selected }) => selected ? "#00ff00": "transparent"};
	cursor: pointer;
`;

function NarcQuestion({ setStage, isSmall }) {
	return (
		<NarcBox isSmall={isSmall}>
			<InnerNarc>
				<NarcText>ARE YOU A COP?</NarcText>
				<NarcImg src='./cop.png'/>
				<Flex justify="center" align="center">
					<NarcOption onClick={() => window.location = narcLink}>YES</NarcOption>
					<NarcSpan>&nbsp;/&nbsp;</NarcSpan>
					<NarcOption onClick={() => setStage(2)}>NO</NarcOption>
				</Flex>
			</InnerNarc>
		</NarcBox>
	)
}

function NarcCoptcha({ images, onSubmit, isSmall }) {
	return (
		<NarcBox isSmall={isSmall}>
			<InnerNarc>
				<NarcText>PROVE IT.</NarcText>
				<NarcBlackText>SELECT ALL IMAGES THAT YOU LIKE:</NarcBlackText>
				<CaptchaDaddy>{images}</CaptchaDaddy>
				<NarcOption onClick={onSubmit}>VERIFY</NarcOption>
			</InnerNarc>
		</NarcBox>
	)
}

function NotNarcEntry({ allowed, isSmall }) {
	return (
		<NarcBox isSmall={isSmall}>
			<InnerNarc>
				<NarcText>
					{allowed 
						? "WELCOME BACK"
						: "YOU'RE IN"
					}
				</NarcText>
				<NarcImg src='./yourein.png'/>
			</InnerNarc>
		</NarcBox>
	)
}

const notNarc = [ true, true, true, true, true, true, false, true ];

export function NarcTest({ allowed, clearTest }) {
	const windowWidth = useWindowWidth();
	const isSmallScreen = windowWidth < 650;

  const [ stage, setStage ] = useState(1);

  const { current: selected } = useRef(notNarc.map(() => false));

	// tried using useState for selected, but it wasn't working for some reason, so doing ref and reducer instead
	const [, forceUpdate ] = useReducer(x => !x, false);

	if (allowed || stage === 3) {
		setTimeout(() => {
			localStorage.setItem("is_not_narc", true);
			clearTest();
		}, 4000);
		return (
			<NotNarcEntry
				isSmall={isSmallScreen}
				allowed={allowed}
			/>
		)
	}
	if (stage === 2) return (
		<NarcCoptcha
			isSmall={isSmallScreen}
			onSubmit={() => {
				if (selected.every((value, i) => value === notNarc[i])) return setStage(3);
				alert("Nice try, dweeby narc...");
			}}
			images={
				selected.map((value, i) => (
					<CaptchaImage
						onClick={() => {
							selected[i] = !value;
							forceUpdate();
						}}
						selected={value}
						key={i}
						src={`./coptcha-${i + 1}.png`}
					/>
				))
			}>
		</NarcCoptcha>
	);
	return (
		<NarcQuestion
			isSmall={isSmallScreen}
			setStage={setStage}
		/>
	)
}

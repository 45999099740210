import { useEffect, useMemo, useRef } from "react";
import { FrontSide, MeshStandardMaterial, sRGBEncoding, TextureLoader, Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { Plane, Text, useGLTF, useTexture } from "@react-three/drei";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import { prefix3D } from "../../utils";

import { useGLTFAnimation } from "../useGLTFAnimation";

import { Dona } from "../../Components/DonaModel";
import { createKuwaharaShader } from "../../shaders/kuwahara";
import { sharedMaterials } from "../../shaders/materials";

function NestedDona({ nodes, materials, speed, ...props }) {
	const frontRims = useRef();
	const backRims = useRef();

	useFrame((_, delta) => {
		if (!speed) return;
		frontRims.current && (frontRims.current.rotation.x += 15 * speed * delta);
		backRims.current && (backRims.current.rotation.x += 15 * speed * delta);
	});

	return (
		<group {...props} dispose={null}>
			<group scale={0.01}>
				<mesh geometry={nodes.back_wiper.geometry} material={materials.black} position={[-24.19, 126.7, -236.2]} />
				<mesh geometry={nodes.black_f_grill_parrt00.geometry} material={materials.carpaint} position={[0, 71.4, 238.15]} />
				<mesh geometry={nodes.black_f_grill_parrt03.geometry} material={materials.black} position={[0, 71.51, 238.9]} />
				<mesh geometry={nodes.black_glass.geometry} material={materials.black} position={[0, 142.71, -142.4]} />
				<mesh geometry={nodes.black_r_top_reflectors00.geometry} material={materials.black} position={[0, 169.03, -195.4]} />
				<mesh geometry={nodes.black_r_top_reflectors01.geometry} material={materials.black} position={[0, 169.03, -196.12]} />
				<mesh geometry={nodes.black_radiator00.geometry} material={materials.black} position={[-0.1, 55.85, 224.13]} />
				<mesh geometry={nodes.black_radiator01.geometry} material={materials.black} position={[0, 70.74, 237.85]} />
				<mesh geometry={nodes.body.geometry} material={materials.carpaint} position={[0, 102.08, -0.4]} />
				<mesh geometry={nodes.carpaint_r_top.geometry} material={materials.carpaint} position={[0, 169.45, -194.5]} />
				<mesh geometry={nodes.chrome_f_grill_part00.geometry} material={materials.chrome} position={[0, 71.55, 238.77]} />
				<mesh geometry={nodes.chrome_f_grill_part01.geometry} material={materials.chrome} position={[0, 71.51, 242.66]} />
				<mesh geometry={nodes.clearglass_taillight.geometry} material={materials.clearglass} position={[0, 112.46, -223.79]} />
				<mesh geometry={nodes.det.geometry} material={materials.chrome} position={[0, 110.7, -237.79]} />
				<mesh geometry={nodes.det001.geometry} material={materials.black} position={[0, 141.69, -17.09]} />
				<mesh geometry={nodes.det002.geometry} material={materials.mattemetal} position={[0, 180.43, -80.62]} />
				<mesh geometry={nodes.det004.geometry} material={materials.black} position={[0, 37.93, 232.31]} />
				<mesh geometry={nodes.dets1.geometry} material={materials.black} position={[0.02, 115.43, -239.37]} />
				<mesh geometry={nodes.dets2.geometry} material={materials.black} position={[0, 71.5, 243.4]} />
				<mesh geometry={nodes.down.geometry} material={materials.black} position={[0, 53.29, 1.28]} />
				<mesh geometry={nodes.ex.geometry} material={materials.chrome} position={[-56.88, 77.1, -235.3]} />
				<mesh geometry={nodes.frame.geometry} material={materials.black} position={[0, 36.58, 232.47]} />
				<mesh geometry={nodes.frame001.geometry} material={materials.black} position={[0, 80.1, 222.47]} />
				<mesh geometry={nodes.Front_Logo.geometry} material={materials.chrome} position={[0.02, 115.34, -238.81]} />
				<mesh geometry={nodes.Front_Logo001.geometry} material={materials.chrome} position={[0, 71.33, 242.73]} />
				<mesh geometry={nodes.front_tires.geometry} material={materials.tire} position={[-84.85, 35.47, 160.47]} rotation={[0, 0, -Math.PI / 2]} scale={[176.36, 96.24, 176.36]} />
				<mesh geometry={nodes.glass.geometry} material={materials.clearglass} position={[0, 80.06, 224.41]} />
				<mesh geometry={nodes.glass002.geometry} material={materials.redglass} position={[0, 98.93, -223.63]} />
				<mesh geometry={nodes.glass003.geometry} material={materials.clearglass} position={[0, 37.93, 233.8]} />
				<mesh geometry={nodes.grill2.geometry} material={materials.carpaint} position={[0, 71.15, 238.07]} />
				<mesh geometry={nodes.handles.geometry} material={materials.carpaint} position={[0, 98.66, 7.01]} />
        <mesh geometry={nodes.headlight_backplates.geometry} material={materials.black} scale={100} />
				<mesh geometry={nodes['in'].geometry} material={materials.black} position={[0, 56.41, 225.82]} />
				{/* <mesh geometry={nodes.interior01.geometry} material={materials.interior} position={[0, 103.46, -44.11]} /> */}
				<mesh geometry={nodes.KIA_logo_front.geometry} material={materials.chrome} position={[0.27, 115.5, -239.38]} />
				<mesh geometry={nodes.KIA_logo_front001.geometry} material={materials.chrome} position={[-0.1, 71.59, 243.39]} />
				<mesh geometry={nodes.mirror01.geometry} material={materials.mirror} position={[0, 120.4, 90.78]} />
				<mesh geometry={nodes.mirror_box.geometry} material={materials.black} position={[0, 122.19, 104.24]} />
				<mesh geometry={nodes.mirrors.geometry} material={materials.carpaint} position={[0, 120.12, 97.17]} />
				<mesh geometry={nodes.orange_glass.geometry} material={materials.orangeglass} position={[0, 77.51, 219.4]} />
				<mesh geometry={nodes.rail.geometry} material={materials.black} position={[0, 177.32, -81.66]} />
				<mesh geometry={nodes.rear_tires.geometry} material={materials.tire} position={[-84.85, 35.47, -142.47]} rotation={[0, 0, -Math.PI / 2]} scale={[176.36, 96.24, 176.36]} />
				<mesh geometry={nodes.redglass_r_top.geometry} material={materials.redglass} position={[0, 168.99, -197.18]} />
				<mesh geometry={nodes.redglass_taillight.geometry} material={materials.redglass} position={[0, 94.21, -224.43]} />
				<mesh ref={frontRims} geometry={nodes.rim03.geometry} material={materials.rim} position={[-92.92, 35.47, 160.47]} />
				<mesh ref={backRims} geometry={nodes.rim03001.geometry} material={materials.rim} position={[-92.92, 35.47, -142.47]} />
				<mesh geometry={nodes.Sedona_r_text.geometry} material={materials.chrome} position={[53.14, 76.98, -236.35]} />
				<mesh geometry={nodes.window.geometry} material={materials.windowglass} position={[0, 138.08, -48.98]} />
				<mesh geometry={nodes.window001.geometry} material={materials.windowglass} position={[0, 143.61, -216.71]} />
				<mesh geometry={nodes.window002.geometry} material={materials.windowglass} position={[0, 139.31, 104.61]} />
				<mesh geometry={nodes.window_frame.geometry} material={materials.black} position={[0, 137.58, -1.76]} />
				<mesh geometry={nodes.wiper.geometry} material={materials.black} position={[3.33, 113.22, 145.9]} />
			</group>
		</group>
	)
}
function DonaForever({ speed, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "dona_compressed_v2.glb");

	const clonedMaterials = useMemo(() => {
		if (!materials) return undefined;

		materials.carpaint.color.set("#ffffff");
		materials.carpaint.metalness = 0;
		materials.carpaint.roughness = 0.05;

		return Object.entries(materials).reduce((obj, [ name, mat ]) => {
			const clonedMat = mat.clone();
			obj[ name ] = clonedMat;
			clonedMat.side = FrontSide;
			clonedMat.opacity = 1;
			clonedMat.transparent = false;
			clonedMat.depthTest = false;
			clonedMat.needsUpdate = true;
			return obj;
		}, {});
	}, [ materials ]);

	return (
		<group {...props}>
			{clonedMaterials.carpaint && Array.from({ length: 6 }, (_, i) => (
				<NestedDona
					key={i}
					nodes={nodes}
					materials={clonedMaterials}
					speed={speed}
					renderOrder={i + 1}
					position={[ 0, 0.3 * (1 - Math.pow(0.75, i + 1)) / 0.25, 0 ]}
					scale={Math.pow(0.75, i + 1)}
				/>
			))}
		</group>
	)
}

const numDonas = 30;
function DonaLot({ speed, curved }) {
	return curved
		? (
			<group position={[ 0, -26.67, 0.2 ]}>
				{Array.from({ length: numDonas - 1 }, (_, i) => (
					<group
						key={i}
						rotation={[ (2 / numDonas) * (i + 1) * Math.PI, 0, 0 ]}>
						<Dona
							position={[ 0, 26.6, 0 ]}
							speed={speed}
						/>
					</group>
				))}
			</group>
		)
		: (
			<group>
				{Array.from({ length: 35 }, (_, i) => i === 3
					? null
					: (
						<group
							key={i}
							position={[ 7.5 - 2.5 * (i % 7), 0, -6 * Math.floor(i / 7) ]}>
							<Dona
								speed={speed}
								curved={false}
							/>
						</group>
					)
				)}
			</group>
		)
}

function DudeBradPitt() {
	const pittMap = useTexture(prefix3D + "pitt.png");

	return (
		<group position={[ 0, 1.36, 0.6 ]}>
			<group
				position={[ -0.74, 0, 0 ]}
				rotation={[ 0, 0, -Math.PI / 10.5 ]}>
				<Plane
					args={[ 0.5, 0.5 ]}
					rotation={[ 0.021 * Math.PI, 0.505 * Math.PI, 0 ]}>
					<meshBasicMaterial
						map={pittMap}
						transparent={true}
						alphaTest={0.1}
					/>
				</Plane>
			</group>
			<group
				position={[ 0.74, 0, 0 ]}
				rotation={[ 0, 0, Math.PI / 10.5 ]}>
				<Plane
					args={[ 0.5, 0.5 ]}
					rotation={[ 0.021 * Math.PI, -0.505 * Math.PI, 0 ]}>
					<meshBasicMaterial
						map={pittMap}
						transparent={true}
						alphaTest={0.1}
					/>
				</Plane>
			</group>
		</group>
	)
}

function HunterScope() {
	const { nodes, materials } = useGLTF(prefix3D + "sniper_scope_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, 0 ]}
			scale={0.04}
			dispose={null}>
			<mesh geometry={nodes.Cylinder001.geometry} material={materials['02 - Default']} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
		</group>
	)
}

function Lobster({ speed, ...props }) {
	const group = useRef();
	const anim = useRef();

	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "lobster_compressed.glb",
		groupRef: group,
		animRef: anim,
		repetitions: 1
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.timeScale = 1;
		animation.reset().play();
		const i = setInterval(() => {
			animation.reset().play();
		}, 3000);
		return () => {
			animation.halt(1.5);
			clearInterval(i);
		}
	}, [ speed ]);

	return (
    <group
			ref={group}
			{...props}
			dispose={null}>
      <group name="Scene">
        <group name="Armature" position={[0.46, 2.45, -1.11]} rotation={[0.74, -0.74, -1.18]}>
          <primitive object={nodes.Bone} />
          <skinnedMesh name="Lobster_Claws_low" geometry={nodes.Lobster_Claws_low.geometry} material={materials.mat} skeleton={nodes.Lobster_Claws_low.skeleton} />
        </group>
      </group>
    </group>
  )
}

function MuttCutts({ speed, ...props }) {
	const group = useRef();
	const tail = useRef();
	const anim = useRef();

	const [ muttMap, dootMap, signMap ] = useTexture([
		prefix3D + "fur.jpg",
		prefix3D + "doot_blep_small.png",
		prefix3D + "mutt_sign.png"
	]);
	const muttMat = (
		<meshStandardMaterial
			color="#dddddd"
			map={muttMap}
		/>
	)

	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "muttcutts_compressed.glb",
		groupRef: group,
		animRef: anim,
		repetitions: 1
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.timeScale = 1;
		animation.reset().play();
		const i = setInterval(() => {
			animation.reset().play();
		}, 3000);
		return () => {
			animation.halt(1.5);
			clearInterval(i);
		}
	}, [ speed ]);

	useFrame(({ clock }) => {
		if (!speed) return;

		tail.current.rotation.z = Math.PI / 20 * Math.sin(15 * clock.getElapsedTime());
	});

	return (
		<group
			ref={group}
			{...props}
			dispose={null}>
			<group name="Scene">
				<group name="Armature" position={[0, 0.36, 2.38]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 0.85, 1]}>
					<primitive object={nodes.Bone} />
					<skinnedMesh name="tongue" frustumCulled={false} geometry={nodes.tongue.geometry} material={materials.tongueMat} skeleton={nodes.tongue.skeleton} />
				</group>
				<mesh name="Cube" geometry={nodes.Cube.geometry} position={[1.01, 0.82, -1.4]} rotation={[0, 0, 0.07]} scale={[0.1, 0.5, 0.5]}>
					{muttMat}
				</mesh>
				<mesh name="Cube" geometry={nodes.Cube.geometry} position={[-1.01, 0.82, -1.4]} rotation={[0, 0, -0.07]} scale={[-0.1, 0.5, 0.5]}>
					{muttMat}
				</mesh>
				<mesh ref={tail} name="Cylinder" geometry={nodes.Cylinder.geometry} position={[0, 1.58, -2]} rotation={[-1.21, 0, 0]} scale={[0.15, 0.5, 0.15]}>
					{muttMat}
				</mesh>
			</group>
			<group
				position={[ 0.8, 1.4, -0.65 ]}
				rotation={[ 0, 0, Math.PI / 10 ]}>
				<Plane
					args={[ 1.8, 1.8 ]}
					rotation={[ 0, Math.PI / 2, 0 ]}>
					<meshBasicMaterial map={signMap} transparent={true} alphaTest={0.1}/>
				</Plane>
			</group>
			<group
				position={[ -0.8, 1.4, -0.65 ]}
				rotation={[ 0, 0, -Math.PI / 10 ]}>
				<Plane
					args={[ 1.8, 1.8 ]}
					rotation={[ 0, -Math.PI / 2, 0 ]}>
					<meshBasicMaterial map={signMap} transparent={true} alphaTest={0.1}/>
				</Plane>
			</group>
			<Plane
				args={[ 0.25, 0.25 ]}
				position={[ -0.7, 0.45, 1.13 ]}
				rotation={[ 0, Math.PI, 0 ]}
				scale={[ -1, 1, 1 ]}>
				<meshBasicMaterial map={dootMap} transparent={true} alphaTest={0.1}/>
			</Plane>
		</group>
  )
}

function NewFrontier({ speed, ...props }) {
	const spinRef = useRef();

	const spacexMap = useTexture(prefix3D + "spacex.png");
	const { nodes, materials } = useGLTF(prefix3D + "spacex_compressed.glb");
	
	useFrame((_, delta) => {
		if (!speed) return;
		spinRef.current.rotation.z += 15 * speed * delta;
	});

	return (
		<group
			{...props}
			dispose={null}>
			<group position={[0, 2.16, 0]} scale={0.5}>
				<mesh geometry={nodes.housing.geometry} material={materials.silver} />
				<group ref={spinRef}>
					<mesh geometry={nodes.impeller.geometry} material={materials.dark_silver} />
					<mesh geometry={nodes.nose.geometry} material={materials.dark_silver} />
				</group>
			</group>
			<mesh geometry={nodes.wing.geometry} material={materials.black} position={[0.81, 0.75, 0.07]} scale={[0.81, 1, 1]} />
			<mesh geometry={nodes.wing.geometry} material={materials.black} position={[-0.81, 0.75, 0.07]} scale={[-0.81, 1, 1]} />
			<group position={[ 0, 0.5, 0.1 ]}>
				<Plane
					args={[ 1.6, 1.333 ]}
					position={[ 0.95, 0, 0 ]}
					rotation={[ 0, Math.PI / 2, 0 ]}>
					<meshBasicMaterial map={spacexMap} transparent={true} alphaTest={0.1}/>
				</Plane>
				<Plane
					args={[ 1.6, 1.333 ]}
					position={[ -0.95, 0, 0 ]}
					rotation={[ 0, -Math.PI / 2, 0 ]}>
					<meshBasicMaterial map={spacexMap} transparent={true} alphaTest={0.1}/>
				</Plane>
			</group>
		</group>
	)
}

const camWorldPos = new Vector3();
const groupWorldPos = new Vector3();
function OneMoreThing() {
	const jobsMap = useTexture(prefix3D + "jobs.png");

	const groupRef = useRef();

	useFrame(({ camera }) => {
		if (!camera || !groupRef.current) return;
		camera.getWorldPosition(camWorldPos);
		groupRef.current.getWorldPosition(groupWorldPos);
		camWorldPos.sub(groupWorldPos);
		groupRef.current.rotation.y = Math.atan2(camWorldPos.x, camWorldPos.z);
	});

	return (
		<group
			ref={groupRef}
			position={[ 0, 0, 0 ]}>
			<Text
				position={[ 0, 2, -1.5 ]}
				color="white"
				fontSize={0.3}>
				One more thing...
			</Text>
			<Plane
				args={[ 1, 0.5 ]}
				position={[ 0.5, 0.25, 2.51 ]}>
				<meshBasicMaterial
					map={jobsMap}
					alphaTest={0.1}
				/>
			</Plane>
		</group>
	)
}

export const environments = {
	"'Dona Forever": {
		upgrades: DonaForever,
		paintProps: {
			material: new MeshStandardMaterial({
				color: "#ffffff",
				metalness: 0,
				roughness: 0.05
			})
		},
		renderOrder: 0
	},
	"'Dona in Oil": {
		environment: {
			overrideMat: new CustomShaderMaterial({
				baseMaterial: MeshStandardMaterial,
				...createKuwaharaShader({
					map: (() => {
						const donaInOilMap = new TextureLoader().load(prefix3D + "oil_map_v3.jpg");
						donaInOilMap.flipY = false;
						donaInOilMap.encoding = sRGBEncoding;
						donaInOilMap.needsUpdate = true;
						return donaInOilMap;
					})(),
					radius: 5,
					csm: true
				}),
				metalness: 0,
				roughness: 0.8
			})
		}
	},
	"'Dona Lot": {
		upgrades: DonaLot,
		paintProps: { color: "#ffffff" }
	},
	"'Dona's Night Out": undefined,
	"Buy My Token": {
		paintProps: { texture: prefix3D + "buyme_map_v1.jpg" }
	},
	"Dude I'm Pretty Sure Brad Pitt's Hiding Behind That Car": {
		upgrades: DudeBradPitt,
		paintProps: { color: "#ffffff" }
	},
	"General Lee": {
		paintProps: { texture: prefix3D + "general_lee.jpg" }
	},
	"Gotta Catch 'Em All!": undefined,
	"Hunter": {
		upgrades: HunterScope,
		paintProps: {
			texture: prefix3D + "hunter_map_v2.jpg",
			color: "#bbbbbb"
		},
		headlights: {
			material: new MeshStandardMaterial({ color: "#00ff00" })
		},
		windows: {
			material: new MeshStandardMaterial({
				color: "#995531",
				opacity: 0.5,
				transparent: true
			})
		}
	},
	"Lucky Blue Lobster": {
		paintProps: {
			color: "#5999bc"
		},
		windows: {
			material: new MeshStandardMaterial({
				color: "#0011aa",
				opacity: 0.5,
				transparent: true
			})
		},
		headlights: {
			material: new MeshStandardMaterial({ color: "#70bbdd" })
		},
		upgrades: function(props) {
			return (
				<group>
					<Lobster
						{...props}
						position={[ 0.55, 0.1, 1.5 ]}
						rotation={[ 0, -Math.PI / 20, 0 ]}
						scale={0.2}
					/>
					<Lobster
						{...props}
						position={[ -0.55, 0.1, 1.5 ]}
						rotation={[ 0, Math.PI / 20, 0 ]}
						scale={[ -0.2, 0.2, 0.2 ]}
					/>
				</group>
			)
		},
		wheelProps: {
			model: () => (<></>), // remove wheels
			spins: false
		}
	},
	"Mutt Cutts": {
		upgrades: MuttCutts,
		paintProps: {
			color: "#b6bdbd",
			roughness: 0.9,
			texture: prefix3D + "carpet.jpg",
			textureProps: {
				repeat: [ 2, 2 ]
			}
		}
	},
	"New Frontier": {
		upgrades: NewFrontier,
		paintProps: {
			color: "#ffffff"
		},
		wheelProps: {
			rimMat: sharedMaterials.standard.black20,
			tireMat: sharedMaterials.standard.white,
			moveDona: ({ elapsed, dona, startPos }) => {
				dona.position.y = startPos[1] + 1.5 + 0.1 * Math.sin(elapsed);
				dona.rotation.x = -Math.PI / 60 * Math.sin(elapsed);
			}
		}
	},
	"One More Thing": {
		upgrades: OneMoreThing,
		paintProps: {
			color: "#ffffff"
		}
	},
	"REPENT": undefined,
	"The Creation Of 'Dona": undefined,
	"Tuna, No Crust": undefined
}

export function useEnvironment(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "environment");
	if (!trait) return undefined;

	return environments[ trait.value ];
}
import { Suspense, useMemo, useState } from "react";
import { AmbientLight } from "three";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, PerspectiveCamera, Stats } from "@react-three/drei";
import { SelectiveBloom, EffectComposer, Select, Selection, Pixelation, Scanline, Noise, DotScreen, ChromaticAberration, Glitch } from "@react-three/postprocessing";
import { folder, useControls } from "leva";

import { Lanes } from "./Lanes";
import { Racer } from "./Racer";
import { TrafficLight } from "./TrafficLight";

const radius = 20;

export function RaceScene({ racers, raceStatus, setRaceStatus, children }) {
	const {
		pixelationActive,
		granularity,
		scanlineActive,
		scanlineDensity,
		noiseActive,
		dotsActive,
		dotsScale,
		dotsAngle,
		chromActive,
		chromIntensity,
		glitchActive
	} = useControls({
		pixelation: folder({
			pixelationActive: false,
			granularity: {
				min: 0,
				max: 50,
				value: 3,
				step: 1
			}
		}),
		scanline: folder({
			scanlineActive: false,
			scanlineDensity: {
				min: 0,
				max: 10,
				value: 2.75,
				step: 0.25
			}
		}),
		noise: folder({
			noiseActive: false
		}),
		dotscreen: folder({
			dotsActive: false,
			dotsScale: {
				min: 0,
				max: 5,
				value: 1,
				step: 0.1
			},
			dotsAngle: {
				min: 0,
				max: 3,
				value: Math.PI / 20,
				step: 0.15
			}
		}),
		chromaticAbberation: folder({
			chromActive: false,
			chromIntensity: {
				min: 0.1,
				max: 2,
				step: 0.1,
				value: 1
			}
		}),
		glitch: folder({
			glitchActive: false
		})
	});

	const [ camera, setCamera ] = useState();

	const { min, max } = useMemo(() => {
		return racers.reduce((obj, { distance }) => {
			obj.min = Math.min(obj.min, distance);
			obj.max = Math.max(obj.max, distance);
			return obj;
		}, { min: Infinity, max: 0 });
	}, [ racers ]);

	return (
		<Suspense>
			<Canvas
				background="black"
				dpr={window.devicePixelRatio}
				flat={true}
				shadows>
				<Stats/>
				<Environment
					background={false}
					path="/images/"
					files="potsdamer_platz_1k.hdr"
				/>
				<OrbitControls
					camera={camera}
				/>
				<PerspectiveCamera
					ref={setCamera}
					makeDefault
					position={[ 10, 8, 12 ]}
					fov={40}
					aspect={window.innerWidth / window.innerHeight}
					near={1}
					far={1000}
				/>
				<ambientLight
					color="#bbb"
					intensity={0.8}
				/>
				<directionalLight
					color="#ef7800"
					intensity={1.2}
					position={[ 1, 10, -2 ]}
					castShadow={true}
					shadow-mapSize-width={1024}
					shadow-mapSize-height={1024}
					shadow-camera-far={30}
					shadow-camera-left={-10}
					shadow-camera-right={10}
					shadow-camera-top={10}
					shadow-camera-bottom={-10}
				/>
				<Selection>
					<EffectComposer autoClear={false}>
						<SelectiveBloom
							intensity={2}
							luminanceThreshold={0.05}
							luminanceSmoothing={0}
							lights={[
								new AmbientLight(0xbbbbbb, 0.8)
							]}
							height={480}
						/>
						{pixelationActive && <Pixelation granularity={granularity}/>}
						{scanlineActive && <Scanline density={scanlineDensity}/>}
						{noiseActive && <Noise premultiply={true}/>}
						{dotsActive && (
							<DotScreen
								angle={dotsAngle}
								scale={dotsScale}
							/>
						)}
						{(chromActive && !pixelationActive) && (
							<ChromaticAberration
								offset={[ 0.005 * chromIntensity, 0.002 * chromIntensity ]}
							/>
						)}
						{glitchActive && <Glitch/>}
					</EffectComposer>
					<Select enabled={false}>
						<Lanes
							number={racers.length}
							radius={radius}
							position={[ 0, -radius, 0 ]}
							status={raceStatus}
						/>
					</Select>
					<TrafficLight
						position={[ 0, 4, 0 ]}
						rotation={[ 0, 0, Math.PI / 2 ]}
						scale={2}
						setStatus={setRaceStatus}
						status={raceStatus}
					/>
					<Suspense>
						{racers.map(({ distance, traits, vin }, i) => (
							<Racer
								key={i}
								label={vin}
								radius={radius}
								position={[ 2 * i - racers.length + 1, 0, 0 ]}
								rotation={max === min
									? 0
									: Math.PI / 7 * (((distance - min) / (max - min)) - 0.5)
								}
								traits={traits}
								isRacing={raceStatus === 1}
							/>
						))}
					</Suspense>
				</Selection>
				{children}
			</Canvas>
		</Suspense>
	)
}
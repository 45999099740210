import { useMemo, useState } from "react";
import styled from "styled-components";

import { useTimer } from "../../Hooks";

import { clamp, pendingTime, RACING_BLOCKS } from "../../utils";

import { Flex } from "../Styles";
import { Racer, RacerNumber, RacerFinish } from "./Racer2D";
import { TrafficLight } from "../RacePage/TrafficLight";

import fullScreenIcon from "../../full-screen.svg";

const Container = styled(Flex).attrs(({ messageOnly = false }) => ({
	column: true,
	justify: messageOnly ? "center": "flex-start",
	align: messageOnly ? "center": "flex-start"
}))`
	position: absolute;
  background-color: rgba(0,0,0,0.6);
	border: 5px solid #ffff00;
  border-radius: 12px;
  width: calc(100% - 80px);
	min-width: 660px;
	max-width: 1000px;
	height: ${({ footerActive }) => footerActive ? "calc(100vh - 300px)": "auto"};
	max-height: calc(100vh - 260px);
	margin-top: 60px;
	overflow: hidden;
	${({ messageOnly = false }) => messageOnly && `
		font-size: 40px;
		color: white;
		font-family: LLPIXEL3, sans-serif;
		text-shadow: 1px 1px 10px white;
	`}
	${({ minimized = false, messageOnly = false }) => minimized && !messageOnly && `
		position: fixed;
		left: 65px;
		bottom: 65px;
		width: 300px;
		min-width: auto;
		height: auto;
		min-height: auto;
	`}
	pointer-events: all;
	z-index: 2;
`;

const Header = styled(Flex)`
	width: 100%;
	height: 190px;
	border-bottom: 3px solid gray;
`;
const RaceHeader = styled(Flex).attrs(() => ({
	column: true
}))`
	width: calc(100% - 100px);
	padding: 20px 40px;
`;
const TimeLeft = styled.div`
	font-family: LLPIXEL3, sans-serif;
	font-size: 60px;
	margin-bottom: 20px;
	color: yellow;
	text-shadow: 1px 1px 10px yellow;

	${({ minimized }) => minimized && `
		font-size: 30px;
		margin-bottom: 0px;
		margin-left: 10px;
	`}

	& > span {
		color: white;
		text-shadow: 1px 1px 10px white;
	}
`;
const RaceInfo = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
	color: white;
	text-transform: uppercase;
	text-shadow: 1px 1px 10px white;
`;
const RaceInfoColumn = styled(Flex).attrs(() => ({
	column: true
}))`
	min-width: 160px;
	& > div:last-of-type {
		margin-top: 15px;
	}
`;

const RaceButton = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	padding: 15px;
	border-radius: 10px;
	border: 2px solid white;
	color: white;
	text-shadow: 1px 1px 10px white;
	font-weight: 700;
	cursor: pointer;

	&:active {
		opacity: 0.6;
	}
	${({ disabled }) => disabled && `
		opacity: 0.6;
		pointer-events: none;
	`}

	& > img {
		margin-left: 15px;
		filter: invert() drop-shadow(1px 1px 5px rgba(255, 255, 255, 0.7));
		width: 20px;
	}
`;

const Body = styled(Flex).attrs(() => ({
	column: true
}))`
	width: 100%;
	height: ${({ footerActive }) => footerActive ? "calc(100vh - 600px)": "calc(100vh - 480px)"};
	min-height: 200px;
	overflow-y: auto;
	${({ minimized }) => minimized && `
		min-height: 190px;
		height: auto;
	`}
`;

const JoinRaceFooter = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 100%;
	min-height: 25px;
	border-top: 3px solid gray;
`;
const JoinButtonContainer = styled(Flex).attrs(() => ({
	justify: "flex-end",
	align: "center"
}))`
	width: calc(100% - 200px);
	height: 100px;
	padding-right: 40px;
	${({ float = false }) => float && `
		width: 200px;
		position: fixed;
		bottom: 380px;
		left: 11px;
	`}
`;

const MinimizeButton = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	width: 40px;
	height: 40px;
	background-color: rgb(60, 60, 60);
	color: white;
	cursor: pointer;
	align-self: flex-start;
	& > img {
		width: 20px;
		height: 20px;
		filter: invert();
	}
`;

const headerMap = {
	CANCELED: "COPS! RUN!",
	EXPIRED: "TOO SLOW, BUCKO",
	CLOSED: "THAT'S SOME JUICY XP"
}

export function RaceBlock({ raceId, racers, raceStatus, openJoinMenu, minimized, setMinimized }) {
	const [ currentBlock ] = useState(0);

	const [{ isValid, maxLevel, startBlock, createTime, type, isLoading }] = useState({
		isValid: true,
		maxLevel: 2,
		startBlock: 0,
		createTime: Date.now(),
		type: "FRIENDLY",
		isLoading: false
	});
	const status = raceStatus;

	const shouldCount = useMemo(() => status === "OPEN", [ status ]);
	const timeLeft = useTimer(pendingTime + createTime, shouldCount, { places: 3 });

	const footerActive = status === "OPEN" && racers.length < 7;

	const blocksRaced = useMemo(() => (
		clamp(currentBlock - startBlock, 0, RACING_BLOCKS)
	), [ currentBlock, startBlock ]);

	const maxDistanceTraveled = useMemo(() => (
		racers.reduce((max, { distance = 0 }) => distance > max ? distance: max, 0) || Infinity
	), [ racers ]);

  return !isValid && !isLoading
		?	<Container messageOnly={true}>INVALID RACE!</Container>
		: minimized
			? (
				<Container
					footerActive={false}
					minimized={true}>
					{isLoading
						? "LOADING RACE..."
						: <>
							<Flex justify="space-between" style={{ width: "100%", borderBottom: "1px solid gray" }}>
								<TimeLeft minimized={minimized}>
									{headerMap[ status ]
										? headerMap[ status ]
										: shouldCount
											? timeLeft
											: <>BLOCKS: <span>{blocksRaced || 0}/{RACING_BLOCKS}</span></>
									}
								</TimeLeft>
								<MinimizeButton onClick={() => setMinimized(m => !m)}>
									<img src={fullScreenIcon} alt="M"/>
								</MinimizeButton>
							</Flex>
							<Body footerActive={false} minimized={true}>
								{racers.map(({ token_id, name, image_url = "/sign.png", distance = 0 }, i) => (
									<Racer
										key={token_id || i}
										number={i + 1}
										name={name}
										image={image_url}
										progress={distance / maxDistanceTraveled}
										minimized={true}
									/>
								))}
							</Body>
							{footerActive && (
								<JoinButtonContainer float={true}>
									<RaceButton onClick={openJoinMenu}>JOIN RACE</RaceButton>
								</JoinButtonContainer>
							)}
						</>
					}
				</Container>
			)
			: (
				<Container
					messageOnly={isLoading}
					footerActive={footerActive}>
					{isLoading
						? "LOADING RACE..."
						: (
							<>
								<Header>
									<RaceHeader>
										<Flex justify="space-between" style={{ width: "100%" }}>
											<TimeLeft>
												{headerMap[ status ]
													? headerMap[ status ]
													: shouldCount
														? timeLeft
														: <>BLOCKS: <span>{blocksRaced || 0}/{RACING_BLOCKS}</span></>
												}
											</TimeLeft>
											<MinimizeButton onClick={() => setMinimized(m => !m)}>
												<img src={fullScreenIcon} alt="M"/>
											</MinimizeButton>
										</Flex>
										<RaceInfo>
											<RaceInfoColumn>
												<div>
													STATUS: <strong>{(!isValid && !isLoading) ? "INVALID" :status}</strong>
												</div>
												<div>
													TYPE: <strong>{type}</strong>
												</div>
											</RaceInfoColumn>
											<RaceInfoColumn>
												<div>
													MAX LEVEL: <strong>{maxLevel}</strong>
												</div>
												<div>
													RACE #: <strong>{raceId}</strong>
												</div>
											</RaceInfoColumn>
											<RaceButton onClick={() => navigator.clipboard.writeText(window.location.href)}>
												<div>COPY RACE URL</div>
												<img
													src="/copy.svg"
													alt="C"
												/>
											</RaceButton>
										</RaceInfo>
									</RaceHeader>
									<TrafficLight status={status}/>
									<MinimizeButton onClick={() => setMinimized(m => !m)}>M</MinimizeButton>
								</Header>
								<Body footerActive={footerActive}>
									{racers.map(({ token_id, name, image_url = "/sign.png", distance = 0 }, i) => (
										<Racer
											key={token_id || i}
											number={i + 1}
											name={name}
											image={image_url}
											progress={distance / maxDistanceTraveled}
										/>
									))}
								</Body>
								<JoinRaceFooter>
									{footerActive && (
										<>
											{/* The + sign sits low so gotta bump it up with padding */}
											<RacerNumber style={{ paddingBottom: "15px" }}>+</RacerNumber>
											<JoinButtonContainer>
												<RaceButton onClick={openJoinMenu}>JOIN RACE</RaceButton>
											</JoinButtonContainer>
											<RacerFinish checkered={false}/>
										</>
									)}
								</JoinRaceFooter>
							</>
						)
					}
				</Container>
			)
}

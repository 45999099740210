import styled from "styled-components";

import { Flex } from "../Styles";

const Container = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100px;
	padding: 20px;
	border: 5px solid black;
	background-color: white;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	z-index: 2;
`;

const Button = styled.div`
  cursor: pointer;
	font-family: ArialBlack;
	transform: scaleY(1.3);
	${({ disabled }) => disabled && `
		opacity: 0.3;
		pointer-events: none;
	`}
`;

const Right = styled(Flex).attrs(() => ({
	align: "center"
}))``;

export function PageControl({ onBack, onNext, canProgress, additionalButton, replaceNextButton }) {
  return (
    <Container>
      {onBack 
				? <Button onClick={onBack}>&lt; BACK</Button> 
				: <div></div>
			}
			<Right>
				{additionalButton}
				{replaceNextButton
					? replaceNextButton
					: onNext
						? <Button
								disabled={!canProgress}
								onClick={() => canProgress && onNext()}
							>
								NEXT &gt;
							</Button>
						: <div></div>
				}
			</Right>
    </Container>
  );
}

import styled from "styled-components";

import { padNumber } from "../utils";

import { Flex } from "./Styles";

const Container = styled.div`
	width: 100%;
	min-width: 600px;
	max-width: 800px;
	max-height: 400px;
	margin: 20px;
  background-color: rgba(0,0,0,0.4);
  border-radius: 12px;
	border: 5px solid yellow;
`;

const Inner = styled.div`
  border: 5px solid black;
  border-radius: 7px;
`;

const Header = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
  height: 100px;
  padding-bottom: 8px;
  text-align: center;
	font-family: LLPIXEL3, sans-serif;
  font-size: 60px;
  color: yellow;
  text-shadow: 1px 1px 10px yellow;
  letter-spacing: 2px;
`;

const Content = styled.div`
  min-height: 100px;
	max-height: 230px;
	margin-bottom: 10px;
	overflow: hidden auto;
	border-bottom: 2px solid gray;
`;

const RaceTable = styled.table`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  text-shadow: 1px 1px 10px white;
	& td {
		width: 18%;
		&:nth-of-type(2),
		&:nth-of-type(3),
		&:nth-of-type(5) {
			width: 21.3%;
		}
	}
`;

const HeaderRow = styled.tr`
	width: 100%;
	& > td {
		border-bottom: 1px solid gray;
	}
`;
const Row = styled.tr`
	width: 100%;
	cursor: pointer;
	&:hover {
		background-color: rgba(0,0,0,0.2);
	}
	& > td {
		border-bottom: 1px solid gray;
	}
`;
const Column = styled.td`
  padding: 12px;
  text-transform: uppercase;
`;

export function StatsBlock({ title, status = false, races = [] }) {
  return (
		<Container>
			<Inner>
				<Header>{title}</Header>
				<RaceTable cellSpacing={0}>
					<tbody>
						<HeaderRow>
							<Column>RACE #</Column>
							<Column>TYPE</Column>
							<Column>MAX LEVEL</Column>
							<Column># RACERS</Column>
							<Column>{status ? "STATUS": "BLOCKS LEFT"}</Column>
						</HeaderRow>
					</tbody>
				</RaceTable>
				<Content>
					<RaceTable cellSpacing={0}>
						<tbody>
							{races.map(({ id, type = "FRIENDLY", maxLevel, racers = [], status: raceStatus = null, blocksLeft = "-" }) => (
								<Row key={id} onClick={() => window.open(`/race/${id}`, "_blank")}>
									<Column>{padNumber(id, 4)}</Column>
									<Column>{type}</Column>
									<Column>{maxLevel}</Column>
									<Column>{racers.length}</Column>
									<Column>{raceStatus || blocksLeft}</Column>
								</Row>
							))}
						</tbody>
					</RaceTable>
				</Content>
			</Inner>
		</Container>
  );
}

import { useEffect, useRef, useState } from "react";
import { BoxBufferGeometry, ClampToEdgeWrapping, CylinderBufferGeometry, DoubleSide, LoopOnce, LoopPingPong, MathUtils, MeshBasicMaterial, MeshPhysicalMaterial, RepeatWrapping, SphereBufferGeometry } from "three";
import { useFrame } from "@react-three/fiber";
import { CatmullRomLine, Instance, Instances, Plane, Sphere, Torus, useGLTF, useTexture } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";

import { prefix3D } from "../../utils";

import { useGLTFAnimation } from "../useGLTFAnimation";
import { cloneMaterialWithProps, sharedMaterials } from "../../shaders/materials";

import { Dona } from "../../Components/DonaModel";

function Baby() {
	return (
		<Dona
			position={[ 0, 1.75, -0.2 ]}
			scale={0.35}
			paintProps={{
				color: "#aaaaaa"
			}}
		/>
	)
}

function Booty({ speed }) {
	const pivot = useRef();

	const { nodes, materials } = useGLTF(prefix3D + "booty_compressed.glb");

	useFrame(({ clock }) => {
		if (!speed) {
			pivot.current && (pivot.current.rotation.z = MathUtils.lerp(pivot.current.rotation.z, 0, 0.05));
			return;
		}
		pivot.current.rotation.z = Math.PI / 3 * (1 + Math.sin(1.5 * clock.getElapsedTime()));
	})

	return (
		<group
			position={[ 0, 1.75, -0.5 ]}
			scale={0.4}
			dispose={null}>
			<group
				ref={pivot}
				position={[-1, 1.6, 0]}>
				<group position={[1, 0, 0]} rotation={[-Math.PI, 0, -Math.PI]}>
					<mesh geometry={nodes.Cube001_1.geometry} material={materials.metal} />
					<mesh geometry={nodes.Cube001_2.geometry} material={materials.wood} />
					<group position={[ 1, 0, 0 ]}>
						<group position={[ -0.135, 0.8, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
						</group>
						<group position={[ 0, 0.1, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
						</group>
					</group>
					<group
						position={[ -1, 0, 0 ]}
						scale={[ -1, 1, 1 ]}>
						<group position={[ -0.135, 0.8, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
						</group>
						<group position={[ 0, 0.1, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
						</group>
					</group>
					<group position={[ 0, 0, -2 ]}>
						<group rotation={[ 0, Math.PI / 2, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, -0.77]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, 0.77]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, -0.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, 0.9]} />
						</group>
					</group>
					<group position={[ 0, 0, 2 ]}>
						<group rotation={[ 0, -Math.PI / 2, 0 ]}>
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, -0.77]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, 0.77]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, -0.9]} />
							<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, 0.9]} />
						</group>
					</group>
				</group>
			</group>
			<mesh geometry={nodes.Cube.geometry} material={materials.metal} />
			<mesh geometry={nodes.Cube_1.geometry} material={materials.wood} />
			<group position={[ 1, 0, 0 ]}>
				<group position={[ 0, 1.5, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
				<group position={[ 0, 0.8, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
				<group position={[ 0, 0.1, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
			</group>
			<group
				position={[ -1, 0, 0 ]}
				scale={[ -1, 1, 1 ]}>
				<group position={[ 0, 1.5, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
				<group position={[ 0, 0.8, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
				<group position={[ 0, 0.1, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, 0]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0, -1.9]} />
				</group>
			</group>
			<group position={[ 0, 0, -2 ]}>
				<group rotation={[ 0, Math.PI / 2, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 1.5, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 1.5, 0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, 0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, 0.9]} />
				</group>
			</group>
			<group position={[ 0, 0, 2 ]}>
				<group rotation={[ 0, -Math.PI / 2, 0 ]}>
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 1.5, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 1.5, 0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.8, 0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, -0.9]} />
					<mesh geometry={nodes.bolt.geometry} material={materials.metal} position={[0, 0.1, 0.9]} />
				</group>
			</group>
		</group>
	)
}

const bowMat = new MeshPhysicalMaterial({
	color: "#BC86A5",
	roughness: 0.4,
	metalness: 0.2,
	reflectivity: 0.2,
	clearcoat: 0,
	clearcoatRoughness: 0,
	thickness: 0.5,
	side: DoubleSide
});
function Bow() {
	const { nodes } = useGLTF(prefix3D + "bow_compressed.glb");

	return (
		<group
			position={[ -0.5, 1.6, 0.75 ]}
			rotation={[ Math.PI / 6, 0, Math.PI / 30 ]}
			dispose={null}>
			<mesh
				geometry={nodes.gift_cap1.geometry}
				material={bowMat}
				material-specular
				rotation={[Math.PI / 2, 0, 0.32]}
				scale={0.08}
			/>
		</group>
	)
}

function BrandAmabassador() {
	const skechersMap = useTexture(prefix3D + "brand_ambassador.png");

	const skechersMat = (
		<meshBasicMaterial
			attach="material"
			map={skechersMap}
			transparent={true}
			alphaTest={0.01}
		/>
	);

	return (
		<group position={[ 0, 0.8, 0.1 ]}>
			<Plane
				args={[ 2, 0.5 ]}
				position={[ 0.95, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}>
				{skechersMat}
			</Plane>
			<Plane
				args={[ 2, 0.5 ]}
				position={[ -0.95, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, 0 ]}>
				{skechersMat}
			</Plane>
		</group>
	)
}

function Chair() {
	const { nodes, materials } = useGLTF(prefix3D + "chair_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, 0 ]}
			scale={1.4}>
			<group dispose={null}>
				<mesh geometry={nodes.supports.geometry} material={materials.metal} />
				<mesh geometry={nodes.seat.geometry} material={materials.bodyMat} />
				<mesh geometry={nodes.legs.geometry} material={materials.metal} />
			</group>
		</group>
	)
}

const ropes = [
	[
		[ -0.4, 0, 0.8 ],
		[ -0.075, 0.25, 0.8 ],
		[ 0, 0.28, 0.8 ],
		[ 0.075, 0.25, 0.8 ],
		[ 0.4, 0, 0.8 ]
	],
	[
		[ -0.4, 0, 0 ],
		[ -0.25, 0.25, 0 ],
		[ 0, 0.36, 0 ],
		[ 0.25, 0.25, 0 ],
		[ 0.4, 0, 0 ]
	]
]
function ChristmasTree() {
	const { nodes, materials } = useGLTF(prefix3D + "christmas_compressed.glb");

	useEffect(() => {
		if (!materials || !materials[ "Material.001" ]) return;

		const mat = materials[ "Material.001" ];
		mat.map.wrapS = mat.map.wrapT = RepeatWrapping;
		mat.map.repeat.set(2, 4);
		mat.map.needsUpdate = true;
	}, [ materials ]);

	return (
		<group
			position={[ 0, 1.75, -0.5 ]}
			scale={1.25}
			dispose={null}>
			<mesh
				geometry={nodes.Cylinder.geometry}
				material={materials['Material.002']}
				material-roughness={1}
				position={[0, 0.18, 0]}
				rotation={[-0.06, 0, 0]}
			/>
			<mesh
				geometry={nodes.Sphere.geometry}
				material={materials['Material.001']}
				material-roughness={1}
				position={[0, 0.12, 0.55]}
				scale={[1.21, 0.77, 1]}>
			</mesh>
			{ropes.map((pts, i) => (
				<CatmullRomLine
					key={i}
					points={pts}
					segments={20}
					color="#dddddd"
				/>
			))}
		</group>
	)
}

function Coffin({ speed }) {
	const lidPivot = useRef();
	const startTime = useRef();
	const { nodes, materials } = useGLTF(prefix3D + "coffin_compressed.glb");

	useEffect(() => {
		if (speed) return;

		startTime.current = undefined;
	}, [ speed ]);

	useFrame(({ clock }) => {
		if (!speed) {
			lidPivot.current.rotation.z = MathUtils.lerp(lidPivot.current.rotation.z, 0, 0.05);
			if (Math.abs(lidPivot.current.rotation.z) < 0.001) lidPivot.current.rotation.z = 0;
			return;
		}
		const time = clock.getElapsedTime();
		startTime.current = startTime.current || time;
		lidPivot.current.rotation.z = -Math.PI / 3 * (1 + Math.sin(3 * (time - startTime.current)));
	});

	return (
		<group
			position={[ 0, 1.75, -0.75 ]}
			scale={0.45}
			dispose={null}>
			<group
				ref={lidPivot}
				position={[1, 1.1, 0]}>
				<mesh geometry={nodes.lid.geometry} material={materials['Material.001']} position={[-1, 0, 0]} />
			</group>
			<mesh geometry={nodes.base.geometry} material={materials['Material.001']} />
			<mesh geometry={nodes.interior.geometry} material={materials.interior} />
			<mesh geometry={nodes.handle_front.geometry} material={materials.metal} position={[0, 0.78, 3]} rotation={[0, 0, -Math.PI / 2]} scale={[0.5, 1, 0.5]} />
			<mesh geometry={nodes.handle_back.geometry} material={materials.metal} position={[0, 0.78, -3]} rotation={[0, 0, -Math.PI / 2]} scale={[0.5, 1, 0.5]} />
			<mesh geometry={nodes.handle_right.geometry} material={materials.metal} position={[0.95, 0.78, 0]} rotation={[-Math.PI / 2, 1.57, 0]} scale={[0.5, 1, 0.5]} />
			<mesh geometry={nodes.handle_left.geometry} material={materials.metal} position={[-0.95, 0.78, 0]} rotation={[Math.PI / 2, -1.57, 0]} scale={[0.5, 1, 0.5]} />
		</group>
	)
}

function ConeHawk() {
	const { nodes, materials } = useGLTF(prefix3D + "traffic_cone_compressed.glb");

	return (
		<group position={[ 0, 1.75, 0.2 ]}>
			<group
				position={[ 0, 0, 0 ]}
				scale={0.02}
				dispose={null}>
				<mesh
					geometry={nodes.traffic_cone.geometry}
					material={materials.initialShadingGroup}
					rotation={[Math.PI / 2, 0, 0]}
				/>
			</group>
			<group
				position={[ 0, 0, -0.75 ]}
				scale={0.02}
				dispose={null}>
				<mesh
					geometry={nodes.traffic_cone.geometry}
					material={materials.initialShadingGroup}
					rotation={[Math.PI / 2, 0, 0]}
				/>
			</group>
			<group
				position={[ 0, 0, -1.5 ]}
				scale={0.02}
				dispose={null}>
				<mesh
					geometry={nodes.traffic_cone.geometry}
					material={materials.initialShadingGroup}
					rotation={[Math.PI / 2, 0, 0]}
				/>
			</group>
		</group>
	)
}

function Copter({ speed, moveDona }) {
	const pivot = useRef();
	const { nodes, materials } = useGLTF(prefix3D + "copter_compressed.glb");

	useFrame(({ clock }, delta) => {
		moveDona && moveDona(({ dona, startPos }) => {
			if (!dona) return;
			dona.position.y = MathUtils.lerp(
				dona.position.y,
				startPos[1] + (speed ? 0.3 + 0.3 * (1.5 + Math.sin(2 * clock.getElapsedTime())): 0),
				0.05
			);
		});
		if (!speed) return;
		pivot.current.rotation.y += 15 * delta;
	});

	return (
		<group
			position={[ 0, 1.7, 0 ]}
			scale={0.5}
			dispose={null}>
			<group ref={pivot}>
				<mesh geometry={nodes.static_rotor.geometry} material={materials.carbon} rotation={[Math.PI / 2, 0, 0]} />
				<mesh geometry={nodes.chassis.geometry} material={materials.metal} rotation={[Math.PI / 2, 0, 0]} />
				<mesh geometry={nodes.static_rotor001.geometry} material={materials.yellow} rotation={[Math.PI / 2, 0, 0]} />
			</group>
		</group>
	)
}

function Couch() {
	const { nodes, materials } = useGLTF(prefix3D + "couch_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.7 ]}
			rotation={[ 0, Math.PI / 2, 0 ]}
			dispose={null}>
			<group
				position={[0, -0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.012}>
				<mesh geometry={nodes.Arm_Rests_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Back_Cushions_L_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Back_Cushions_R_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Back_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Butt_Cushions_L_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Butt_Cushions_R_low.geometry} material={materials.lambert1} />
				<mesh geometry={nodes.Seat_low.geometry} material={materials.lambert1} />
			</group>
		</group>
	)
}

function CrimeScene() {
	const { nodes, materials } = useGLTF(prefix3D + "crime_scene_compressed.glb");

	useEffect(() => {
		if (!materials || !materials.mat) return;

		materials.mat.map.anisotropy = 8;
		materials.mat.map.needsUpdate = true;
		materials.mat.needsUpdate = true;
	}, [ materials ]);
	
	return (
		<group dispose={null}>
			<mesh geometry={nodes.Cylinder001.geometry} material={materials.mat} />
		</group>
	)
}

function Danglers() {
	const { nodes, materials } = useGLTF(prefix3D + "danglers_compressed.glb");

	return (
		<group
			position={[ 0, 0.1, 2.55 ]}
			scale={0.1}
			dispose={null}>
			<mesh geometry={nodes.Sphere.geometry} material={materials.gold} material-roughness={0.15}/>
		</group>
	)
}

function DontLook({ speed }) {
	const lidPivot = useRef();
	const startTime = useRef();
		
	const { nodes, materials } = useGLTF(prefix3D + "ark_compressed.glb");

	useEffect(() => {
		if (speed) return;

		startTime.current = undefined;
	}, [ speed ]);

	useFrame(({ clock }) => {
		if (!speed) {
			lidPivot.current.rotation.z = MathUtils.lerp(lidPivot.current.rotation.z, 0, 0.05);
			if (Math.abs(lidPivot.current.rotation.z) < 0.001) lidPivot.current.rotation.z = 0;
			return;
		}
		const time = clock.getElapsedTime();
		startTime.current = startTime.current || time;
		const progress = ((2 * (time - startTime.current)) % 12);
		if (progress > 9) lidPivot.current.rotation.z = 0;
		else if (progress < 3) lidPivot.current.rotation.z = -Math.PI / 3 * (1 + Math.sin(-Math.PI / 2 + 2 * Math.PI * (progress / 6)));
		else if (progress < 6) lidPivot.current.rotation.z = -2 * Math.PI / 3;
		else lidPivot.current.rotation.z = -Math.PI / 3 * (1 + Math.sin(Math.PI / 2 + 2 * Math.PI * ((progress - 6) / 6)));
	});

  return (
		<group
			position={[ 0, 1.75, -0.6 ]}
			rotation={[ 0, Math.PI , 0 ]}
			scale={0.42}
			dispose={null}>
			<group ref={lidPivot} position={[1, 2, 0]}>
				<mesh geometry={nodes.lid.geometry} material={materials.gold} material-roughness={0.6} position={[-1, 0.1, 0]} />
				<mesh geometry={nodes.statue.geometry} material={materials.gold} position={[-1, 0.7, -1.46]} />
				<mesh geometry={nodes.statue.geometry} material={materials.gold} position={[-1, 0.7, 1.46]} rotation={[ 0, Math.PI, 0 ]}/>
			</group>
			<mesh geometry={nodes.base.geometry} material={materials.gold} />
			<Select enabled={!!speed}>
				<mesh
					position={[ 0, 1, 0 ]}>
					<boxGeometry args={[ 1.5, 2, 3.5 ]}/>
					<meshBasicMaterial color="#ffffaa"/>
				</mesh>
			</Select>
		</group>
  )
}

function EaglesNest() {
	const { nodes, materials } = useGLTF(prefix3D + "eagle_compressed.glb");

	const nestMap = useTexture(prefix3D + "twigs.jpg");

	useEffect(() => {
		if (!nestMap) return;

		nestMap.center.set(0.5, 0.5);
		nestMap.repeat.set(4, 2);
		nestMap.wrapS = nestMap.wrapT = RepeatWrapping;
		nestMap.flipY = false;
		nestMap.needsUpdate = true;
	}, [ nestMap ]);

	return (
		<group
			position={[ 0, 1.75, 0 ]}
			dispose={null}>
			<Torus
				args={[ 0.35, 0.385, 8, 32 ]}
				position={[ 0, 0.3, 0 ]}
				rotation={[ -Math.PI / 2, 0, 0 ]}
				scale={[ 1, 1, 0.8 ]}>
				<meshStandardMaterial map={nestMap}/>
			</Torus>
			<mesh
				geometry={nodes.eagle_Zremesher.geometry}
				material={materials.eagle_Zremesher}
				position={[ 0, 0.55, 0 ]}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.5}/>
		</group>
	)
}

function ETHFlag({ speed }) {
	const { nodes, materials } = useGLTF(prefix3D + "eth_flag.glb");

	useFrame(({ clock }) => {
		// if multiple flags are loaded, this probably sets the morph influences multiple times, maybe some way to optimize?
		if (!speed) {
			nodes.Plane.morphTargetInfluences[0] = MathUtils.lerp(nodes.Plane.morphTargetInfluences[0], 0, 0.05);
			nodes.Plane.morphTargetInfluences[1] = MathUtils.lerp(nodes.Plane.morphTargetInfluences[1], 0, 0.05);
			return;
		}
		nodes.Plane.morphTargetInfluences[0] = 0.5 + 0.5 * Math.sin(50 * clock.getElapsedTime());
		nodes.Plane.morphTargetInfluences[1] = 0.5 + 0.5 * Math.cos(50 * clock.getElapsedTime());
	});

	return (
		<group
			position={[ -0.8, 0.85, 1.75 ]}
			scale={0.8}
			dispose={null}>
			<mesh geometry={nodes.Cylinder.geometry} material={nodes.Cylinder.material} scale={[ 1, 0.7, 1 ]}/>
			<mesh
				name="Plane"
				geometry={nodes.Plane.geometry}
				material={materials.mat}
				morphTargetDictionary={nodes.Plane.morphTargetDictionary}
				morphTargetInfluences={nodes.Plane.morphTargetInfluences}
				position={[0, 1.08, 0]}
				rotation={[0, 0, -Math.PI / 2]}
				scale={[0.32, 0.8, 0.4]} />
		</group>
	)
}

function ForTheMob({ speed }) {
	const { nodes, materials } = useGLTF(prefix3D + "fedora_compressed.glb");

	useEffect(() => {
		if (!materials) return;
		materials.ribbon.map.center.set(1, 0.5);
		materials.ribbon.map.repeat.set(5, 1);
		materials.ribbon.map.wrapS = materials.ribbon.map.wrapT = ClampToEdgeWrapping;
	}, [ materials ]);

	useFrame(({ clock }) => {
		if (!speed) return;

		materials.ribbon.map.offset.set(2 * (1 + Math.sin(clock.getElapsedTime())), 0);
	});

	return (
		<group
			position={[ 0, 1.75, -0.2 ]}
			rotation={[ Math.PI / 20, 0, 0 ]}
			dispose={null}>
			<mesh
				geometry={nodes.Cinta_Sombrero.geometry}
				material={materials.ribbon}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.Cinta_Sombrero001.geometry}
				material={materials.metal}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.polySurface13.geometry}
				material={materials.hat}
				material-color="#030303"
				rotation={[Math.PI / 2, 0, 0]}
			/>
		</group>
	);
}

function Garbage() {
	const { nodes, materials } = useGLTF(prefix3D + "garbage_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.2 ]}
			rotation={[ 0, -Math.PI / 2, 0 ]}
			scale={[ 0.008, 0.007, 0.008 ]}
			dispose={null}>
			<mesh
				geometry={nodes.bag1.geometry}
				material={materials.garbagebags}
				material-metalness={0.8}
				material-roughness={0.2}
				position={[5, 9, 42]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh geometry={nodes.bag2.geometry} material={materials.garbagebags} position={[-13, 14, -45]} rotation={[Math.PI / 2, 0, 2.01]}/>
			<mesh geometry={nodes.bag1001.geometry} material={materials.garbagebags} position={[-95, 14, 5]} rotation={[Math.PI / 2, -0.65, 0]}/>
			<mesh geometry={nodes.bag2001.geometry} material={materials.garbagebags} position={[35, 32, -18]} rotation={[Math.PI / 2, -1.32, 2.01]}/>
			<mesh geometry={nodes.bag2002.geometry} material={materials.garbagebags} position={[-9, 91, -25]} rotation={[2.67, 0, 2.01]}/>
			<mesh geometry={nodes.bag2003.geometry} material={materials.garbagebags} position={[58, 14, 57]} rotation={[1.03, 0.01, 2.57]}/>
		</group>
	)
}

function GetInTheZone() {
	const { nodes, materials } = useGLTF(prefix3D + "zone_compressed.glb");

	return (
		<group dispose={null}>
			<mesh geometry={nodes.scoop.geometry} material={materials.black} position={[0, 1.1, 1.91]} scale={[ 1.1, 1, 1 ]}/>
			<mesh geometry={nodes.teeth.geometry} material={materials.black} position={[0, 0.88, 2.33]} rotation={[0.75, 0, 0]} />
			<mesh geometry={nodes.teeth.geometry} material={materials.black} position={[0, 0.88, 2.33]} rotation={[0.75, 0, 0]} scale={[ -1, 1, 1 ]}/>
			<mesh geometry={nodes.phish.geometry} material={materials.chrome} />
			<mesh geometry={nodes.phish.geometry} material={materials.chrome} scale={[ -1, 1, 1 ]}/>
			<mesh geometry={nodes.le_sign.geometry} material={materials.le_sign} material-transparent={true} position={[0.92, 0.43, 0.1]} />
			<mesh geometry={nodes.le_sign.geometry} material={materials.le_sign} position={[-0.92, 0.43, 0.1]} rotation={[ Math.PI / 100, 0, 0 ]} scale={[ -1, 1, -1 ]}/>
			<mesh geometry={nodes['4x4'].geometry} material={materials['4x4']} material-transparent={true} position={[0.88, 0.38, 2.2]} />
			<mesh geometry={nodes['4x4'].geometry} material={materials['4x4']} position={[-0.89, 0.38, 2.2]} rotation={[ 0, Math.PI / 5, 0 ]} scale={[ -1, 1, -1 ]}/>
			<mesh geometry={nodes.divots.geometry} material={materials.black} />
			<mesh geometry={nodes.divots.geometry} material={materials.black} scale={[ -1, 1, 1 ]}/>
		</group>
	)
}

function Godzilla() {
	const { nodes, materials } = useGLTF(prefix3D + "godzilla_compressed.glb");

	return (
		<group
			position={[ 0, 1.71, 0.35 ]}
			rotation={[ Math.PI / 60, 0, 0 ]}
			scale={0.18}
			dispose={null}>
			<mesh geometry={nodes.ZBrush_defualt_group000.geometry} material={materials.spikes} />
			<mesh geometry={nodes.ZBrush_defualt_group001.geometry} material={materials.skin} material-color="#101010"/>
			<mesh geometry={nodes.ZBrush_defualt_group002.geometry} material={materials.tongue} />
			<mesh geometry={nodes.Sphere001.geometry} material={materials.eyes} position={[0.03, 5.82, 0.93]} scale={0.36} />
			<mesh geometry={nodes.Sphere.geometry} material={materials.Material} position={[0.03, 5.82, 0.93]} scale={0.36} />
			<mesh geometry={nodes.Cube.geometry} material={materials.teeth} />
		</group>
	)
}

function GoStopSlowdown({ speed, ...props }) {
	const [ status, setStatus ] = useState(-1);

	const { nodes, materials } = useGLTF(prefix3D + "traffic_light_compressed.glb");

	useFrame(({ clock }) => {
		if (!speed) {
			if (status !== -1) setStatus(-1);
			return;
		}
		const newStatus = -1 + Math.floor((5 * clock.getElapsedTime()) % 3);
		if (newStatus !== status) setStatus(newStatus);
	});

	return (
		<group
			{...props}
			dispose={null}>
			<group position={[ 0, 1.8, 0 ]}>
				<mesh
					geometry={nodes.Cube.geometry}
					material={materials.black}
				/>
				<Select enabled={status === 0}>
					<mesh
						geometry={nodes.Cylinder.geometry}
						material={materials.yellow_light}
						material-color={status === 0 ? "#ffff00": "#7f7f00"}
						position={[0, 0.75, 0.1]}
					/>
				</Select>
				<Select enabled={status === -1}>
					<mesh
						geometry={nodes.Cylinder001.geometry}
						material={materials.red_light}
						material-color={status === -1 ? "#ff0000": "#7f0000"}
						position={[0, 1.23, 0.1]}
					/>
				</Select>
				<Select enabled={status === 1}>
					<mesh
						geometry={nodes.Cylinder002.geometry}
						material={materials.green_light}
						material-color={status === 1 ? "#00ff00": "#007f00"}
						position={[0, 0.28, 0.1]}
					/>
				</Select>
				<mesh
					geometry={nodes.Cylinder003.geometry}
					material={materials.black}
					position={[0, 0.28, 0.15]}
				/>
				<mesh
					geometry={nodes.Cylinder004.geometry}
					material={materials.black}
					position={[0, 0.75, 0.15]}
				/>
				<mesh
					geometry={nodes.Cylinder005.geometry}
					material={materials.black}
					position={[0, 1.23, 0.15]}
				/>
			</group>
		</group>
	);
}

function GreatPipes() {
	const { nodes, materials } = useGLTF(prefix3D + "pipes_compressed.glb");

	return (
		<group
			position={[ 0, 0.2, -0.6 ]}
			dispose={null}>
			<group
				position={[ -0.5, 0, 0 ]}
				scale={[ -0.5, 0.5, 0.5 ]}>
				<mesh geometry={nodes.Cylinder.geometry} material={materials.metal} />
				<mesh geometry={nodes.Cylinder001.geometry} material={materials.metal} position={[0.12, 0, -0.39]} rotation={[0, 0.1, 0]} />
			</group>
			<group
				position={[ 0.5, 0, 0 ]}
				scale={0.5}>
				<mesh geometry={nodes.Cylinder.geometry} material={materials.metal} />
				<mesh geometry={nodes.Cylinder001.geometry} material={materials.metal} position={[0.12, 0, -0.39]} rotation={[0, 0.1, 0]} />
			</group>
		</group>
	)
}

function IWasntCheap() {
	const { nodes, materials } = useGLTF(prefix3D + "grill_compressed.glb");

	return (
		<group position={[ 0, 0.69, 2.425 ]} dispose={null}>
			<mesh geometry={nodes.grill.geometry} material={materials.grillMat} material-roughness={0.3} scale={0.01} />
			<mesh geometry={nodes.grill.geometry} material={materials.grillMat} scale={[ -0.01, 0.01, 0.01 ]} />
		</group>
	)
}

function Listeners({ speed }) {
	const group = useRef();
	const { nodes, materials } = useGLTF(prefix3D + "ears_compressed.glb");

	useFrame(({ clock }) => {
		if (!speed) return;
		group.current.rotation.x = Math.PI / 8 * Math.sin(2 * clock.getElapsedTime());
	});

	return (
		<group
			ref={group}
			position={[ 0, 1.75, 0 ]}
			scale={0.35}
			dispose={null}>
			<mesh geometry={nodes.Cube.geometry} material={materials.pink} />
			<mesh geometry={nodes.Cube001.geometry} material={materials.white} />
			<mesh geometry={nodes.Cube002.geometry} material={materials.pink} />
			<mesh geometry={nodes.Cube003.geometry} material={materials.white} />
		</group>
	)
}

function MarcOrnstein() {
	const { nodes, materials } = useGLTF(prefix3D + "orn_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.3 ]}
			scale={[ 0.75, 1, 1 ]}>
			<CatmullRomLine
				points={[
					[ -0.7, 0, 0 ],
					[ -0.54, 0.44, 0 ],
					[ 0, 0.48, 0 ],
					[ 0.54, 0.44, 0 ],
					[ 0.7, 0, 0 ]
				]}
				segments={20}
				color="blue"
			/>
			<group
				position={[ 0, 0.02, 0 ]}
				scale={0.02}
				dispose={null}>
				<mesh geometry={nodes.WoodenBoat.geometry} material={materials.IND_WoodenBoat_01_SM} rotation={[Math.PI / 2, 0, 0]} scale={0.5} />
			</group>
		</group>
	)
}

function NewKayak() {
	const { nodes, materials } = useGLTF(prefix3D + "kayak_compressed.glb");

	return (
		<group
			position={[ 0, 1.95, -0.1 ]}
			rotation={[ 0, Math.PI, 0 ]}
			dispose={null}>
      <group
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <group
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <group
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.polySurface3.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.polySurface4.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.polySurface2.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCylinder1.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCylinder2.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCube2.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCube3.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCube5.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCylinder3.geometry}
				material={materials.lambert2}
				position={[0.01, 0.01, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
      <mesh
				geometry={nodes.pCylinder5.geometry}
				material={materials.lambert2}
				position={[-0.01, -0.14, 0.17]}
				rotation={[1.48, 0.06, -1.44]}
				scale={[0.7, 1, 1]}
			/>
    </group>
	)
}

const laserMat = new MeshBasicMaterial({ color: "#ffffff" });
const glowMat = new MeshBasicMaterial({
	color: "#ff0000",
	opacity: 0.7,
	transparent: true
});
function NightRider({ speed }) {
	const dir = useRef(1);
	const [ litIndex, setLitIndex ] = useState(-1);

	useEffect(() => {
		if (!speed) {
			setLitIndex(-1);
			return;
		}

		setLitIndex(0);
		const i = setInterval(() => {
			setLitIndex(l => {
				if (dir.current === 1) {
					if (l === 4) dir.current = -1;
				}
				else {
					if (l === 0) dir.current = 1;
				}
				return l + dir.current;
			});
		}, 100);

		return () => clearInterval(i);
	}, [ speed ]);

	return (
		<group position={[ 0, 0.78, 2.4 ]}>
			{Array.from({ length: 5 }, (_, i) => (
				<Select
					key={i}
					position={[ -0.32 + 0.16 * i, 0, 0 ]}
					enabled={i === litIndex}>
					<Sphere
						args={[ 0.015 ]}
						material={laserMat}
					/>
					<Sphere
						args={[ i === litIndex ? 0.08: 0.05 ]}
						material={glowMat}
					/>
				</Select>
			))}
		</group>
	)
}

function PartyTime({ speed }) {
	const tongue = useRef();
	const anim = useRef();

	const { nodes: hatNodes, materials: hatMats } = useGLTF(prefix3D + "party_hat_compressed.glb");
	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "party_tongue_compressed.glb",
		groupRef: tongue,
		animRef: anim,
		loop: LoopPingPong,
		repetitions: 2
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.timeScale = 2;
		animation.reset().play();
		const i = setInterval(() => {
			animation.reset().play();
		}, 2500);
		return () => {
			animation.halt(1.5);
			clearInterval(i);
		}
	}, [ speed ]);

	return (
		<group>
			<group
				position={[ 0, 1.75, 0 ]}
				scale={0.6}
				dispose={null}>
				<mesh geometry={hatNodes.Cone.geometry} material={hatMats.hat} position={[0, 1, 0]} scale={[0.8, 1, 0.8]} />
				<mesh geometry={hatNodes.Cube.geometry} material={hatMats.yellow} position={[0, 2, 0]} rotation={[0.59, 0.45, -0.28]} scale={[0.01, 0.1, 0.01]} />
			</group>
			<group
				ref={tongue}
				position={[ 0, 0.4, 2.35 ]}
				scale={[ 0.75, 0.6, 0.6 ]}
				dispose={null}>
				<group name="Scene">
					<group name="Armature" position={[0, 0, 0.5]} rotation={[Math.PI / 2, 0, 0]}>
						<primitive object={nodes.Bone} />
						<skinnedMesh name="Cube" geometry={nodes.Cube.geometry} material={materials.gold} material-roughness={0} skeleton={nodes.Cube.skeleton} />
					</group>
					<mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.paper} />
				</group>
			</group>
		</group>
	)
}

function PoliceLights({ speed }) {
	const { nodes, materials } = useGLTF(prefix3D + "police_lights_compressed.glb");

	useEffect(() => {
		if (!materials) return;

		materials.red_light.opacity = materials.blue_light.opacity = speed ? 0.15: 0.85;
	}, [ materials, speed ]);

	useFrame(({ clock }) => {
		if (!speed) return;
		materials.red_light.opacity = 0.1 + 0.375 * (Math.sin(30 * clock.getElapsedTime()) + 1);
		materials.blue_light.opacity = 1 - materials.red_light.opacity;
	});

	return (
		<group
			position={[ 0, 1.625, 0.5 ]}
			rotation={[ Math.PI / 30, 0, 0 ]}
			dispose={null}>
			<mesh
				geometry={nodes.Cube.geometry}
				material={materials.middle_cap}
			/>
			<Select enabled={!!speed}>
				<mesh
					position={[ 0.001, 0, 0 ]}
					geometry={nodes.Cube001.geometry}
					material={materials.red_light}
					material-opacity={0.85}
					material-transparent={true}
				/>
				<mesh
					geometry={nodes.Cube002.geometry}
					material={materials.blue_light}
					material-opacity={0.85}
					material-transparent={true}
				/>
			</Select>
			<mesh
				geometry={nodes.Cube003.geometry}
				material={materials.metal}
			/>
			<mesh
				geometry={nodes.roof_lights.geometry}
				material={materials['roof lights']}
				position={[0, 0.23, 0]}
				scale={0.82}
			/>
			<mesh
				geometry={nodes.Cylinder.geometry}
				material={materials.metal}
			/>
			<mesh
				geometry={nodes.Cylinder001.geometry}
				material={materials.metal}
				position={[0.45, 0.08, 0]}
			/>
		</group>
	)
}

function PopupTent() {
	const { nodes, materials } = useGLTF(prefix3D + "popup2_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.4 ]}
			scale={0.6}
			dispose={null}>
			<mesh geometry={nodes.Cube.geometry} material={materials.tent} position={[0, 1.34, 0]} />
			<mesh geometry={nodes.Cube001.geometry} material={materials.Material} position={[0.97, 0, -0.97]} scale={[1, 0.75, 1]} />
		</group>
	)
}

function Punk() {
	const { nodes, materials } = useGLTF(prefix3D + "punk_compressed.glb");

  return (
    <group
			position={[ 0, 0.5, -2.5 ]}
			scale={0.8}
			dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} />
    </group>
  )
}

function QuackAnim({ speed }) {
	const group = useRef();
	const anim = useRef();

	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "quack_compressed.glb",
		groupRef: group,
		animRef: anim,
		loop: LoopOnce,
		repetitions: 1
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.timeScale = 1;
		animation.reset().play();
		const i = setInterval(() => {
			animation.reset().play();
		}, 1500);
		return () => {
			animation.halt(1);
			clearInterval(i);
		}
	}, [ speed ]);

	return (
		<group
			ref={group}
			position={[ 0, 0.68, 2.5 ]}
			scale={[ 1.5, 1.25, 1.25 ]}
			dispose={null}>
			<group name="Scene">
				<group name="Armature" position={[0, 0, -0.3]} rotation={[Math.PI / 2, 0, 0]} scale={0.3}>
					<primitive object={nodes.Bone} />
					<group name="Duck001">
						<skinnedMesh name="Duck001_1" geometry={nodes.Duck001_1.geometry} material={materials.Material} skeleton={nodes.Duck001_1.skeleton} />
						<skinnedMesh name="Duck001_2" geometry={nodes.Duck001_2.geometry} material={materials['Material.003']} skeleton={nodes.Duck001_2.skeleton} />
					</group>
				</group>
			</group>
		</group>
	)
}

function Raygun({ speed }) {
	const pivot = useRef();
	const spinner = useRef();
	const laser = useRef();

	const firing = useRef(false);
	const pivotTarget = useRef(0);

	const { nodes, materials } = useGLTF(prefix3D + "raygun_compressed.glb");

	useFrame(({ clock }, delta) => {
		if (!speed) {
			laser.current.scale.z = 0.5;
			pivot.current.rotation.x = MathUtils.lerp(pivot.current.rotation.x, 0, 0.05);
			spinner.current.rotation.z = MathUtils.lerp(spinner.current.rotation.z, 0, 0.05);
			return;
		}
		spinner.current.rotation.z = (spinner.current.rotation.z + 5 * delta) % (2 * Math.PI);
		pivot.current.rotation.x = MathUtils.lerp(pivot.current.rotation.x, pivotTarget.current, 0.05);
		const elapsed = clock.getElapsedTime();
		if (elapsed % 4 < 1) {
			laser.current.scale.z = 0.5 * 100 * elapsed;
			firing.current = true;
		}
		else {
			if (firing.current) {
				laser.current.scale.z = 0.5;
				pivotTarget.current = (-Math.PI / 4) * Math.random();
			}
			firing.current = false;
		}
	});

	return (
		<group
			position={[ 0, 1.75, 0 ]}
			scale={0.4}
			dispose={null}>
			<group
				ref={pivot}
				position={[0, 1.75, 0]}>
				<group ref={spinner}>
					<mesh geometry={nodes.endcap.geometry} material={materials.black} position={[0, 0, 3.83]} />
					<Select enabled={!!speed}>
						<mesh geometry={nodes.lights.geometry} material={materials.glowMat} position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, 0]} scale={[0.75, 0.6, 0.75]} />
					</Select>
					<mesh geometry={nodes.midclip.geometry} material={materials.main} position={[0, 0, 2.82]} scale={[1, 1, 0.75]} />
					<mesh geometry={nodes.rotor.geometry} material={materials.gray} position={[0.17, -0.25, 1.46]} rotation={[0, 0, -Math.PI / 4]} scale={[1.27, 1.27, 1]} />
					<mesh geometry={nodes.shaft.geometry} material={materials.main} position={[0, 0, 0.38]} rotation={[Math.PI / 2, 0, 0]} scale={[0.75, 0.6, 0.75]} />
				</group>
				<Select enabled={!!speed}>
					<mesh ref={laser} geometry={nodes.laser.geometry} material={materials.glowMat} position={[0, 0, 0.8]} scale={[1, 1, 0.5]} />
				</Select>
				<mesh geometry={nodes.sight.geometry} material={materials.black} position={[0, 0.75, 0.8]} scale={0.15} />
				<mesh geometry={nodes.sight001.geometry} material={materials.rayMat} material-color="#333333" position={[0, 0.75, 0.8]} scale={0.15} />
			</group>
			<mesh geometry={nodes.supports.geometry} material={materials.main} />
			<mesh geometry={nodes.pivot.geometry} material={materials.main} position={[0, 1.75, 0]} rotation={[0, 0, -Math.PI / 2]} scale={[0.15, 1.2, 0.15]} />
		</group>
	)
}

function RefurbCannon() {
	const { nodes, materials } = useGLTF(prefix3D + "cannon_compressed.glb");

	return (
		<group position={[ -0.3, 1.75, 0 ]}>
			<group
				rotation={[ 0, -0.67 * Math.PI, 0 ]}
				scale={1.4}
				dispose={null}>
				<mesh geometry={nodes.Wheel.geometry} material={materials.wheel} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.Main_Cannon.geometry} material={materials.metal} position={[0, 0.5, -0.33]} />
				<mesh geometry={nodes.RectangularPrism.geometry} material={materials.redwood} position={[0, 0.33, -0.35]} />
				<mesh geometry={nodes.WheelNutInner2.geometry} material={materials.redwood} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} scale={0.05} />
				<mesh geometry={nodes.Wheel_Spokes.geometry} material={materials.wood_body} position={[0, 0.33, -0.35]} />
				<mesh geometry={nodes.WoodenSupports.geometry} material={materials.wood_body} position={[0, 0.09, 0.41]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.MetalMainSupport.geometry} material={materials.wood_body} position={[0, 0.39, -0.31]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.MetalSupportFrame.geometry} material={materials.metal} position={[-0.01, 0.31, -0.11]} rotation={[0, 0, -Math.PI / 2]} scale={0.34} />
				<mesh geometry={nodes.MetalSupportFrameMetalCover.geometry} material={materials.metal} position={[0, 0.51, -0.4]} scale={0.01} />
				<mesh geometry={nodes.WheelRim.geometry} material={materials.metal} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} />
			</group>
			<group
				position={[ 0, 0, -1.3 ]}
				rotation={[ 0, -0.67 * Math.PI, 0 ]}
				scale={1.4}
				dispose={null}>
				<mesh geometry={nodes.Wheel.geometry} material={materials.wheel} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.Main_Cannon.geometry} material={materials.metal} position={[0, 0.5, -0.33]} />
				<mesh geometry={nodes.RectangularPrism.geometry} material={materials.redwood} position={[0, 0.33, -0.35]} />
				<mesh geometry={nodes.WheelNutInner2.geometry} material={materials.redwood} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} scale={0.05} />
				<mesh geometry={nodes.Wheel_Spokes.geometry} material={materials.wood_body} position={[0, 0.33, -0.35]} />
				<mesh geometry={nodes.WoodenSupports.geometry} material={materials.wood_body} position={[0, 0.09, 0.41]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.MetalMainSupport.geometry} material={materials.wood_body} position={[0, 0.39, -0.31]} rotation={[0, 0, -Math.PI / 2]} />
				<mesh geometry={nodes.MetalSupportFrame.geometry} material={materials.metal} position={[-0.01, 0.31, -0.11]} rotation={[0, 0, -Math.PI / 2]} scale={0.34} />
				<mesh geometry={nodes.MetalSupportFrameMetalCover.geometry} material={materials.metal} position={[0, 0.51, -0.4]} scale={0.01} />
				<mesh geometry={nodes.WheelRim.geometry} material={materials.metal} position={[0, 0.33, -0.35]} rotation={[0, 0, -Math.PI / 2]} />
			</group>
		</group>
	)
}

function SafetyTest({ speed }) {
	const group = useRef();
	const anim = useRef();

	// const { nodes, materials } = useGLTF(prefix3D + "dummy_compressed.glb");
	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "dummy-rig_compressed.glb",
		groupRef: group,
		animRef: anim
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.reset().play();
		animation.weight = 1;
		animation.timeScale = 0.6;
		return () => {
			animation.fadeOut(2).halt(2);
		}
	}, [ speed ]);

	return (
    <group
			ref={group}
			dispose={null}>
      <group name="Scene">
        <group name="Armature" position={[0, 1.96, 0.12]} rotation={[Math.PI / 2, 0, 0]}>
          <primitive object={nodes.main} />
          <skinnedMesh name="body004" geometry={nodes.body004.geometry} material={materials.joints} skeleton={nodes.body004.skeleton} />
          <skinnedMesh name="body004001" geometry={nodes.body004001.geometry} material={materials.joints} skeleton={nodes.body004001.skeleton} />
          <skinnedMesh name="body004002" geometry={nodes.body004002.geometry} material={materials.joints} skeleton={nodes.body004002.skeleton} />
          <skinnedMesh name="body004003" geometry={nodes.body004003.geometry} material={materials.joints} skeleton={nodes.body004003.skeleton} />
          <skinnedMesh name="body004004" geometry={nodes.body004004.geometry} material={materials.joints} skeleton={nodes.body004004.skeleton} />
          <skinnedMesh name="body004005" geometry={nodes.body004005.geometry} material={materials.joints} skeleton={nodes.body004005.skeleton} />
          <skinnedMesh name="body004006" geometry={nodes.body004006.geometry} material={materials.joints} skeleton={nodes.body004006.skeleton} />
          <skinnedMesh name="body004007" geometry={nodes.body004007.geometry} material={materials.joints} skeleton={nodes.body004007.skeleton} />
          <skinnedMesh name="body005" geometry={nodes.body005.geometry} material={materials.body} material-color="#5F5B4F" skeleton={nodes.body005.skeleton} />
          <skinnedMesh name="body005001" geometry={nodes.body005001.geometry} material={materials.body} skeleton={nodes.body005001.skeleton} />
          <skinnedMesh name="body006" geometry={nodes.body006.geometry} material={materials.body} skeleton={nodes.body006.skeleton} />
          <skinnedMesh name="Object001" geometry={nodes.Object001.geometry} material={materials.body} skeleton={nodes.Object001.skeleton} />
          <skinnedMesh name="Object002" geometry={nodes.Object002.geometry} material={materials.body} skeleton={nodes.Object002.skeleton} />
          <skinnedMesh name="Object003" geometry={nodes.Object003.geometry} material={materials.body} skeleton={nodes.Object003.skeleton} />
          <skinnedMesh name="Object003001" geometry={nodes.Object003001.geometry} material={materials.body} skeleton={nodes.Object003001.skeleton} />
          <skinnedMesh name="Object004" geometry={nodes.Object004.geometry} material={materials.body} skeleton={nodes.Object004.skeleton} />
          <skinnedMesh name="Object004001" geometry={nodes.Object004001.geometry} material={materials.body} skeleton={nodes.Object004001.skeleton} />
          <skinnedMesh name="Object005" geometry={nodes.Object005.geometry} material={materials.body} skeleton={nodes.Object005.skeleton} />
          <skinnedMesh name="Object005001" geometry={nodes.Object005001.geometry} material={materials.body} skeleton={nodes.Object005001.skeleton} />
          <skinnedMesh name="Object006" geometry={nodes.Object006.geometry} material={materials.body} skeleton={nodes.Object006.skeleton} />
          <skinnedMesh name="Object006001" geometry={nodes.Object006001.geometry} material={materials.body} skeleton={nodes.Object006001.skeleton} />
        </group>
      </group>
    </group>
  )
	// return (
  //   <group dispose={null}>
  //     <mesh geometry={nodes.body.geometry} material={materials.body} material-color="#5F5B4F" position={[0, 2.24, 0.8]} rotation={[0.55, 0, 0]} scale={0.02} />
  //     <mesh geometry={nodes.joints.geometry} material={materials.joints} position={[-0.7, 2.06, 0.96]} rotation={[0.01, -0.19, -1.54]} scale={0.02} />
  //   </group>
  // )
}

function SatTV({ speed }) {
	const group = useRef();
	const rotTarget = useRef(0);
	const { nodes, materials } = useGLTF(prefix3D + "satTV_compressed.glb");

	useFrame(() => {
		if (!speed) rotTarget.current = 0;
		if (Math.abs(group.current.rotation.y - rotTarget.current) < 0.005) {
			if (!speed) return;
			rotTarget.current = rotTarget.current + 2 * Math.PI * (Math.random() - 0.5);
		}
		group.current.rotation.y = MathUtils.lerp(group.current.rotation.y, rotTarget.current, 0.05);
	});

	return (
		<group
			ref={group}
			position={[ 0, 1.75, 0 ]}
			dispose={null}>
			<mesh geometry={nodes.antenna.geometry} material={materials.black} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.01} />
			<mesh geometry={nodes.antenna001.geometry} material={materials.dish} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.01} />
		</group>
	)
}

function Slingshot({ speed }) {
	const group = useRef();
	const anim = useRef();

	const { nodes, materials } = useGLTFAnimation({
		path: prefix3D + "slingshot_compressed.glb",
		groupRef: group,
		animRef: anim,
		loop: LoopOnce,
		repetitions: 1
	});

	useEffect(() => {
		if (!anim.current) return;
		if (!speed) return;

		const animation = anim.current;
		animation.timeScale = 1;
		animation.reset().play();
		const i = setInterval(() => {
			animation.reset().play();
		}, 2500);
		return () => {
			animation.halt(1);
			clearInterval(i);
		}
	}, [ speed ]);

	return (
		<group
			ref={group}
			position={[ 0, 1.75, 0 ]}
			dispose={null}>
			<group name="Scene">
				<group name="Armature" rotation={[-0.26, 0, 0]}>
					<primitive object={nodes.Bone} />
					<skinnedMesh name="Plane" geometry={nodes.Plane.geometry} material={materials.Material} skeleton={nodes.Plane.skeleton} />
				</group>
				<mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.Material} rotation={[-0.26, 0, 0]} />
				<mesh name="Cube002" geometry={nodes.Cube002.geometry} material={materials.wood} position={[0, 0.01, 0.02]} rotation={[-0.26, 0, 0]} />
				<mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials.wood} position={[0, -0.01, -0.02]} rotation={[2.88, 0, Math.PI]} />
			</group>
		</group>
	)
}

function SmallDinnerParty() {
	const { nodes, materials } = useGLTF(prefix3D + "/dinner_table_compressed.glb");

  return (
		<group
			position={[ 0, 1.7, -0.6 ]}
			rotation={[0, Math.PI / 2, 0]}
			scale={0.5}
			dispose={null}>
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[-0.6, 0, 0.88]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[0, 0, 0.88]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[0.6, 0, 0.88]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[-0.6, 0, -0.88]} rotation={[0, Math.PI, 0]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[0, 0, -0.88]} rotation={[0, Math.PI, 0]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[0.6, 0, -0.88]} rotation={[0, Math.PI, 0]} />
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[1.2, 0, 0]} rotation={[0, Math.PI / 2, 0]}/>
			<mesh geometry={nodes.Chair.geometry} material={materials.wood} position={[-1.2, 0, 0]} rotation={[0, -Math.PI / 2, 0]}/>
			<mesh geometry={nodes.Table001.geometry} material={materials.wood} rotation={[Math.PI / 2, 0, -Math.PI]} />
		</group>
  )
}

function Sniffer() {
	const { nodes, materials } = useGLTF(prefix3D + "sniffer_compressed.glb");

	return (
		<group
			position={[ 0, 0.55, 2.497 ]}
			rotation={[ -Math.PI / 8, 0, 0 ]}
			scale={0.2}
			dispose={null}>
			<mesh geometry={nodes.LP_Noses_Group_2.geometry} material={materials.mat} />
		</group>
	)
}

function SnowPlow({ speed }) {
	const pivot = useRef();
	const rotTarget = useRef(0);

	const { nodes, materials } = useGLTF(prefix3D + "snow_plow_compressed.glb");

	useFrame(() => {
		if (Math.abs(pivot.current.rotation.x - rotTarget.current) < 0.001) {
			rotTarget.current = speed && rotTarget.current === 0
				? -Math.PI / 4
				: 0;
			return;
		};
		pivot.current.rotation.x = MathUtils.lerp(pivot.current.rotation.x, rotTarget.current, 0.05);
	});

	return (
		<group
			ref={pivot}
			position={[ 0, 1, 2.65 ]}>
			<group
				position={[ 0, -1, 0.5 ]}
				scale={0.55}
				dispose={null}>
				<mesh geometry={nodes.Cube.geometry} material={materials.yellow}/>
				<mesh geometry={nodes.Cube001.geometry} material={materials.yellow}/>
				<mesh geometry={nodes.Sphere.geometry} material={materials.black}/>
				<mesh geometry={nodes.Cube002.geometry} material={materials.black}/>
				<mesh geometry={nodes.Cube003.geometry} material={materials.black}/>
				<mesh geometry={nodes.Sphere001.geometry} material={materials.glass} material-opacity={0.6} material-transparent={true}/>
				<mesh geometry={nodes.Sphere002.geometry} material={materials.steel}/>
				<mesh geometry={nodes.Sphere003.geometry} material={materials.steel}/>
				<mesh geometry={nodes.Sphere004.geometry} material={materials.steel}/>
				<mesh geometry={nodes.Plane.geometry} material={materials.black}/>
			</group>
		</group>
	)
}

function Spoiler() {
	const { nodes, materials } = useGLTF(prefix3D + "spoiler_compressed.glb");

	return (
		<group
			position={[ 0, 1.74, -1.8 ]}
			dispose={null}>
			<mesh geometry={nodes.Plane.geometry} material={materials.Carbon} />
			<mesh geometry={nodes.Plane_1.geometry} material={materials.Feet} />
		</group>
	)
}

function StairSection({ end, ...props }) {
	const { nodes, materials } = useGLTF(prefix3D + "stairs_compressed.glb");

	return (
		<group
			{...props}
			dispose={null}>
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 0.52, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 0.64, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 0.76, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 0.88, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 1, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Circle.geometry} material={materials.metal} position={[1, 1.12, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
			<mesh geometry={nodes.Cube.geometry} material={materials.metal} scale={[0.6, 0.25, 0.6]} />
			<mesh geometry={nodes.Cube001.geometry} material={materials.metal} position={[0, 0.3, 0]} rotation={[0, Math.PI / 10, 0]} scale={[0.6, 0.25, 0.6]} />
			<mesh geometry={nodes.Cube002.geometry} material={materials.metal} position={[0, 0.6, 0]} rotation={[0, Math.PI / 5, 0]} scale={[0.6, 0.25, 0.6]} />
			<mesh geometry={nodes.Cube003.geometry} material={materials.metal} position={[0, 0.9, 0]} rotation={[0, 0.94, 0]} scale={[0.6, 0.25, 0.6]} />
			<mesh geometry={nodes.Cube004.geometry} material={materials.metal} position={[0, 1.2, 0]} rotation={[0, 1.26, 0]} scale={[0.6, 0.25, 0.6]} />
			<mesh geometry={nodes.Cylinder.geometry} material={materials.metal} />
			<mesh geometry={nodes.Cylinder001.geometry} material={materials.metal} />
			<mesh geometry={nodes.Plane.geometry} material={materials.metal} />
			{end && (
				<group
					position={[ 0, 1.5, 0 ]}
					rotation={[ 0, Math.PI / 2, 0 ]}>
					<mesh geometry={nodes.Cylinder001.geometry} material={materials.metal} />
					<mesh geometry={nodes.Cube.geometry} material={materials.metal} scale={[0.6, 0.25, 0.6]} />
				</group>
			)}
		</group>
	)
}
function Stairway({ speed }) {
	const group = useRef();

	useFrame((_, delta) => {
		if (!group.current) return;
		if (!speed) {
			if (Math.abs(group.current.rotation.y) < 0.005) return;
			group.current.rotation.y = MathUtils.lerp(group.current.rotation.y, 0, 0.05);
			return;
		}
		group.current.rotation.y = (group.current.rotation.y - 2 * delta * speed) % (-2 * Math.PI);
	});

	return (
		<group
			ref={group}
			position={[ -0.3, 1.75, -0.5 ]}
			scale={[ 0.75, 0.5, 0.75 ]}>
			<StairSection/>
			<StairSection
				position={[ 0, 1.5, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}
			/>
			<StairSection
				position={[ 0, 3, 0 ]}
				rotation={[ 0, Math.PI, 0 ]}
			/>
			<StairSection
				position={[ 0, 4.5, 0 ]}
				rotation={[ 0, 1.5 * Math.PI, 0 ]}
				end={true}
			/>
		</group>
	)
}

function StudentSign() {
	const { nodes, materials } = useGLTF(prefix3D + "student_sign_compressed.glb");

	return (
		<group
			position={[ 0, 1.8, -0.5 ]}
			scale={0.35}
			dispose={null}>
			<mesh geometry={nodes.sign.geometry} material={materials.yellow_sign} material-roughness={0.1}/>
			<mesh geometry={nodes.brim.geometry} material={materials.brim} scale={[ 1, 2, 1 ]}/>
		</group>
	)
}

function Supercharged() {
	const { nodes, materials } = useGLTF(prefix3D + "/engine_block_compressed.glb");

	return (
		<group dispose={null}>
			<mesh geometry={nodes.Cube.geometry} material={materials.chromeMat} position={[0, 1.02, 1.95]} />
			<mesh geometry={nodes.Cube003.geometry} material={materials['Material.001']} position={[0, 1.2526, 1.98]} />
		</group>
	)
}

function Taradiddles() {
	const { nodes, materials } = useGLTF(prefix3D + "taradiddles_compressed.glb");

	return (
		<group
			position={[ 0, 1.775, -0.5 ]}
			rotation={[ Math.PI / 60, 0, 0 ]}
			scale={0.008}
			dispose={null}>
			<mesh geometry={nodes.ring.geometry} material={materials.band} />
			<mesh geometry={nodes.Hat.geometry} material={materials.hat} />
		</group>
	)
}

function TheBest() {
	const { nodes, materials } = useGLTF(prefix3D + "the_best_compressed.glb");

	useEffect(() => {
		if (!materials || !materials.signMat) return;

		materials.signMat.map.wrapS = materials.signMat.map.wrapT = ClampToEdgeWrapping;
	}, [ materials ]);

	return (
		<group
			position={[ 0, 1.9, -0.6 ]}
			scale={0.38}
			dispose={null}>
			<mesh geometry={nodes.body.geometry} material={materials.bodyMat} material-side={DoubleSide} material-opacity={0.98} material-transparent={true} scale={[ 0.8, 1, 1 ]} />
			<mesh geometry={nodes.signs.geometry} material={materials.signMat} position={[ 0, 0.06, 0 ]} scale={[ 0.8, 1, 1 ]} />
			<mesh geometry={nodes.front_support.geometry} material={materials.gray} position={[0, -0.05, -2.06]} scale={[1.875, 0.02, 0.1]} />
			<mesh geometry={nodes.front_feet.geometry} material={materials.gray} position={[-1.5676, -0.25, -2.04]} scale={0.15} />
			<mesh geometry={nodes.back_support.geometry} material={materials.gray} position={[0, -0.05, 2.06]} rotation={[-Math.PI, 0, -Math.PI]} scale={[1.875, 0.02, 0.1]} />
			<mesh geometry={nodes.back_feet.geometry} material={materials.gray} position={[1.5676, -0.25, 2.04]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.15} />
		</group>
	)
}

function TheWow() {
	const [ vistaMap, wowMap ] = useTexture([
		prefix3D + "vista.png",
		prefix3D + "wow.png"
	]);

	const vistaMat = (
		<meshBasicMaterial
			attach="material"
			map={vistaMap}
			transparent={true}
			alphaTest={0.01}
		/>
	);

	return (
		<group position={[ 0, 0.8, 0.1 ]}>
			<Plane
				args={[ 1.6, 0.335 ]}
				position={[ 0.95, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}>
				{vistaMat}
			</Plane>
			<Plane
				args={[ 1.6, 0.335 ]}
				position={[ -0.95, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, 0 ]}>
				{vistaMat}
			</Plane>
			<Plane
				args={[ 1.2, 1.2 ]}
				position={[ 0, 0.25, 1.85 ]}
				rotation={[ -1.25, 0, 0 ]}>
				<meshBasicMaterial
					attach="material"
					map={wowMap}
					transparent={true}
					alphaTest={0.01}
				/>
			</Plane>
		</group>
	)
}

function Turbine({ speed }) {
	const rotor = useRef();

	const { nodes, materials } = useGLTF(prefix3D + "turbine_compressed.glb");

	useFrame((_, delta) => {
		if (!speed) return;
		rotor.current.rotation.z -= 5 * delta;
	});

	return (
    <group
			position={[ 0, 2.15, 0 ]}
			scale={0.5}
			dispose={null}>
			<group ref={rotor}>
				<mesh geometry={nodes.Cylinder.geometry} material={materials.silver} rotation={[ 0.02, 0, 0 ]}/>
				<mesh geometry={nodes.Sphere.geometry} material={materials.dark_silver} />
			</group>
      <mesh geometry={nodes.Cylinder001.geometry} material={materials.dark_silver} />
      <mesh geometry={nodes.Cylinder002.geometry} material={materials.silver} />
      <mesh geometry={nodes.Cylinder005.geometry} material={materials.silver} rotation={[Math.PI / 2, -0.23, 0]} scale={[0.05, 0.5, 0.05]} />
			<mesh geometry={nodes.Cylinder005.geometry} material={materials.silver} rotation={[Math.PI / 2, -2.5, 0]} scale={[0.05, 0.5, 0.05]} />
      <mesh geometry={nodes.Cylinder004.geometry} material={materials.silver} rotation={[-Math.PI / 2, 1.27, -Math.PI]} scale={[0.05, 0.5, 0.05]} />
      <mesh geometry={nodes.Cylinder006.geometry} material={materials.silver} position={[0.5, -0.47, -0.82]} rotation={[Math.PI / 2, -0.75, 0]} scale={[0.05, 0.5, 0.05]} />
      <mesh geometry={nodes.Cube.geometry} material={materials.yellow} />
			<mesh geometry={nodes.Cube.geometry} material={materials.yellow} scale={[ -1, 1, 1 ]} />
    </group>
  )
}

function TwinSchwinns() {
	const { nodes, materials } = useGLTF(prefix3D + "schwinn_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.5 ]}
			dispose={null}>
			<group position={[0.4, 0, 0]} rotation={[ 0, -Math.PI / 2, 0 ]} scale={0.2}>
				<mesh geometry={nodes.Cylinder066.geometry} material={materials.Seat} material-color="#030303"/>
				<mesh geometry={nodes.Cylinder066_1.geometry} material={materials.Handler_B} material-color="#313131"/>
				<mesh geometry={nodes.Cylinder066_2.geometry} material={materials.Frame} material-color="#202020"/>
				<mesh geometry={nodes.Cylinder066_3.geometry} material={materials.Mat1} material-color="#e7e7e7"/>
				<mesh geometry={nodes.Cylinder066_4.geometry} material={materials.Mat2} material-color="#7c7c7c"/>
				<mesh geometry={nodes.Cylinder066_5.geometry} material={materials.Man} material-color="#353430"/>
				<mesh geometry={nodes.Cylinder066_6.geometry} material={materials.Tire} material-color="#272727"/>
				<mesh geometry={nodes.Cylinder066_7.geometry} material={materials.Rim} material-color="#eeeeee"/>
			</group>
			<group position={[-0.4, 0, 0]} rotation={[ 0, -Math.PI / 2, 0 ]} scale={0.2}>
				<mesh geometry={nodes.Cylinder066.geometry} material={materials.Seat} />
				<mesh geometry={nodes.Cylinder066_1.geometry} material={materials.Handler_B} />
				<mesh geometry={nodes.Cylinder066_2.geometry} material={materials.Frame} />
				<mesh geometry={nodes.Cylinder066_3.geometry} material={materials.Mat1} />
				<mesh geometry={nodes.Cylinder066_4.geometry} material={materials.Mat2} />
				<mesh geometry={nodes.Cylinder066_5.geometry} material={materials.Man} />
				<mesh geometry={nodes.Cylinder066_6.geometry} material={materials.Tire} />
				<mesh geometry={nodes.Cylinder066_7.geometry} material={materials.Rim} />
			</group>
		</group>
	)
}

const tokenGeometry = new CylinderBufferGeometry(0.15, 0.15, 0.07, 32, 1);
const generateRandomTokenPosition = i =>([
	0.075 * (Math.random() - 0.5),
	0.07 * i,
	0.075 * (Math.random() - 0.5)
]);
const stacks = [
	Array.from({ length: 18 }, (_, i) => generateRandomTokenPosition(i)),
	Array.from({ length: 14 }, (_, i) => generateRandomTokenPosition(i)),
	Array.from({ length: 9 }, (_, i) => generateRandomTokenPosition(i)),
	Array.from({ length: 6 }, (_, i) => generateRandomTokenPosition(i)),
	Array.from({ length: 3 }, (_, i) => generateRandomTokenPosition(i))
];
const numTokens = stacks.reduce((total, arr) => total + arr.length, 0);
function UtilityTokens() {
	return (
		<group position={[ 0, 1.75, 0 ]}>
			<Instances
				geometry={tokenGeometry}
				material={sharedMaterials.standard.gold}
				limit={numTokens}>
				{stacks.map((stack, s) => (
					<group
						key={s}
						position={[ 0, 0, 0.1 - 0.3 * s]}>
						{stack.map((pos, i) => (
							<Instance
								key={i}
								position={pos}
							/>
						))}
					</group>
				))}
			</Instances>
		</group>
	)
}

const ladderMat = cloneMaterialWithProps(
	sharedMaterials.standard.steel,
	{ color: "#ffffff" }
);
const ladderLegGeo = new BoxBufferGeometry(0.08, 1.6, 0.025);
const ladderStepGeo = new BoxBufferGeometry(0.07, 0.025, 0.5);
const ladderNubGeo = new SphereBufferGeometry(0.04);
function LadderStep(props) {
	return (
		<group {...props}>
			<mesh
				position={[ 0, 0, -0.25 ]}
				scale={[ 1, 0.5, 0.5 ]}
				geometry={ladderNubGeo}
				material={sharedMaterials.standard.black}
			/>
			<mesh
				geometry={ladderStepGeo}
				material={ladderMat}
			/>
			<mesh
				position={[ 0, 0, 0.25 ]}
				scale={[ 1, 0.5, 0.5 ]}
				geometry={ladderNubGeo}
				material={sharedMaterials.standard.black}
			/>
		</group>
	)
}
function WeCouldSleepTent() {
	const { nodes, materials } = useGLTF(prefix3D + "tent_compressed.glb");

	return (
		<group
			position={[ 0, 1.75, -0.6 ]}
			dispose={null}>
			<mesh geometry={nodes.Door.geometry} material={materials.khaki} position={[0, 0.58, 0]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[1, 1.2, 1]} />
			<mesh geometry={nodes.Karkas.geometry} material={materials.black} />
			<mesh geometry={nodes.Walls.geometry} material={materials.khaki} material-color="#dba368"/>
			<mesh geometry={nodes.mesh.geometry} material={materials.mesh} />
			<mesh geometry={nodes.mesh_side.geometry} material={materials.mesh} />
			<mesh geometry={nodes.Plane.geometry} material={materials.light_khaki} />
			<group
				position={[ 0.83, 0.025, -0.18 ]}
				rotation={[ 0, 0, Math.PI / 12 ]}>
				<mesh
					position={[ 0, -0.8, -0.25 ]}
					geometry={ladderLegGeo}
					material={ladderMat}
				/>
				<mesh
					position={[ 0, -0.8, 0.25 ]}
					geometry={ladderLegGeo}
					material={ladderMat}
				/>
				<LadderStep position={[ 0, -0.1, 0 ]}/>
				<LadderStep position={[ 0, -0.35, 0 ]}/>
				<LadderStep position={[ 0, -0.6, 0 ]}/>
				<LadderStep position={[ 0, -0.85, 0 ]}/>
				<LadderStep position={[ 0, -1.1, 0 ]}/>
				<LadderStep position={[ 0, -1.35, 0 ]}/>
			</group>
		</group>
	)
}

function Wings() {
	const { nodes, materials } = useGLTF(prefix3D + "wings_compressed.glb");

	return (
		<group
			position={[ 0, 0.75, 0.35 ]}
			rotation={[ Math.PI / 6, 0, 0 ]}
			dispose={null}>
			<mesh position={[ -0.88, 0, 0 ]} rotation={[ 0, -0.03 * Math.PI, 0 ]} scale={0.028} geometry={nodes['Explorer_of_New_Worlds-Competition_WIP-15'].geometry} material={materials['Default OBJ']} />
			<mesh position={[ 0.88, 0, 0 ]} rotation={[ 0, 0.03 * Math.PI, 0 ]} scale={[ -0.028, 0.028, 0.028 ]} geometry={nodes['Explorer_of_New_Worlds-Competition_WIP-15'].geometry} material={materials['Default OBJ']} />
		</group>
	)
}

export const upgrades = {
	"Baby": Baby,
	"Blank": undefined, // default
	"Booty": Booty,
	"Bow": Bow,
	"Brand Ambassador": BrandAmabassador,
	"Chair": Chair,
	"Christmas Tree": ChristmasTree,
	"Coffin": Coffin,
	"Cone Hawk": ConeHawk,
	"Copter": Copter,
	"Couch": Couch,
	"Crime Scene": CrimeScene,
	"Danglers": Danglers,
	"Don't look, Marion! Keep your eyes SHUT": DontLook,
	"Eagle's Nest": EaglesNest,
	"ETH Flag": ETHFlag,
	"For the Mob Movie": ForTheMob,
	"Garbage": Garbage,
	"Get In The Zone": GetInTheZone,
	"GO, STOP, SLOW DOWN": GoStopSlowdown,
	"気を付けて！ゴジラ！": Godzilla,
	"Great Set Of Pipes": GreatPipes,
	"I Mean Ya It Wasn't Cheap": IWasntCheap,
	"Listeners": Listeners,
	"Marc Ornstein": MarcOrnstein,
	"New Kayak": NewKayak,
	"Night Rider": NightRider,
	"Party Time": PartyTime,
	"Police Lights": PoliceLights,
	"Popup Shop": PopupTent,
	"Punk": Punk,
	"Quack": QuackAnim,
	"Rail Gun": Raygun,
	"Refurbished Civil War Cannons": RefurbCannon,
	"Safety Test": SafetyTest,
	"Satellite TV": SatTV,
	"Slingshot": Slingshot,
	"Small Dinner Party": SmallDinnerParty,
	"Sniffer": Sniffer,
	"Snow Plow": SnowPlow,
	"Spoiler": Spoiler,
	"Stairway To Heaven": Stairway,
	"Student Driver": StudentSign,
	"Supercharged": Supercharged,
	"Taradiddles": Taradiddles,
	"The Best": TheBest,
	"The Wow Starts Now": TheWow,
	"Utility Tokens": UtilityTokens,
	"Turbine": Turbine,
	"Twin Schwinns": TwinSchwinns,
	"We Could Actually Sleep Up There": WeCouldSleepTent,
	"Wings": Wings,
}

export function useUpgrades(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "upgrades");
	if (!trait) return undefined;

	return upgrades[ trait.value ];
}
import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { isMobile } from "react-device-detect";

import { gitbookLink } from "../links";

import { BorderButton, Flex } from "./Styles";
import { Wrapper } from "./Wrapper";
import { NarcTest } from "./NarcTest.js"
import { DonaModal } from "./DonaModal";

const fadeIn = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;
const Container = styled.div`
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
	animation: ${fadeIn} 3s ease;
`;

const MenuContainer = styled(Flex).attrs(() => ({
	justify: "space-between"
}))`
  position: fixed;
  width: 100%;
  padding: 34px;
  padding-right: 88px;
	z-index: 5;
	pointer-events: none;
	& * {
		pointer-events: auto;
	}
`;

const MenuLink = styled.a`
	padding: 10px 0;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 24px;
  letter-spacing: 0.1px;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #fff;
  cursor: pointer;
`;

const Left = styled.div``;

const Right = styled(Flex).attrs(() => ({
	align: "center"
}))``;

const Logo = styled.img`
	width: 120px;
	cursor: pointer;
`;

const MobileNotAllowed = styled.img`
	width: calc(100vw - 180px);
	margin-bottom: 120px;
`;

export function WrapperWithMenu({ children, account, connectWallet, allowed, shouldTest = false, canTransact, menuItems, bgVideo }) {
	const [ isTesting, setTesting ] = useState(shouldTest);

	const [ donaModalActive, setDonaActive ] = useState(false);

  return (
		<>
			<Wrapper
				bgVideo={bgVideo}
				disableFence={!isMobile}
				contentAlign={isMobile ? "center": "flex-start"}>
				{isMobile
					? <MobileNotAllowed src="/mobile-09.png"/>
					: isTesting
						? (
							<NarcTest
								allowed={allowed}
								clearTest={() => setTesting(false)}
							/>
						)
						: (
							<Container>
								<MenuContainer>
									<Left>
										<Logo
											src="/images/logo.png"
											onClick={() => window.location.href = "/"}
										/>
									</Left>
									<Right>
										<MenuLink onClick={async () => {
											if (!account && !(await connectWallet())) return;
											setDonaActive(a => !a);
										}}>VEHICLES</MenuLink>
										<MenuLink onClick={() => window.open(gitbookLink, "_blank")}>RACING 101</MenuLink>
										<BorderButton onClick={() => connectWallet()}>
											{account
												? account.substr(0, 4) + "..." + account.substr(-4, 4)
												: "Connect wallet"}
										</BorderButton>
										{menuItems}
									</Right>
								</MenuContainer>
								{children}
							</Container>
						)
				}
			</Wrapper>
			<DonaModal
				visible={donaModalActive}
				account={account}
				canTransact={canTransact}
				onClose={() => setDonaActive(false)}
			/>
		</>
  );
}

import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useDonaContext } from "../../providers";
import { useWallet } from "../../Hooks";

import { CenteredFlex, Flex, PillHeader } from "../Styles";
import { WrapperWithMenu } from "../WrapperWithMenu";
import { ElevatorButton } from "./ElevatorButton";
import { Garage3D } from "./Garage3D";
import { WheelIcon } from "../WheelIcon";

const Background = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-image: url('${process.env.PUBLIC_URL + "diamond-2.jpg"}');
	background-size: cover;
	z-index: -1;
`;

const Container = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	width: 100%;
	margin-top: 140px;
`;

const Content = styled(Flex).attrs(() => ({
	column: true,
	justify: "stretch",
	align: "center"
}))`
  width: 910px;
	height: 612px;
	box-shadow: 0px 7px 34px rgba(0,0,0,0.4);
`;

const CautionBar = styled.div`
	width: 100%;
	height: 30px;
	background: repeating-linear-gradient(-45deg, rgb(0, 0, 0), rgb(0, 0, 0) 35px, rgb(255, 255, 0) 35px, rgb(255, 255, 0) 70px);
`;

const MainContent = styled(Flex).attrs(() => ({
	justify: "stretch",
	grow: 1
}))`
	width: 100%;
`;
const StationContainer = styled(Flex).attrs(() => ({
	column: true,
	align: "center"
}))`
	flex-grow: 1;
	height: 480px;
	padding: 40px 0;
	background-color: #ffff00;
	& > div:first-of-type {
		margin-bottom: 60px;
	}
`;
const ControlsContainer = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	width: 100%;
	height: 72px;
	background-image: url('${process.env.PUBLIC_URL + "elevator-bottom-bar.png"}');
	background-position: center;
	background-size: cover;
	padding: 15px 10px;
`;
const ControlsButton = styled(CenteredFlex)`
	position: relative;
	height: 48px;
	padding: 0px 18px;
	margin: 5px;
	border-radius: 8px;
	border: 1px solid rgba(0,0,0,0.5);
	background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.3) 50%, rgba(255, 255, 255, 0.2));
	background-color: rgb(200, 200, 200);
	color: black;
	text-shadow: rgb(136, 136, 136) 1px 1px 2px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		left: 2px;
		right: 2px;
		top: 2px;
		height: 24px;
		border-radius: 8px;
		background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
	}

	&:disabled {
		background-color: rgb(102, 102, 102);
		cursor: not-allowed;
	}
`;

const Container3D = styled(CenteredFlex)`
	position: relative;
	width: 480px;
	height: 480px;
	background-image: url('${process.env.PUBLIC_URL + "elevator.png"}');
	background-position: center;
	background-size: cover;
`;
const Loading3D = styled.div`
	position: absolute;
	font-family: LLPIXEL3, sans-serif;
	font-size: ${({ size = 16 }) => size}px;
	color: white;
	z-index: 2;
`;
const DonaStats = styled(Flex).attrs(() => ({
	justify: "space-between",
	align: "center"
}))`
	position: absolute;
	top: 40px;
	width: 360px;
	padding: 10px 15px;
	color: #ffff00;
	font-family: LLPIXEL3, sans-serif;
	background-color: black;
	border: 2px solid #ffff00;
`;
const DonaStat = styled.div`
	margin-right: 10px;
	min-width: 60px;
`;
const AnimWheel = styled(CenteredFlex)`
	width: 36px;
	height: 36px;
	border-radius: 3px;
	background-color: ${({ active }) => !active ? "#000000": "#ffff00"};
	border: 1px solid #ffff00;
	cursor: pointer;

	& > svg {
		width: 24px;
		height: 24px;
	}
`;

const GarageContainer = styled(CenteredFlex)`
	position: relative;
	width: 160px;
	height: 160px;
	background-image: url('${process.env.PUBLIC_URL + "elevator.png"}');
	background-position: center;
	background-size: cover;
`;
const Dona2D = styled.img`
	width: 130px;
	height: 130px;
	cursor: pointer;
`;

export function RandomGaragePage() {
	const { donaMetadata } = useDonaContext()
	const { account, connect, canTransact } = useWallet();

	const [ activeFloor, setActiveFloor ] = useState(0);

	const [ raceIds ] = useState(() => {
		const search = new URLSearchParams(window.location.search);
		const searchIds = search.get("token_ids") || search.get("token_id");
		const arr = searchIds ? searchIds.split(",").map(num => parseInt(num)): [];

		while(arr.length < 7) {
			const randId = Math.ceil(Math.random() * 6000);
			arr.push(randId);
		}
		return arr;
	});

	const [ vehicles, setVehicles ] = useState([]);

	useEffect(() => {
		if (!donaMetadata) return;

		setVehicles(raceIds.map(tokenId => {
			const { metadata, images } = donaMetadata[ tokenId ] || {}
			return {
				token_id: tokenId,
				name: metadata?.name || "",
				image_url: images?.[ "512" ] || "/sign.png",
				level: 1,
				xp: 0
			}
		}));
	}, [ raceIds, donaMetadata ]);

	const [ selected, setSelected ] = useState();

	const [ isAnimating, setIsAnimating ] = useState(false);

	useEffect(() => {
		if (!vehicles || !vehicles.length) setSelected(null);
		else setSelected(vehicles[0]);
		setIsAnimating(false);
	}, [ vehicles ]);

	const [ donaTab, setDonaTab ] = useState(0);

	const displayDonas = useMemo(() => {
		if (!vehicles || !vehicles.length) return new Array(3).fill(null);
		const arr = vehicles.slice(3 * donaTab, 3 * (donaTab + 1));
		while(arr.length < 3) {
			arr.push(null);
		}
		return arr;
	}, [ vehicles, donaTab ]);

	return (<>
		<Background/>
		<WrapperWithMenu
			account={account}
			connectWallet={connect}
			allowed={true}
			canTransact={canTransact}
			bgVideo={false}>
			<Container>
				<Content>
					<CautionBar/>
					<MainContent>
						<StationContainer>
							<PillHeader>{activeFloor === 0 ? "ETHAN'S": "ARBI'S"}</PillHeader>
							<Flex column fullwidth align="center">
								<ElevatorButton
									label="ETHAN'S"
									active={activeFloor === 0}
									onClick={() => setActiveFloor(0)}
								/>
								<ElevatorButton
									label="ARBI'S"
									active={activeFloor === 1}
									onClick={() => setActiveFloor(1)}
								/>
							</Flex>
						</StationContainer>
						<Container3D>
							<Garage3D
								loading={<Loading3D size={40}>Loading...</Loading3D>}
								dona={selected}
								speed={isAnimating ? 1: 0}
								position={[ 0, -2, 0 ]}
							/>
							{selected && (
								<DonaStats>
									<Flex column>
										<div>{selected.name}</div>
										<Flex>
											<DonaStat>LV: {selected.level}</DonaStat>
											<DonaStat>XP: {selected.xp}</DonaStat>
										</Flex>
									</Flex>
									<AnimWheel
										active={isAnimating}
										onClick={() => setIsAnimating(a => !a)}>
										<WheelIcon fill={!isAnimating ? "#ffff00": "#000000"}/>
									</AnimWheel>
								</DonaStats>
							)}
						</Container3D>
						<Flex column>
							{displayDonas.map((dona, i) => (
								<GarageContainer key={i}>
									{!!dona && (
										<Dona2D
											src={dona.image_url}
											onClick={() => setSelected(dona)}
										/>
									)}
								</GarageContainer>
							))}
						</Flex>
					</MainContent>
					<CautionBar/>
					<ControlsContainer>
						<Flex>
							<ControlsButton as="button" disabled>SEND</ControlsButton>
							<ControlsButton as="button" disabled>RECV</ControlsButton>
							<ControlsButton as="button" disabled>{"[ ]"}</ControlsButton>
						</Flex>
						<PillHeader style={{ width: "400px" }}>
							{/* eslint-disable-next-line */}
							<marquee>WELCOME TO YOUR GARAGE</marquee>
						</PillHeader>
						<Flex>
							<ControlsButton
								as="button"
								disabled={donaTab === 0}
								onClick={() => setDonaTab(t => t - 1)}>
								{"◀"}
							</ControlsButton>
							<ControlsButton
								as="button"
								disabled={donaTab === Math.ceil(vehicles.length / 3) - 1}
								onClick={() => setDonaTab(t => t + 1)}>
								{"▶"}
							</ControlsButton>
						</Flex>
					</ControlsContainer>
				</Content>
			</Container>
		</WrapperWithMenu>
	</>)
}
import { Color, DoubleSide, MeshStandardMaterial, TextureLoader } from "three";
import { Plane, useTexture } from "@react-three/drei";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

import { prefix3D } from "../../utils";
import { sharedMaterials } from "../../shaders/materials";
import { createGradientShader } from "../../shaders/gradient";

const brokenWindshieldMap = new TextureLoader().load(prefix3D + "windshield_broken.jpg");
const brokenWindowMap = new TextureLoader().load(prefix3D + "windows_broken.jpg");
const lllMap = new TextureLoader().load(prefix3D + "sufferhatedie.jpeg");
lllMap.center.set(0.5, 0.5);
lllMap.repeat.set(1.5, 1.2);
const poopMap = new TextureLoader().load(prefix3D + "poop.jpg");
poopMap.center.set(0.5, 0.5);
poopMap.repeat.set(1.8, 0.8);
poopMap.offset.set(-0.3, -0.1);
brokenWindshieldMap.flipY = brokenWindowMap.flipY = lllMap.flipY = poopMap.flipY = false;

function ForSale() {
	const forsaleMap = useTexture(prefix3D + "for_sale.jpeg");
	
	return (
		<Plane
			args={[ 35, 27 ]}
			position={[ 52, -15, 23.5 ]}
			rotation={[ -Math.PI / 3.2, Math.PI / 40, Math.PI / 25 ]}>
			<meshBasicMaterial
				attach="material"
				map={forsaleMap}
				side={DoubleSide}
			/>
		</Plane>
	)
}

export const windows = {
	"Blackout": {
		material: sharedMaterials.standard.black
	},
	"Broken": {
		windshieldMat: new MeshStandardMaterial({
			map: brokenWindshieldMap,
			opacity: 0.8,
			transparent: true
		}),
		windowMat: new MeshStandardMaterial({
			color: "#ffffff",
			map: brokenWindowMap,
			opacity: 0.8,
			transparent: true
		})
	},
	"Clean": undefined, // default
	"Cotton Candy": {
		material: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("pink").convertSRGBToLinear(),
					new Color("#87dfea").convertSRGBToLinear()
				],
				opacity: 0.6,
				direction: "x",
				csm: true
			})
		})
	},
	"Focus": {
		material: new MeshStandardMaterial({
			color: "#cccc00",
			opacity: 0.5,
			transparent: true
		})
	},
	"For Sale": {
		model: ForSale
	},
	"Lemon LIme": {
		material: new MeshStandardMaterial({
			color: "#ffff00",
			opacity: 0.7,
			transparent: true
		}),
		windshieldMat: new MeshStandardMaterial({
			color: "#00ff00",
			opacity: 0.5,
			transparent: true
		})
	},
	"Live, Laugh, Love": {
		windshieldMat: new MeshStandardMaterial({
			map: lllMap,
			opacity: 0.5,
			transparent: true
		})
	},
	"Not Again": {
		windshieldMat: new MeshStandardMaterial({
			map: poopMap,
			opacity: 0.75,
			transparent: true
		})
	},
	"Sport": {
		material: new MeshStandardMaterial({
			color: "#aa0011",
			opacity: 0.8,
			transparent: true
		}),
		windshieldMat: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("#0000ff"),
					new Color("#cc0011")
				],
				opacity: 0.6,
				direction: "x",
				csm: true
			})
		})
	},
	"Sunset": {
		material: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("#ff00ff").convertSRGBToLinear(),
					new Color("#7f00ff").convertSRGBToLinear()
				],
				opacity: 0.7,
				direction: "x",
				csm: true
			})
		}),
		windshieldMat: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("#ffcc00").convertSRGBToLinear(),
					new Color("#ff3300").convertSRGBToLinear(),
					new Color("#ff0000").convertSRGBToLinear()
				],
				opacity: 0.7,
				direction: "x",
				csm: true
			})
		})
	},
	"Sweet Sixteen": {
		material: new MeshStandardMaterial({
			color: "#cc00cc",
			opacity: 0.5,
			transparent: true
		})
	},
	"Tangerine": {
		material: new MeshStandardMaterial({
			color: "#ff5500",
			opacity: 0.5,
			transparent: true
		}),
		windshieldMat: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("#ffff00").convertSRGBToLinear(),
					new Color("#ff5500").convertSRGBToLinear()
				],
				opacity: 0.6,
				direction: "x",
				csm: true
			})
		})
	},
	"Tropics": {
		material: new MeshStandardMaterial({
			color: "#ff00ff",
			opacity: 0.5,
			transparent: true
		}),
		windshieldMat: new CustomShaderMaterial({
			baseMaterial: MeshStandardMaterial,
			...createGradientShader({
				colors: [
					new Color("#00ffff").convertSRGBToLinear(),
					new Color("#0000ff").convertSRGBToLinear()
				],
				opacity: 0.7,
				direction: "x",
				csm: true
			})
		})
	},
}

export function useWindows(traits) {
	const trait = traits.find(({ trait_type }) => trait_type === "windows");
	if (!trait) return undefined;

	return windows[ trait.value ];
}
import styled from "styled-components";

import { PillLabel } from "../Styles";

const Container = styled(PillLabel)`
	position: relative;
`;

const Button = styled.div`
	position: absolute;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	right: -4px;
	background: conic-gradient(from 10deg, gray, white, gray, white, gray);
	
	box-shadow: ${({ active }) => active
		? `red 0px 0px 4px 4px`
		: `rgb(102 102 102) 0px 0px 4px 4px`
	};
	cursor: pointer;
`;

export function ElevatorButton({ label, active, onClick }) {
	return (
		<Container>
			{(active ? "★ ": "") + label}
			<Button
				active={active}
				onClick={onClick}
			/>
		</Container>
	)
}
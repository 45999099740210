import styled from "styled-components";

import { Flex } from "../Styles";

const Container = styled(Flex).attrs(() => ({
	align: "center"
}))`
	width: 100%;
	height: 100px;
	border-bottom: 1px solid gray;
`;
export const RacerNumber = styled(Flex).attrs(() => ({
	justify: "center",
	align: "center"
}))`
	font-family: LLPIXEL3, sans-serif;
	font-size: 50px;
	width: 100px;
	height: 100px;
	border-right: 1px solid gray;
	color: white;
	text-shadow: 1px 1px 10px white;
`;
const RacerLane = styled(Flex).attrs(({ isWinner }) => ({
	justify: isWinner ? "space-between": "center",
	align: "center"
}))`
	width: calc(100% - 200px);
	height: 100px;
	position: relative;
	${({ isWinner }) => isWinner && `padding: 0 40px;`}
`;
const RaceLaneStripe = styled.div`
	width: 100%;
	height: 5px;
	background-color: white;
	box-shadow: 0 7px 20px rgba(255, 255, 255, 0.7);
`;
const RacerImage = styled.img`
	height: 100px;
	width: 100px;
	position: ${({ inline }) => inline ? "auto": "absolute"};
	border: 3px solid white;
	box-shadow: 0 7px 20px rgba(255, 255, 255, 0.7);
	transition: all 0.5s ease;
`;
export const RacerFinish = styled.div`
	width: 100px;
	height: 99px;
	border-left: 1px solid gray;
	background: ${({ checkered = true }) => checkered 
		? "repeating-conic-gradient(#000 0% 25%, #fff 0% 50%) 50% / 50px 50px"
		: "none"
	};
`;

export function Racer({ number, name, image, progress, winnerContent }) {
	return (
		<Container>
			<RacerNumber>{number}</RacerNumber>
			<RacerLane isWinner={!!winnerContent}>
				{winnerContent
					? winnerContent
					: <>
						<RaceLaneStripe/>
						<RacerImage
							src={image}
							title={name}
							style={{ left: `${progress * 100}%` }}
						/>
					</>
				}
			</RacerLane>
			{winnerContent
				? (
					<RacerImage
						src={image}
						title={name}
						inline={true}
					/>
				)
				: <RacerFinish/>
			}
		</Container>
	)
}